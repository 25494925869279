import React from "react";

function IconChartRolling() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H18C18.55 3 19.0208 3.19583 19.4125 3.5875C19.8042 3.97917 20 4.45 20 5V9.2H4V21ZM4 7.2H18V5H4V7.2Z"
        fill="black"
      />
      <rect x="6" y="14" width="15" height="2" fill="black" />
    </svg>
  );
}

export default IconChartRolling;
