import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { globalCss } from "@stitches/react";
import { useAtom } from "jotai";
import dayjs from "dayjs";

import { styled } from "./stitches.config";
import DemoNavigation from "./navigation/demoNavigation";

import {
  periodAtom,
  periodCompareAtom,
  latestImportDateAtom,
  orgAtom,
  membershipAtom,
  sourceAtom,
  userAtom,
  userMembershipsAtom,
} from "./helpers/atoms";

import { previousPeriodEnd } from "./helpers/dates";

export default function DemoLayout({
  org: source_org,
  source: source_source,
  type,
  children,
  user: source_user,
  membership: source_membership,
  user_memberships: source_user_memberships,
  data,
}) {
  const [source, setSource] = useAtom(sourceAtom);
  const [latestImportDate, setLatestImportDate] = useAtom(latestImportDateAtom);
  const [newPeriod, setNewPeriod] = useAtom(periodAtom);
  const [newPeriodCompare, setNewPeriodCompare] = useAtom(periodCompareAtom);

  const [org, setOrg] = useAtom(orgAtom);
  const [membership, setMembership] = useAtom(membershipAtom);
  const [user, setUser] = useAtom(userAtom);
  const [userMemberships, setUserMemberships] = useAtom(userMembershipsAtom);

  useEffect(() => {
    setSource(source_source);
    if (data?.latestImportDate) {
      setLatestImportDate(dayjs(data?.latestImportDate).tz("UTC").format("YYYY-MM-DD"));
    }
    setNewPeriod({
      date: dayjs(data?.date).tz("UTC").format("YYYY-MM-DD"),
      interval: data?.interval,
      intervalCount: data?.intervalCount,
    });

    if (data && data.date && data.intervalCount && data.interval) {
      const compareValue = previousPeriodEnd(
        data?.date,
        data?.intervalCount,
        data?.interval
      ).format("YYYY-MM-DD");

      setNewPeriodCompare({
        active: false,
        date: compareValue,
        interval: data?.interval,
        intervalCount: data?.intervalCount,
      });
    }
  }, []);

  useEffect(() => {
    setUser(source_user);
    setUserMemberships(source_user_memberships);
    setMembership(source_membership);
  }, [source_user, source_user_memberships, source_membership]);

  useEffect(() => {
    setOrg(source_org);
  }, [org]);

  globalStyles();

  return (
    <>
      <Helmet>
        <title>onverre</title>
        <meta
          name="description"
          content="onverre creates robust and extendable analytics dashboards from your existing first-party data warehouse in minutes."
        />
      </Helmet>

      {type === "auth" ? (
        <>{children}</>
      ) : (
        <>
          <DemoNavigation />

          <Container id="body-container">{children}</Container>
        </>
      )}
    </>
  );
}

const globalStyles = globalCss({
  "*": {
    margin: 0,
    padding: 0,
    fontFamily: "'Inter', sans-serif",
    boxSizing: "border-box",
  },

  "@supports (font-variation-settings: normal)": {
    html: { fontFamily: "'Inter var', sans-serif" },
  },

  "h1, h2, h3, h4, h5, h6": {
    fontWeight: "500",
  },

  h1: { fontSize: "2.5rem" },
  h2: { fontSize: "2rem" },
  h3: { fontSize: "1.5rem" },
  h4: { fontSize: "1.25rem" },
  h5: { fontSize: "0.875rem" },
  h6: { fontSize: "0.75rem" },
  p: { fontSize: "1rem", fontWeight: "400" },

  hr: {
    height: "1px",
    backgroundColor: "$slate4",
    border: "none",
    margin: "2.5rem 0",
  },
});

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "4rem 0 1rem",
  transition: "width 0.2s ease-in-out",

  "@xl": {
    "&.period-panel-open": {
      width: "calc(100% - 368px)",
    },
  },
});
