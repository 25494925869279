import React from "react";

import { styled } from "./stitches.config";

const Indicator = styled("div", {
  variants: {
    kind: {
      current: {
        backgroundColor: "$blue10",
      },
      previous: {
        backgroundColor: "$slate10",
      },
      currentNew: {
        backgroundColor: "$blue4",
      },
      currentReturning: {
        backgroundColor: "$blue10",
      },
      previousNew: {
        backgroundColor: "$slate4",
      },
      previousReturning: {
        backgroundColor: "$slate10",
      },
    },

    size: {
      sm: {
        width: "0.5rem",
        height: "0.5rem",
      },
      md: {
        width: "0.675rem",
        height: "0.675rem",
      },
      lg: {
        width: "0.75rem",
        height: "0.75rem",
      },
      xl: {
        width: "1rem",
        height: "1rem",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

export default Indicator;
