import React, { useState } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { styled } from "../stitches.config";
import { csrf } from "../../utils/csrf.js";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import InputText from "../inputText";
import InputError from "../inputError";
import Button from "../button";

export default function SnowflakeConnectionForm({ source, org }) {
  const [name, setName] = useState(source.name);
  const [clientId, setClientId] = useState(source.client_id);
  const [clientSecret, setClientSecret] = useState(source.client_secret);
  const [username, setUsername] = useState(source.username);
  const [password, setPassword] = useState(source.password);
  const [role, setRole] = useState(source.role);
  const [accountUrl, setAccountUrl] = useState(source.account_url);
  const [warehouse, setWarehouse] = useState(source.warehouse);
  const [database, setDatabase] = useState(source.database);
  const [schema, setSchema] = useState(source.schema);

  return (
    <Form
      id="edit_source"
      action={`/settings/${org.slug}/warehouse-connection`}
      acceptCharset="UTF-8"
      method="post"
      data-turbo="false"
    >
      <input name="_method" type="hidden" value="patch" />
      <input name="source[type]" type="hidden" value="Snowflake::Source" />
      <input name="source[odbc]" type="hidden" value="true" />
      <input
        type="hidden"
        name="authenticity_token"
        value={csrf()}
        autoComplete="off"
      />

      <InputField>
        <InputLabel htmlFor="name">Name</InputLabel>
        <InputText
          aria-label="Name"
          id="name"
          name="source[name]"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          errors={source.errors?.name}
          autoFocus
          required
          autoComplete="off"
        />
        {source.errors?.name && (
          <InputError error={source.errors?.name} />
        )}
      </InputField>
      <InputField>
        <InputLabel htmlFor="account_url">Account URL</InputLabel>
        <InputText
          aria-label="Account URL"
          id="account_url"
          name="source[account_url]"
          type="text"
          value={accountUrl}
          onChange={(e) => setAccountUrl(e.target.value)}
          errors={source.errors?.account_url}
          required
        />
        {source.errors?.account_url && (
          <InputError error={source.errors?.account_url} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="username">Username</InputLabel>
        <InputText
          aria-label="Username"
          id="username"
          name="source[username]"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          errors={source.errors?.username}
          required
          autoComplete="off"
        />
        {source.errors?.username && (
          <InputError error={source.errors?.username} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="password">Password</InputLabel>
        <InputText
          aria-label="Password"
          id="password"
          name={password && password !== "" && "source[password]"}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          errors={source.errors?.password}
          autoComplete="off"
          placeholder="Enter Password"
        />
        {source.errors?.password && (
          <InputError error={source.errors?.password} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="warehouse">Warehouse</InputLabel>
        <InputText
          aria-label="Warehouse"
          id="warehouse"
          name="source[warehouse]"
          type="text"
          value={warehouse}
          onChange={(e) => setWarehouse(e.target.value)}
          errors={source.errors?.warehouse}
          required
        />
        {source.errors?.warehouse && (
          <InputError error={source.errors?.warehouse} />
        )}
      </InputField>
      <InputField>
        <InputLabel htmlFor="database">Database</InputLabel>
        <InputText
          aria-label="Database"
          id="database"
          name="source[database]"
          type="text"
          value={database}
          onChange={(e) => setDatabase(e.target.value)}
          errors={source.errors?.database}
          required
        />
        {source.errors?.database && (
          <InputError error={source.errors?.database} />
        )}
      </InputField>
      <InputField>
        <InputLabel htmlFor="schema">Schema</InputLabel>
        <InputText
          aria-label="Schema"
          id="schema"
          name="source[schema]"
          type="text"
          value={schema}
          onChange={(e) => setSchema(e.target.value)}
          errors={source.errors?.schema}
          required
        />
        {source.errors?.schema && (
          <InputError error={source.errors?.schema} />
        )}
      </InputField>
      <InputField>
        <InputLabel htmlFor="role">Role</InputLabel>
        <InputText
          aria-label="Role"
          id="role"
          name="source[role]"
          type="text"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          errors={source.errors?.role}
          required
        />
        {source.errors?.role && (
          <InputError error={source.errors?.role} />
        )}
      </InputField>

      <Button type="submit">Save Connection</Button>
    </Form>
  );
}

const Form = styled("form", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "1.25rem",

  "> button": {
    marginTop: "1rem",
  },
});
