import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross1Icon } from "@radix-ui/react-icons";
import { keyframes } from "@stitches/react";
import { styled } from "./stitches.config";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translateX(100%)" },
  "100%": { opacity: 1, transform: "translateX(0%)" },
});

export const DialogContent = React.forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <DialogPrimitive.Portal>
      <DialogOverlay />

      <Content {...props} ref={forwardedRef}>
        <DialogClose aria-label="Close">
          <Cross1Icon />
        </DialogClose>
        <ContentScroll>
          <ContentWrapper>{children}</ContentWrapper>
        </ContentScroll>
      </Content>
    </DialogPrimitive.Portal>
  )
);

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;

const DialogOverlay = styled(DialogPrimitive.Overlay, {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "hsla(0, 0%, 0%, 0.5)",
  width: "100vw",
  height: "100vh",
  transition: "$transitionLong",
  zIndex: "calc($zDialog - 1)",

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const Content = styled(DialogPrimitive.Content, {
  position: "fixed",
  top: "0",
  right: "0",
  backgroundColor: "white",
  height: "100vh",
  width: "100vw",
  zIndex: "$zDialog",

  "@md": {
    width: "40rem",
  },

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${contentShow} 500ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const ContentScroll = styled("div", {
  padding: "2.5rem 1rem",
  overflowY: "scroll",
  height: "100%",

  "@md": {
    padding: "5rem",
  },
});

const ContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  minHeight: "100%",
  gap: "1rem",
});

const DialogClose = styled(DialogPrimitive.Close, {
  position: "absolute",
  top: "1rem",
  right: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "$slate11",
  cursor: "pointer",
  borderRadius: "50%",
  transition: "$transitionBase",
  backgroundColor: "$slate2",
  padding: "1rem",
  border: "none",

  svg: {
    width: "0.875rem",
    height: "0.875rem",
  },

  "&:hover": {
    color: "$slate12",
  },

  "@md": {
    color: "$slate5",
    border: "1px solid $slate9",
    backgroundColor: "transparent",
    padding: "2.5rem",
    top: "50%",
    left: "-10rem",
    right: "initial",
    transform: "translateY(-50%)",

    svg: {
      width: "1.25rem",
      height: "1.25rem",
    },

    "&:hover": {
      color: "$slate2",
      borderColor: "$slate6",
    },
  },
});
