import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";

import Pagination from "../pagination";
import { styled } from "../stitches.config";
import { client } from "../../utils/client";
import { periodAtom, periodCompareAtom } from "../helpers/atoms";

import ControlsSearch from "../table/controlsSearch";

import BarChartRow from "./barChartRow";
import BarChartRowCompare from "./barChartRowCompare";

const rowLookup = {
  "Source/Medium": {
    fetch: "sourceMediums",
    fetchCompare: "sourceMediumComparisons",
    return: "source_mediums",
    search: "source_medium",
  },
  Referral: {
    fetch: "referrals",
    fetchCompare: "referralComparisons",
    return: "referrals",
    search: "referral",
  },
  "Traffic Channel": {
    fetch: "trafficChannels",
    fetchCompare: "trafficChannelComparisons",
    return: "traffic_channels",
    search: "traffic_channel",
  },
  Country: {
    fetch: "countries",
    fetchCompare: "countryComparisons",
    return: "countries",
    search: "country",
  },
  Device: {
    fetch: "devices",
    fetchCompare: "deviceComparisons",
    return: "devices",
    search: "device",
  },
  "Operating System": {
    fetch: "operatingSystems",
    fetchCompare: "operatingSystemComparisons",
    return: "operating_systems",
    search: "operating_system",
  },
  Browser: {
    fetch: "browsers",
    fetchCompare: "browserComparisons",
    return: "browsers",
    search: "browser",
  },
};

export default function BarChart({ title, value }) {
  const [period, setPeriod] = useAtom(periodAtom);
  const [periodCompare, setPeriodCompare] = useAtom(periodCompareAtom);

  if (periodCompare.active) {
    return (
      <BarChartCompare
        title={title}
        value={value}
        intervalCount={period.intervalCount}
        interval={period.interval}
        date={period.date}
      />
    );
  } else {
    return (
      <BarChartSingle
        title={title}
        value={value}
        intervalCount={period.intervalCount}
        interval={period.interval}
        date={period.date}
      />
    );
  }
}

function BarChartSingle({ title, value, interval, intervalCount, date }) {
  const [sum, setSum] = useState(0);
  const [data, setData] = useState();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState("");

  const fetchParams = {
    date: date,
    interval_count: intervalCount,
    interval: interval,
    page: pageIndex,
    per: pageSize,
  };

  if (search) {
    fetchParams[rowLookup[title].search] = search;
  }

  useEffect(() => {
    client[rowLookup[title].fetch](fetchParams).then((r) => {
      setSum(r.sum);
      setPageCount(r.total_pages);
      setData(r[rowLookup[title].return]);
    });
  }, [date, interval, intervalCount, pageIndex, pageSize, search]);

  return (
    <Container>
      <SearchWrapper>
        <ControlsSearch
          value={search ?? ""}
          onChange={(value) => setSearch(String(value))}
          placeholder="Search all..."
        />
      </SearchWrapper>

      {data &&
        data.map(({ name, count }, index) => (
          <BarChartRow key={index} name={name} count={count} sum={sum} />
        ))}

      <PaginationWrapper>
        <Pagination
          currentPage={pageIndex}
          setCurrentPage={setPageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalPages={pageCount}
        />
      </PaginationWrapper>
    </Container>
  );
}

function BarChartCompare({ title, value, interval, intervalCount, date }) {
  const [periodCompare, setPeriodCompare] = useAtom(periodCompareAtom);

  const [sum, setSum] = useState(0);
  const [sumCompare, setSumCompare] = useState(0);
  const [data, setData] = useState();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState("");

  const fetchParams = {
    date: date,
    previous_date: periodCompare.date,
    interval_count: intervalCount,
    interval: interval,
    page: pageIndex,
    per: pageSize,
  };

  if (search) {
    fetchParams[rowLookup[title].search] = search;
  }

  useEffect(() => {
    client[rowLookup[title].fetchCompare](fetchParams).then((r) => {
      setSum(r.sum);
      setSumCompare(r.previous_sum);
      setPageCount(r.total_pages);
      setData(r[rowLookup[title].return]);
    });
  }, [date, interval, intervalCount, pageIndex, pageSize, search]);

  return (
    <Container>
      <SearchWrapper>
        <ControlsSearch
          value={search ?? ""}
          onChange={(value) => setSearch(String(value))}
          placeholder="Search all..."
        />
      </SearchWrapper>

      {data &&
        data.map((row, index) => (
          <BarChartRowCompare
            key={index}
            row={row}
            sum={sum}
            sumPrevious={sumCompare}
          />
        ))}

      <PaginationWrapper>
        <Pagination
          currentPage={pageIndex}
          setCurrentPage={setPageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalPages={pageCount}
        />
      </PaginationWrapper>
    </Container>
  );
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const PaginationWrapper = styled("div", {
  padding: "0 $10 $5",
});

const SearchWrapper = styled("div", {
  padding: "$5 $10 $1",
  borderBottom: "1px solid $slate4",
});
