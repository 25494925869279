import React from "react";

import { styled } from "../stitches.config";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../tabs";
import BarChart from "../charts/barChart";

export default function Acquisition() {
  return (
    <Container>
      <Header>
        <Title>Acquisition</Title>
      </Header>

      <Tabs defaultValue="referrals">
        <TabsList>
          <TabsTrigger value="sourceMediums">Source/Medium</TabsTrigger>
          <TabsTrigger value="referrals">Referrals</TabsTrigger>
          <TabsTrigger value="trafficChannels">Traffic Channel</TabsTrigger>
        </TabsList>

        <TabsContent value="sourceMediums">
          <BarChart title="Source/Medium" value="sourceMediums" />
        </TabsContent>
        <TabsContent value="referrals">
          <BarChart title="Referral" value="referrals" />
        </TabsContent>
        <TabsContent value="trafficChannels">
          <BarChart title="Traffic Channel" value="trafficChannels" />
        </TabsContent>
      </Tabs>
    </Container>
  );
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div", {
  display: "flex",
  padding: "$5",
  borderBottom: "1px solid $slate4",

  "@lg": {
    padding: "$5 $10",
  },
});

const Title = styled("h3", {
  fontSize: "1.25rem",
});
