import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CalendarIcon,
  ClockIcon,
  CaretSortIcon,
} from "@radix-ui/react-icons";
import { styled } from "./stitches.config";

const StyledValue = styled(SelectPrimitive.Value, {
  fontSize: "1rem",
  fontWeight: "400",
  color: "$slate11",
  marginLeft: "1rem",
});

const StyledIcon = styled(SelectPrimitive.Icon, {
  lineHeight: 0,
  color: "$slate11",
  transition: "$transitionBase",

  variants: {
    type: {
      start: {
        marginRight: "0.5rem",
      },
      end: {
        marginLeft: "auto",
      },
    },
  },

  defaultVariants: {
    type: "start",
  },

  svg: {
    width: "16px",
    height: "16px",
  },
});

const StyledTrigger = styled(SelectPrimitive.Trigger, {
  alignItems: "center",
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-start",
  textAlign: "left",
  position: "relative",
  borderRadius: "0px",
  color: "$slate12",
  fontSize: "0.875rem",
  width: "100%",
  transition: "$transitionBase",

  "@md": {
    width: "auto",
  },

  "&:focus-visible, &:focus": {
    borderColor: "$slate11",
    outline: "none",
  },

  "span:first-of-type": {
    display: "flex",
    alignItems: "center",
  },

  variants: {
    variant: {
      base: {
        border: "1px solid $slate6",

        fontWeight: "400",

        "&:hover": {
          border: "1px solid $slate10",
        },
      },
    },
    size: {
      sm: {
        height: "2rem",
        padding: "0 0.5rem",
        gap: "0.5rem",

        svg: {
          width: "1rem",
          height: "1rem",
        },
      },
      md: {
        height: "2.75rem",
        padding: "0 0.75rem",

        "@md": {
          minWidth: "12.5rem",
        },
      },
    },
  },

  defaultVariants: {
    variant: "base",
    size: "md",
  },
});

const StyledContent = styled(SelectPrimitive.Content, {
  backgroundColor: "white",
  borderRadius: "0px",
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  overflow: "hidden",
  zIndex: "500",
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  border: "1px solid white",
});

const StyledGroup = styled(SelectPrimitive.Group, {
  position: "relative",
});

const scrollButtonStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "1.5rem",
  backgroundColor: "white",
  color: "$slate12",
  cursor: "default",
};

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
);

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
);

const StyledRoot = styled(SelectPrimitive.Root, {});

export const SelectSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  backgroundColor: "$slate3",
  margin: "0.5rem",
});

export const SelectLabel = styled(SelectPrimitive.Label, {
  fontSize: "0.75rem",
  color: "$slate11",
  padding: "0.75rem 0.5rem 0.5rem 1rem",
});

export const Select = React.forwardRef(
  (
    { variant, size, check, icon, children, customTrigger, ...props },
    forwardedRef
  ) => {
    return (
      <StyledRoot {...props}>
        <StyledTrigger ref={forwardedRef} variant={variant} size={size}>
          {icon && (
            <StyledIcon>
              {icon === "calendar" ? (
                <CalendarIcon />
              ) : icon === "clock" ? (
                <ClockIcon />
              ) : null}
            </StyledIcon>
          )}

          <StyledValue />

          <StyledIcon type="end">
            {variant === "organizationSwitcher" ? (
              <CaretSortIcon />
            ) : (
              <ChevronDownIcon />
            )}
          </StyledIcon>
        </StyledTrigger>

        <SelectPrimitive.Portal>
          <StyledContent>
            <StyledScrollUpButton>
              <ChevronUpIcon />
            </StyledScrollUpButton>

            <StyledViewport>{children}</StyledViewport>

            <StyledScrollDownButton>
              <ChevronDownIcon />
            </StyledScrollDownButton>
          </StyledContent>
        </SelectPrimitive.Portal>
      </StyledRoot>
    );
  }
);

export const SelectGroup = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    return (
      <StyledGroup {...props} ref={forwardedRef}>
        {children}
      </StyledGroup>
    );
  }
);

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: "absolute",
  display: "flex",
  lineHeight: "0",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const StyledItem = styled(SelectPrimitive.Item, {
  all: "unset",
  alignItems: "center",
  borderRadius: "0px",
  color: "$slate12",
  display: "flex",
  fontSize: "0.875rem",
  fontWeight: "400",
  position: "relative",
  userSelect: "none",

  "&:hover": {
    backgroundColor: "$slate1",
  },

  "&:focus": {
    backgroundColor: "$slate2",
    color: "$gray900",
  },

  variants: {
    size: {
      sm: {
        padding: "0 1rem 0 0.5rem",
        height: "2rem",
        gap: "0.25rem",

        svg: {
          width: "1rem",
          height: "1rem",
        },

        span: {
          display: "flex",
          alignItems: "center",
        },

        [`${StyledItemIndicator}`]: {
          right: "0.5rem",

          svg: {
            width: "0.875rem",
            height: "0.875rem",
          },
        },
      },
      md: {
        padding: "0 0.25rem 0 2.25rem",
        height: "2.75rem",

        [`${StyledItemIndicator}`]: {
          left: "0.5rem",

          svg: {
            width: "1.25rem",
            height: "1.25rem",
          },
        },
      },
    },
    check: {
      right: {
        [`${StyledItemIndicator}`]: {
          position: "absolute",
          right: "0rem",
        },
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

const StyledItemValue = styled(SelectPrimitive.ItemText, {});

export const SelectItem = React.forwardRef(
  ({ size, check, children, ...props }, forwardedRef) => {
    return (
      <StyledItem {...props} ref={forwardedRef} size={size} check={check}>
        <StyledItemIndicator>
          <CheckIcon />
        </StyledItemIndicator>

        <StyledItemValue>{children}</StyledItemValue>
      </StyledItem>
    );
  }
);
