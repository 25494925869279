import React, { useState } from "react";
import { Cross } from "hamburger-react";
import { styled } from "../stitches.config";

export default function SettingsNavigation({ active, org }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <Nav>
      <Trigger>
        <Title>Settings</Title>
        <Cross toggled={isOpen} toggle={setOpen} size={20} />
      </Trigger>

      <Content open={isOpen}>
        <NavItem active={active === "account" ? true : false}>
          <Link href="/settings/account">Account</Link>
        </NavItem>

        <Label>{org.name}</Label>

        <Group>
          <NavItem active={active === "organization" ? true : false}>
            <Link href={`/settings/${org.slug}/organization`}>
              Organization
            </Link>
          </NavItem>

          <NavItem active={active === "warehouse" ? true : false}>
            <Link href={`/settings/${org.slug}/warehouse-connection`}>
              Warehouse Connection
            </Link>
          </NavItem>

          <NavItem active={active === "members" ? true : false}>
            <Link href={`/settings/${org.slug}/members`}>Members</Link>
          </NavItem>

          <NavItem active={active === "billing" ? true : false}>
            <Link href={`/settings/${org.slug}/billing`}>Billing</Link>
          </NavItem>
        </Group>
      </Content>
    </Nav>
  );
}

const Nav = styled("div", {
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  listStyle: "none",
  gap: "0.5rem",
  background: "white",
  width: "100%",
  position: "absolute",
  zIndex: 1,
  top: "4rem",
  left: 0,
  borderBottom: "1px solid $slate3",

  "@md": {
    borderBottom: "none",
    position: "relative",
    top: "initial",
    left: "initial",
  },
});

const Trigger = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: "0.25rem",

  "@md": {
    display: "none",
  },
});

const Title = styled("h6", {
  color: "$slate11",
  fontSize: "0.875rem",
  margin: 0,
  padding: "0.5rem 1rem",
  textTransform: "uppercase",
});

const Content = styled("div", {
  zIndex: 2,
  width: "100%",
  padding: "0 0.5rem 1rem",
  flexDirection: "column",

  "@md": {
    padding: "0",
  },

  variants: {
    open: {
      true: {
        display: "flex",
      },
      false: {
        display: "none",

        "@md": {
          display: "flex",
        },
      },
    },
  },
});

const Label = styled("h6", {
  color: "$slate11",
  fontSize: "0.875rem",
  padding: "0.5rem 0.75rem",
  textDecoration: "none",
  display: "block",
  textTransform: "uppercase",
  fontWeight: "500",
  letterSpacing: "0.05em",

  "@md": {
    fontSize: "0.75rem",
  },
});

const Group = styled("ul", {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  listStyle: "none",
  marginLeft: "1rem",
});

const Link = styled("a", {
  color: "$slate11",
  cursor: "pointer",
  fontSize: "1rem",
  padding: "0.75rem 0.75rem",
  textDecoration: "none",
  height: "100%",
  width: "100%",
  display: "block",

  "&:hover": {
    color: "$slate12",
  },

  "@md": {
    padding: "0.5rem 0.75rem",
    fontSize: "0.875rem",
  },
});

const NavItem = styled("li", {
  padding: "0",

  "&:hover": {
    color: "$slate12",

    [`& ${Link}`]: {
      backgroundColor: "$slate2",
      color: "$slate12",
    },
  },

  variants: {
    active: {
      true: {
        [`& ${Link}`]: {
          color: "$slate12",
        },
      },
    },
  },
});
