import React from "react";

export default function IconPathsNested() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3996 18.4996H7V20.5796H22.3996V18.4996ZM22.3996 13.4999H7V15.5799H22.3996V13.4999ZM7 8.41991H22.3996V10.4999L7 10.4999V8.41991ZM22.3996 3.41992H1.59961V5.49991H22.3996V3.41992Z"
        fill="currentColor"
      />
    </svg>
  );
}
