import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { styled } from "../stitches.config";
import {
  RadioGroup,
  RadioItem,
  RadioItemRadio,
  RadioItemIndicator,
  RadioItemLabel,
  RadioItemTitle,
  RadioItemDescription,
} from "../radio";

import { csrf } from "../../utils/csrf";
import { client } from "../../utils/client";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import InputText from "../inputText";
import InputError from "../inputError";
import Button from "../button";

export default function MembersInvitationNew({
  org,
  invitation,
  setDialogOpen,
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: { role: "member" } });

  const onSubmit = (data) => {
    client
      .post(`/orgs/${org.slug}/invitations`, data)
      .then((response) => {
        Turbo.visit(`/settings/${org.slug}/members`);
      })
      .catch(({ message, response }) => {
        console.log({ response, message });
        if (response) {
          response.then(({ flash, data }) => {
            if (data) {
              Object.keys(data.errors).map((key) => {
                setError(key, { type: "custom", message: data.errors[key][0] });
              });
            }
          });
        }
      });
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      id="new_invitation"
      action={`/orgs/${org.slug}/invitations`}
      acceptCharset="UTF-8"
      method="post"
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={csrf()}
        autoComplete="off"
      />

      <InputField>
        <InputLabel htmlFor="invitation_first_name">First Name</InputLabel>
        <InputText
          {...register("first_name")}
          errors={errors.first_name?.message}
          aria-label="First Name"
          type="text"
          autoComplete="on"
          defaultValue={invitation?.first_name}
          autoFocus
        />
        {errors.first_name && <InputError error={errors.first_name.message} />}
      </InputField>
      <InputField>
        <InputLabel htmlFor="invitation_last_name">Last Name</InputLabel>
        <InputText
          {...register("last_name")}
          errors={errors.last_name?.message}
          aria-label="Last Name"
          type="text"
          defaultValue={invitation?.last_name}
          autoComplete="on"
          autoFocus
        />
        {errors.last_name && <InputError error={errors.last_name} />}
      </InputField>
      <InputField>
        <InputLabel htmlFor="invitation_email">Email</InputLabel>
        <InputText
          {...register("email")}
          errors={errors.email?.message}
          aria-label="Email address"
          type="email"
          defaultValue={invitation?.email}
          autoComplete="on"
          autoFocus
        />
        {errors.email && <InputError error={errors.email.message} />}
      </InputField>

      <InputField layout="stacked">
        <InputLabel>Role</InputLabel>

        <RadioGroup
          {...register("role")}
          errors={errors.role?.message}
          defaultValue={invitation?.role || "member"}
          required={true}
        >
          <RadioItem>
            <RadioItemRadio value="admin" id="admin">
              <RadioItemIndicator />
            </RadioItemRadio>

            <RadioItemLabel htmlFor="admin">
              <RadioItemTitle>Administrator</RadioItemTitle>
              <RadioItemDescription>
                View access to the analytics dashboard. Edit access to the
                warehouse connection, and the ability to add and remove members.
              </RadioItemDescription>
            </RadioItemLabel>
          </RadioItem>

          <RadioItem>
            <RadioItemRadio value="member" id="member">
              <RadioItemIndicator />
            </RadioItemRadio>

            <RadioItemLabel htmlFor="member">
              <RadioItemTitle>Member</RadioItemTitle>
              <RadioItemDescription>
                View access to the analytics dashboard.
              </RadioItemDescription>
            </RadioItemLabel>
          </RadioItem>
        </RadioGroup>
        {errors.role && <InputError error={errors.role.message} />}
      </InputField>

      <Button type="submit">Submit</Button>
    </Form>
  );
}

const Form = styled("form", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "1.25rem",

  "> button": {
    marginTop: "1rem",
  },
});
