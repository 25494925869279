import React, { useState } from "react";
import { TriangleRightIcon } from "@radix-ui/react-icons";

import { styled } from "../stitches.config";
import { formatNumber, formatTime, formatPercent } from "../helpers/formatters";
import IconAquisition from "../icons/iconAcquisition";
import BarChart from "../charts/barChart";
import TableBarChart from "./tableBarChart";
import { Bar } from "@nivo/bar";

export default function TableRow({
  row,
  subRow,
  columns,
  columnOrder,
  expandedRows,
  pathRollup,
  togglePathExpand,
}) {
  const expanded = expandedRows.includes(row.path);

  const [expandedAcquisition, setExpandedAcquisition] = useState(false);

  return (
    <>
      <StyledTableRow subRow={subRow}>
        <Control
          onClick={() => setExpandedAcquisition(!expandedAcquisition)}
          expanded={expandedAcquisition}
        >
          <IconAquisition />
        </Control>

        {columnOrder.map((columnId) => {
          const column = columns.find((c) => c.id === columnId);

          if (!column.visible) {
            return null;
          }
          let cellValue;
          switch (column.id) {
            case "path":
              cellValue = row.path;
              break;
            case "users":
              cellValue = formatNumber(row.users);
              cellPercentage = formatPercent((row.users / column.total) * 100);
              break;
            case "views":
              cellValue = formatNumber(row.views);
              cellPercentage = formatPercent((row.views / column.total) * 100);
              break;
            case "average_seconds_on_page":
              cellValue = formatTime(row.average_seconds_on_page);
              cellPercentage = null;
              break;
            case "entrances":
              cellValue = formatNumber(row.entrances);
              cellPercentage = formatPercent(
                (row.entrances / column.total) * 100
              );
              break;
            case "exits":
              cellValue = formatNumber(row.exits);
              cellPercentage = formatPercent((row.exits / column.total) * 100);
              break;
            case "bounce_rate":
              cellValue = formatPercent(row.bounce_rate);
              cellPercentage = null;
              break;
            default:
              cellValue = "";
          }

          if (column.id === "path") {
            return (
              <TableData
                key={column.id}
                kind={column.id === "path" ? "path" : undefined}
              >
                <Cell>
                  {row.sub_path_count > 1 ? (
                    <CellRollUp
                      expanded={expanded}
                      onClick={() => togglePathExpand(row.path)}
                    >
                      <TriangleRightIcon />
                    </CellRollUp>
                  ) : (
                    <CellRollUpIndent
                      state={
                        pathRollup ? "rolledUp" : subRow ? "childRow" : "base"
                      }
                    />
                  )}
                  <CellValue>{cellValue}</CellValue>

                  {row.sub_path_count > 1 && (
                    <CellRollUpPaths>
                      {formatNumber(row.sub_path_count)} subpaths
                    </CellRollUpPaths>
                  )}
                </Cell>
              </TableData>
            );
          } else {
            return (
              <TableData
                key={column.id}
                kind={column.id === "path" ? "path" : undefined}
              >
                <Cell>
                  <CellValue>{cellValue}</CellValue>
                  {cellPercentage && (
                    <CellPercentage>({cellPercentage})</CellPercentage>
                  )}
                </Cell>
              </TableData>
            );
          }
        })}
      </StyledTableRow>

      {expandedAcquisition && (
        <Acquisition expanded={expandedAcquisition}>
          <Col>
            <Title>Source/Medium</Title>
            <TableBarChart
              title="Source/Medium"
              value="sourceMediums"
              path={row.path}
            />
          </Col>
          <Col>
            <Title>Referral</Title>
            <TableBarChart title="Referral" value="referrals" path={row.path} />
          </Col>
          <Col>
            <Title>Traffic Channel</Title>
            <TableBarChart
              title="Traffic Channel"
              value="trafficChannels"
              path={row.path}
            />
          </Col>
        </Acquisition>
      )}
    </>
  );
}

export const StyledTableRow = styled("tr", {
  display: "flex",
  position: "relative",

  "&:before, &:after": {
    content: "''",
    display: "block",
    width: "100%",
    height: "1px",
    backgroundColor: "$slate7",
    position: "absolute",
    left: "0",
    zIndex: "1",
    opacity: "0",
  },

  "&:before": {
    top: "-1px",
  },

  "&:after": {
    bottom: "0",
  },

  variants: {
    subRow: {
      true: {
        background: "$slate1",
      },
      false: {
        background: "$white",
      },
    },
    kind: {
      header: {},
      body: {
        "&:hover": {
          "&:before, &:after": {
            opacity: "1",
          },
        },
      },
    },
  },

  defaultVariants: {
    kind: "body",
    state: "collapsed",
  },
});

const tableCellStyles = {
  textAlign: "right",
  fontWeight: "400",
  fontFamily: "$sans",
  position: "relative",
  fontSize: "0.875rem",
  border: "1px solid $slate3",
  borderWidth: "0 1px 1px 0",
  borderCollapse: "collapse",
  transition: "$transitionShort",

  "&:first-of-type": {
    borderWidth: "0 0 1px 0 ",
  },
  "&:last-of-type": {
    borderWidth: "0 0 1px 0",
  },

  variants: {
    kind: {
      default: {
        flex: "1 0 180px",
        minWidth: "180px",
      },
      path: {
        flex: "1 1 100%",
        minWidth: "240px",
        width: "100%",
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
};

const Cell = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "0.5rem",
});

const TableData = styled("td", {
  ...tableCellStyles,
  padding: "0.75rem",
  color: "$slate12",

  [`${Cell}`]: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  "&:nth-of-type(2)": {
    textAlign: "left",

    [`${Cell}`]: {
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },
});

const CellRollUp = styled("button", {
  appearance: "none",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  lineHeight: 0,
  color: "$slate12",
  transition: "$transitionShort",

  variants: {
    expanded: {
      true: { transform: "rotate(90deg)" },
      false: { transform: "rotate(0deg)" },
    },
  },

  defaultVariants: {
    state: false,
  },
});

const CellRollUpIndent = styled("div", {
  height: "15px",
  width: "15px",
  background: "transparent",

  variants: {
    state: {
      rolledUp: { width: "15px" },
      childRow: { width: "30px" },
      base: { width: "0px" },
    },
  },

  defaultVariants: {
    state: "base",
  },
});

const CellRollUpPaths = styled("div", {
  marginLeft: "auto",
  justifySelf: "flex-end",
  fontSize: "0.75rem",
  color: "$slate10",
});

const CellValue = styled("span", {
  display: "inline-block",
  color: "$slate12",
  wordBreak: "break-all",
});

const CellPercentage = styled("span", {
  fontSize: "0.675rem",
  color: "$slate10",
  fontWeight: "500",
  width: "2.75rem",
  display: "inline-block",
});

const Control = styled("td", {
  alignItems: "center",
  borderBottom: "1px solid $slate3",
  borderRight: "1px solid $slate3",
  borderLeft: "1px solid transparent",
  padding: "0.25rem 0.75rem",
  display: "flex",
  justifyContent: "center",
  color: "$slate10",
  cursor: "pointer",
  transition: "$transitionShort",

  "&:hover": {
    color: "$slate12",
    background: "$slate1",
    borderRight: "1px solid $slate7",
  },

  variants: {
    expanded: {
      true: {
        background: "$slate1",
        borderBottom: "1px solid $slate1",
        borderLeft: "1px solid $slate3",
        color: "$slate12",
      },
      false: {},
    },
  },

  defaultVariants: {
    expanded: false,
  },
});

const Acquisition = styled("div", {
  width: "100%",
  gridTemplateColumns: "repeat(3, 1fr)",
  background: "$slate1",
  border: "1px solid $slate3",
  borderTop: "none",

  variants: {
    expanded: {
      true: { display: "grid" },
      false: { display: "none" },
    },
  },

  defaultVariants: {
    expanded: false,
  },
});

const Col = styled("div", {
  borderLeft: "1px solid $slate3",

  "&:first-of-type": {
    borderLeft: "none",
  },
});

const Title = styled("h3", {
  fontSize: "1rem",
  padding: "$5 $10 0",
});
