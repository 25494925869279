import React, { useState } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { styled } from "../stitches.config";
import { csrf } from "../../utils/csrf.js";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import InputText from "../inputText";
import InputCheckbox from "../inputCheckbox";
import InputError from "../inputError";
import Button from "../button";
import BigQueryCredentialsUpload from "./bigQueryCredentialsUpload";

export default function BigQueryConnectionForm({ source, org }) {
  const [name, setName] = useState(source.name);
  const [namespace, setNamespace] = useState(source.namespace);
  const [projectId, setProjectId] = useState(source.project_id);
  const [credentialsFile, setCredentialsFile] = useState("");

  return (
    <Form
      id="edit_source"
      action={`/settings/${org.slug}/warehouse-connection`}
      acceptCharset="UTF-8"
      method="post"
      data-turbo="false"
      encType="multipart/form-data"
    >
      <input name="_method" type="hidden" value="patch" />
      <input name="source[type]" type="hidden" value="BigQuery::Source" />
      <input
        type="hidden"
        name="authenticity_token"
        value={csrf()}
        autoComplete="off"
      />

      <InputField>
        <InputLabel htmlFor="name">Name</InputLabel>
        <InputText
          aria-label="Name"
          id="name"
          name="source[name]"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          errors={source.errors?.name}
          autoFocus
          required
          autoComplete="off"
        />
        {source.errors?.name && (
          <InputError error={source.errors?.name} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="projectId">Project Id</InputLabel>
        <InputText
          aria-label="Project Id"
          id="projectId"
          name="source[project_id]"
          type="text"
          value={projectId}
          onChange={(e) => setProjectId(e.target.value)}
          errors={source.errors?.project_id}
          required
          autoComplete="off"
        />
        {source.errors?.project_id && (
          <InputError error={source.errors?.project_id} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="namespace">Namespace</InputLabel>
        <InputText
          aria-label="Namespace"
          id="namespace"
          name="source[namespace]"
          type="text"
          value={namespace}
          onChange={(e) => setNamespace(e.target.value)}
          errors={source.errors?.namespace}
          autoComplete="off"
        />
        {source.errors?.namespace && (
          <InputError error={source.errors?.namespace} />
        )}
      </InputField>

      <BigQueryCredentialsUpload source={source} />

      <Button type="submit">Save Connection</Button>
    </Form>
  );
}

const Form = styled("form", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "1.25rem",

  "> button": {
    marginTop: "1rem",
  },
});
