import { atom } from "jotai";

export const orgAtom = atom({});
export const sourceAtom = atom({});
export const membershipAtom = atom();
export const userAtom = atom();
export const userMembershipsAtom = atom();

export const latestImportDateAtom = atom();
export const periodAtom = atom();
export const periodCompareAtom = atom();
export const periodPanelAtom = atom(false);
export const compareAtom = atom(false);
