import React from "react";

import { styled } from "../stitches.config";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../tabs";
import BarChart from "../charts/barChart";

export default function Audience() {
  return (
    <Container>
      <Header>
        <Title>Audience</Title>
      </Header>

      <Tabs defaultValue="devices">
        <TabsList>
          <TabsTrigger value="countries">Countries</TabsTrigger>
          <TabsTrigger value="devices">Devices</TabsTrigger>
          <TabsTrigger value="operatingSystems">Operating Systems</TabsTrigger>
          <TabsTrigger value="browsers">Browsers</TabsTrigger>
        </TabsList>

        <TabsContent value="countries">
          <BarChart title="Country" value="countries" />
        </TabsContent>

        <TabsContent value="devices">
          <BarChart title="Device" value="devices" />
        </TabsContent>

        <TabsContent value="operatingSystems">
          <BarChart title="Operating System" value="operatingSystems" />
        </TabsContent>

        <TabsContent value="browsers">
          <BarChart title="Browser" value="browsers" />
        </TabsContent>
      </Tabs>
    </Container>
  );
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div", {
  display: "flex",
  padding: "$5",
  borderBottom: "1px solid $slate4",

  "@lg": {
    padding: "$5 $10",
  },
});

const Title = styled("h3", {
  fontSize: "1.25rem",
});
