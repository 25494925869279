import React, { useState } from "react";
import { styled } from "../stitches.config";
import Layout from "../layout";
import ImportingData from "../importingData";

export default function EmptyDataDashboard(props) {
  return (
    <Layout {...props}>
      <ImportingData />
    </Layout>
  );
}
