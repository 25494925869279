import React from "react";

import { styled } from "../stitches.config";
import {
  formatNumber,
  formatPercent,
  displayDifference,
  displayPercentageChange,
} from "../helpers/formatters";
import { Flex } from "../flex";

import Indicator from "../indicator";
import { CurrentPeriod, PreviousPeriod } from "../comparisons/periods";

export default function BarChartRow({ row, sum, sumPrevious }) {
  const { name, count, previous } = row;
  const percent = (count / sum) * 100;
  const previousPercent = (previous?.count / sumPrevious) * 100;

  const percentageChange = ((count - previous?.count) / previous?.count) * 100;
  const difference = count - previous?.count;

  return (
    <Row>
      <Flex justify="between" gap="xxs">
        <Text kind="name">{name === "" ? "direct" : name}</Text>

        <Flex gap="xxs" align="center">
          {displayDifference(difference)}
          {displayPercentageChange(percentageChange)}
        </Flex>
      </Flex>

      <Flex justify="between" align="center" gap="xxs">
        <CurrentPeriod />

        <Flex gap="xxs" align="end">
          <Text kind="count">{formatNumber(count)}</Text>
          <Text kind="percent">({formatPercent(percent)})</Text>
        </Flex>
      </Flex>

      <Flex justify="between" align="center" gap="xxs">
        <PreviousPeriod />

        <Flex gap="xxs" align="end">
          <Text kind="count">
            {previous ? formatNumber(previous.count) : "—"}
          </Text>
          <Text kind="percent">
            {previous ? "(" + formatPercent(previousPercent) + ")" : "—"}
          </Text>
        </Flex>
      </Flex>
    </Row>
  );
}

const Row = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  borderBottom: "1px solid $slate4",
  padding: "$2 $5",

  "@lg": {
    padding: "$4 $10",
  },
});

const Text = styled("div", {
  fontWeight: "400",

  variants: {
    kind: {
      name: {
        color: "$slate12",
        fontSize: "0.875rem",
      },
      period: {
        color: "$slate11",
        fontSize: "0.75rem",
      },
      count: {
        color: "$slate12",
        fontSize: "0.75rem",
      },
      percent: {
        color: "$slate11",
        fontSize: "0.675rem",
        textAlign: "right",
        width: "3.25rem",
      },
    },
  },
});
