import React, { useRef } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons";

import { styled } from "./stitches.config";
import Button from "./button";
import { Select, SelectItem } from "./select";

export default function Pagination({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  totalPages,
  subRow,
}) {
  const paginationPageInputRef = useRef(null);

  return (
    <PaginationWrapper subRow={subRow}>
      <PaginationGroup>
        <PaginationText>
          Page {currentPage} of {totalPages}
        </PaginationText>
      </PaginationGroup>

      <PaginationSection>
        <PaginationGroup variant="rows">
          <PaginationText>Show Rows</PaginationText>

          <Select
            size="sm"
            value={pageSize}
            onValueChange={(value) => setPageSize(value)}
          >
            {[10, 25, 50, 75, 100].map((pageSize) => (
              <SelectItem key={pageSize} value={pageSize} size="sm">
                {pageSize}
              </SelectItem>
            ))}
          </Select>
        </PaginationGroup>

        {totalPages > 1 && (
          <PaginationGroup variant="buttons">
            <Button
              size="sm"
              kind="secondary"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(1)}
            >
              <DoubleArrowLeftIcon />
            </Button>
            <Button
              size="sm"
              kind="secondary"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <ChevronLeftIcon />
            </Button>

            <PageInputWrapper>
              <PageInput
                type="number"
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setCurrentPage(page);
                }}
                value={currentPage}
                max={totalPages.toString()}
                min="1"
                ref={paginationPageInputRef}
              />
            </PageInputWrapper>

            <Button
              size="sm"
              kind="secondary"
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <ChevronRightIcon />
            </Button>
            <Button
              size="sm"
              kind="secondary"
              onClick={() => setCurrentPage(totalPages)}
            >
              <DoubleArrowRightIcon />
            </Button>
          </PaginationGroup>
        )}
      </PaginationSection>
    </PaginationWrapper>
  );
}

export const PaginationWrapper = styled("div", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  justifyContent: "space-between",
  flexDirection: "column",
  paddingTop: "1.5rem",
  flexWrap: "wrap",

  "@sm": {
    gap: "0.5rem",
    flexDirection: "row",
  },

  variants: {
    subRow: {
      true: {
        padding: "0.5rem 0.75rem",
        background: "$slate1",
        borderBottom: "1px solid $slate7",
      },
      false: {},
    },
  },
});

export const PaginationSection = styled("div", {
  alignItems: "center",
  display: "flex",
  gap: "1rem",
  justifyContent: "center",
  flexWrap: "wrap",

  "@md": {
    flexWrap: "nowrap",
    justifyContent: "flex-end",
  },
});

export const PaginationGroup = styled("div", {
  alignItems: "center",
  display: "flex",

  variants: {
    variant: {
      default: {
        gap: "2rem",
      },
      buttons: {
        gap: "0.25rem",
      },
      rows: {
        gap: "0.5rem",
      },
    },
  },

  defaultVariants: {
    variant: "default",
  },
});

export const PaginationText = styled("div", {
  fontSize: "0.75rem",
  color: "$slate11",
});

export const PageInputWrapper = styled("span", {
  position: "relative",
  appearance: "textfield",
  display: "flex",

  "input[type=number]": {
    WebkitAppearance: "none",
    MozAppearance: "textfield",
  },

  "input[type=number]::-webkit-inner-spin-button": {
    appearance: "none",
  },
});

export const PageInput = styled("input", {
  appearance: "none",
  background: "transparent",
  border: "1px solid $slate7",
  height: "2rem",
  width: "3rem",
  textAlign: "center",

  "&:focus, &:focus-within": {
    outline: "none",
    border: "1px solid $slate11",
  },
});
