import React from "react";
import { useAtom } from "jotai";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";

import { orgAtom } from "../helpers/atoms";
import { styled } from "../stitches.config";
import Button from "../button";
import { csrf } from "../../utils/csrf";

export default function ControlsExport(props) {
  const { date, intervalCount, interval, order, desc, path, pathRollup } = props;
  const [org] = useAtom(orgAtom);

  return (
    <Export
      action={pathRollup ? `/export/${org.slug}/path_rollups` : `/export/${org.slug}/path_aggregates`}
      method="post"
      acceptCharset="UTF-8"
      data-turbo="false"
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={csrf()}
        autoComplete="off"
      />

      <input type="hidden" name="date" value={date} />
      <input type="hidden" name="interval" value={interval} />
      <input type="hidden" name="interval_count" value={intervalCount} />
      <input type="hidden" name="path" value={path} />

      <Button size="sm" kind="action" type="submit">
        <ArrowTopRightIcon />
        Export
      </Button>
    </Export>
  );
}

const Export = styled("form", {
  display: "none",

  "@md": {
    display: "block",
  },
});
