import React, { useState, useEffect } from "react";
import { client } from "../../../utils/client"
import Layout from "../../layout";
import { styled } from "../../stitches.config";
import SettingsNavigation from "../../settings/navigation";
import Button from "../../button";
import SettingsLayout, {
  Header,
  Title,
  Subtitle,
  Intro,
  Container,
  Paragraph,
} from "../../settings/layout";

export default function Billing(props) {
  const { org, plans } = props
  const currentPlan = plans.find((p) => { return p.key === org.plan_key })

  const [planInterval, setPlanInterval] = useState(currentPlan?.interval || "monthly")
  const [visiblePlans, setVisiblePlans] = useState([])

  useEffect(() => {
    const orderedPlans = plans
      .filter((p) => { return p.interval === planInterval })
      .sort((a, b) => {
        if (a.total_cents > b.total_cents) {
          return 1
        } else {
          return -1
        }
      })

    setVisiblePlans(orderedPlans)
  }, [planInterval])

  const handlePlanChange = (plan) => {
    if (plan !== currentPlan) {
      client.checkout({ plan_key: plan.key })
        .then((response) => { window.location.href = response.redirect_url })
    }
  }

  return (
    <Layout {...props}>
      <SettingsLayout>
        <SettingsNavigation active="billing" {...props} />

        <Container>
          <Header as="header">
            <div>
              <Title>Billing</Title>
              <Intro>Edit your billing information.</Intro>
            </div>
          </Header>

          <Section>
            <Subtitle>Plan</Subtitle>
            <PlanInterval>
              <PlanIntervalButton active={planInterval === "monthly"} kind="action" onClick={() => { setPlanInterval("monthly") }}>Monthly</PlanIntervalButton>
              <PlanIntervalButton active={planInterval === "yearly"} kind="action" onClick={() => { setPlanInterval("yearly") }}>Yearly</PlanIntervalButton>
            </PlanInterval>

            {visiblePlans.map((plan) =>
              <Plan onClick={() => handlePlanChange(plan)} key={plan.key} active={plan.key === org.plan_key}>
                <PlanPrice>
                  <Price>{plan.display_price}</Price>
                  <Interval>{plan.interval}</Interval>
                </PlanPrice>
                <PlanDetails>
                  <PlanTitle>{plan.name}</PlanTitle>
                  <PlanDescription>{plan.description}</PlanDescription>
                  <PlanList>
                    {plan.features.map((feature, index) =>
                      <PlanListItem key={index}>{feature}</PlanListItem>
                    )}
                  </PlanList>
                </PlanDetails>
              </Plan>
            )}
          </Section>

          <Section>
            <Subtitle>Payment Method</Subtitle>

            <Empty>No Payment Method Needed</Empty>
          </Section>

          <Section>
            <Subtitle>Invoice History</Subtitle>

            <Empty>No Invoices</Empty>
          </Section>
        </Container>
      </SettingsLayout>
    </Layout>
  );
}

const Section = styled("div", {
  padding: "1rem 0",
  borderBottom: "1px solid $slate2",

  "&:last-of-type, &:first-of-type": {
    borderBottom: "none",
  },
});

const Plan = styled("div", {
  border: "1px solid $slate3",
  display: "grid",
  gridTemplateColumns: "1fr ",
  gap: "1rem",
  margin: "1rem 0 1rem 0",

  "@md": {
    gridTemplateColumns: "200px 1fr",
  },

  "&:hover": {
    border: "1px solid $slate10",
  },

  variants: {
    active: {
      true: {
        border: "1px solid $slate12",
      },
      false: {
        cursor: "pointer",
      }
    }
  }
});

const PlanPrice = styled("div", {
  padding: "1rem",
  background: "$slate2",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const Price = styled("h2", {
  fontSize: "1.5rem",
  fontWeight: 500,
  color: "$slate12",
  marginBottom: "0.25rem",
});

const Interval = styled("p", {
  fontSize: "0.875rem",
  fontWeight: 400,
  color: "$slate11",
  margin: "0",
  textTransform: "capitalize",
});

const PlanDetails = styled("div", {
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",

  "@md": {
    padding: "2rem",
  },
});

const PlanTitle = styled("h3", {
  fontSize: "1.125rem",
  fontWeight: 500,
  color: "$slate12",
  marginBottom: "0.5rem",
});

const PlanDescription = styled("p", {
  fontSize: "1rem",
  fontWeight: 400,
  color: "$slate11",
  marginBottom: "0.75rem",
});

const PlanList = styled("ul", {
  listStyle: "inside",
  padding: "0",
  margin: "0",
  fontSize: "0.875rem",
  fontWeight: 400,
});

const PlanListItem = styled("li", {
  color: "$slate11",
  marginBottom: "0.375rem",
});

const Empty = styled("div", {
  padding: "2rem 1rem",
  background: "$slate2",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "$slate11",
  fontSize: "0.875rem",
});

const PlanInterval = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const PlanIntervalButton = styled(Button, {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  variants: {
    active: {
      true: {
        textDecorationLine: "underline",
      }
    }
  }
});
