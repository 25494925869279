import React, { useState } from "react";
import Layout from "../../layout";
import SettingsNavigation from "../../settings/navigation";
import SettingsLayout, {
  Header,
  Title,
  Intro,
  Container,
  Content,
  List,
} from "../../settings/layout";
import { Dialog, DialogTrigger, DialogContent } from "../../dialog";
import Button from "../../button";
import MembersCard from "../../settings/membersCard";
import MembersInvitationNew from "../../settings/membersInvitationNew";
import MembersInvitationCard from "../../settings/membersInvitationCard";

export default function Members(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { role } = props.membership;

  return (
    <Layout {...props}>
      <SettingsLayout>
        <SettingsNavigation active="members" {...props} />

        <Container>
          <Header>
            <div>
              <Title>Organization Members</Title>
              <Intro>Add, remove, and edit members of your organization.</Intro>
            </div>

            {(role === "owner" || role === "admin") && (
              <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogTrigger asChild>
                  <Button>Invite</Button>
                </DialogTrigger>

                <DialogContent>
                  <Title>Invite Member</Title>
                  <MembersInvitationNew
                    setDialogOpen={setDialogOpen}
                    {...props}
                  />
                </DialogContent>
              </Dialog>
            )}
          </Header>

          <Content>
            <List>
              <Intro>Members</Intro>

              {props.memberships.map((membership) => (
                <MembersCard
                  org={props.org}
                  membership={membership}
                  role={role}
                />
              ))}
            </List>

            <List>
              <Intro>Invitations</Intro>
              {props.invitations.map((invitation) => (
                <MembersInvitationCard
                  name={invitation.name}
                  email={invitation.email}
                  role={invitation.role}
                  org={props.org}
                  invitation={invitation}
                  currentUserRole={role}
                />
              ))}
            </List>
          </Content>
        </Container>
      </SettingsLayout>
    </Layout>
  );
}
