import React from "react";
import { styled } from "./stitches.config";

export default function InputHelpText({ children }) {
  return <HelpText>{children}</HelpText>;
}

const HelpText = styled("p", {
  color: "$slate11",
  fontSize: "0.75rem",
});
