import React from "react";
import dayjs from "dayjs";
import { client } from "../../utils/client";
import { linearGradientDef } from "@nivo/core";
import {
  blue,
  indigo,
  crimson,
  red,
  amber,
  yellow,
  purple,
  violet,
  slate,
} from "@radix-ui/colors";
import { yesterday, periodStart } from "./dates";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export const chartColors = {
  users: blue.blue10,
  usersPrevious: blue.blue10,
  pageViews: amber.amber10,
  pageViewsPrevious: amber.amber10,
  sessions: crimson.crimson10,
  sessionsPrevious: crimson.crimson10,
  bounceRate: purple.purple10,
  bounceRatePrevious: purple.purple10,
};

export const chartGradients = [
  linearGradientDef("usersGradient", [
    { offset: 0, color: blue.blue9, opacity: 0.75 },
    { offset: 90, color: blue.blue9, opacity: 0 },
  ]),
  linearGradientDef("usersPreviousGradient", [
    { offset: 0, color: blue.blue9, opacity: 0.25 },
    { offset: 90, color: blue.blue9, opacity: 0 },
  ]),
  linearGradientDef("viewsGradient", [
    { offset: 0, color: amber.amber9, opacity: 0.75 },
    { offset: 90, color: amber.amber9, opacity: 0 },
  ]),
  linearGradientDef("viewsPreviousGradient", [
    { offset: 0, color: amber.amber9, opacity: 0.25 },
    { offset: 90, color: amber.amber9, opacity: 0 },
  ]),
  linearGradientDef("sessionsGradient", [
    { offset: 0, color: crimson.crimson9, opacity: 0.75 },
    { offset: 90, color: crimson.crimson9, opacity: 0 },
  ]),
  linearGradientDef("sessionsPreviousGradient", [
    { offset: 0, color: crimson.crimson9, opacity: 0.25 },
    { offset: 90, color: crimson.crimson9, opacity: 0 },
  ]),
  linearGradientDef("bounceRateGradient", [
    { offset: 0, color: purple.purple9, opacity: 0.75 },
    { offset: 90, color: purple.purple9, opacity: 0 },
  ]),
  linearGradientDef("bounceRatePreviousGradient", [
    { offset: 0, color: purple.purple9, opacity: 0.25 },
    { offset: 90, color: purple.purple9, opacity: 0 },
  ]),
];

export const chartFills = [
  { match: (d) => d.id === "users", id: "usersGradient" },
  { match: (d) => d.id === "usersPrevious", id: "usersPreviousGradient" },
  {
    match: (d) => d.id === "pageViews",
    id: "viewsGradient",
  },
  {
    match: (d) => d.id === "pageViewsPrevious",
    id: "viewsPreviousGradient",
  },
  {
    match: (d) => d.id === "sessions",
    id: "sessionsGradient",
  },
  {
    match: (d) => d.id === "sessionsPrevious",
    id: "sessionsPreviousGradient",
  },
  {
    match: (d) => d.id === "bounceRate",
    id: "bounceRateGradient",
  },
  {
    match: (d) => d.id === "bounceRatePrevious",
    id: "bounceRatePreviousGradient",
  },
];

export const barChartFills = [
  { match: (d) => d.data.id === "users", id: "usersGradient" },
  { match: (d) => d.data.id === "usersPrevious", id: "usersPreviousGradient" },
  {
    match: (d) => d.data.id === "pageViews",
    id: "viewsGradient",
  },
  {
    match: (d) => d.data.id === "pageViewsPrevious",
    id: "viewsPreviousGradient",
  },
  {
    match: (d) => d.data.id === "sessions",
    id: "sessionsGradient",
  },
  {
    match: (d) => d.data.id === "sessionsPrevious",
    id: "sessionsPreviousGradient",
  },
  {
    match: (d) => d.data.id === "bounceRate",
    id: "bounceRateGradient",
  },
  {
    match: (d) => d.data.id === "bounceRatePrevious",
    id: "bounceRatePreviousGradient",
  },
];

export function DashedChartLine({ series, lineGenerator, xScale, yScale }) {
  const dashedIds = [
    "usersPrevious",
    "pageViewsPrevious",
    "sessionsPrevious",
    "bounceRatePrevious",
  ];

  return series.map(({ id, data, color }, index) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={color}
      style={
        dashedIds.includes(id)
          ? {
              // simulate line will dash stroke when index is even
              strokeDasharray: "6, 8",
              strokeWidth: 2,
            }
          : {
              // simulate line with solid stroke
              strokeWidth: 2,
            }
      }
    />
  ));
}

export function paddedDates(date, days) {
  const endDate = dayjs(date).tz("UTC");
  const result = [];

  for (var i = 0; i < days; i++) {
    let x = endDate.subtract(i, "days");
    result.push({ x: x.format("YYYY-MM-DD"), y: 0 });
  }

  return result;
}

export function paddedSummaryData(date, days) {
  return [
    { id: "users", data: paddedDates(date, days) },
    { id: "pageViews", data: paddedDates(date, days) },
    { id: "bounceRate", data: paddedDates(date, days) },
    { id: "sessions", data: paddedDates(date, days) }
  ];
}

export function getChartData(date, intervalCount, interval, maxDate) {
  let startDate = periodStart(date, intervalCount, interval);
  let days;
  if (date > maxDate) {
    days = dayjs(maxDate).diff(startDate, "day") + 1
  } else {
    days = dayjs(date).diff(startDate, "day") + 1
  }

  let chartParams = {
    end_date: date,
    start_date: startDate.format("YYYY-MM-DD"),
    interval_count: 1,
    interval: "day",
    order: "date"
  };

  let summaryData = [
    { id: "users", data: [] },
    { id: "pageViews", data: [] },
    { id: "bounceRate", data: [] },
    { id: "sessions", data: [] }
  ]

  let summaryDataRolling = [
    { id: "users", data: [] },
    { id: "pageViews", data: [] },
    { id: "bounceRate", data: [] },
    { id: "sessions", data: [] }
  ]

  let barChartData = []
  let barChartDataRolling = []

  return client.aggregates(chartParams).then((response) => {
    const aggregateDateLookup = response.aggregates.reduce((accumulator, aggregate) => {
      accumulator[dayjs(aggregate.date).tz("UTC").format("YYYY-MM-DD")] = aggregate;
      return accumulator;
    }, {});

    const endDate = dayjs(date).tz("UTC");
    for (var i = 0; i < days; i++) {
      let currentDate = startDate.add(i, "days").format("YYYY-MM-DD");
      summaryData[0].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.users || 0 })
      summaryData[1].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.views || 0 })
      summaryData[2].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.bounce_rate || 0 })
      summaryData[3].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.sessions || 0 })

      summaryDataRolling[0].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.rolling_seven_day_users || 0 })
      summaryDataRolling[1].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.rolling_seven_day_views || 0 })
      summaryDataRolling[2].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.rolling_seven_day_bounce_rate || 0 })
      summaryDataRolling[3].data.unshift({ x: currentDate, y: aggregateDateLookup[currentDate]?.rolling_seven_day_sessions || 0 })

      barChartData.push({
        x: currentDate,
        users: aggregateDateLookup[currentDate]?.users || 0,
        pageViews: aggregateDateLookup[currentDate]?.views || 0,
        bounceRate: aggregateDateLookup[currentDate]?.bounce_rate || 0,
        sessions: aggregateDateLookup[currentDate]?.sessions || 0
      })

      barChartDataRolling.push({
        x: currentDate,
        users: aggregateDateLookup[currentDate]?.rolling_seven_day_users || 0,
        pageViews: aggregateDateLookup[currentDate]?.rolling_seven_day_views || 0,
        bounceRate: aggregateDateLookup[currentDate]?.rolling_seven_day_bounce_rate || 0,
        sessions: aggregateDateLookup[currentDate]?.rolling_seven_day_sessions || 0
      })
    }


    return {
      lineChartData: summaryData,
      lineChartDataRolling: summaryDataRolling,
      barChartData,
      barChartDataRolling,
    }
  })
}

export function combineDatesLineChart(current, previous) {
  let normalizedPreviousLines = [];

  for (let i = 0; i < current.length; i++) {
    let currentLine = current[i];
    let currentId = currentLine.id;
    let previousLine = previous.find((line) => line.id == currentId);
    let currentLineDataLength = currentLine.data.length
    let previousLineDataLength = previousLine.data.length

    let normalizedPreviousLine = { id: `${currentId}Previous`, data: [] };
    for (let j = 0; j < currentLineDataLength; j++) {
      normalizedPreviousLine.data.unshift({
        x: currentLine.data[(currentLineDataLength - 1 - j)].x,
        y: previousLine.data[(previousLineDataLength - 1 - j)]?.y || 0,
        xPrevious: previousLine.data[(previousLineDataLength - 1 - j)]?.x || null,
      });
    }

    normalizedPreviousLines.push(normalizedPreviousLine);
  }
  return current.concat(normalizedPreviousLines);
}

export function combineDatesBarChart(current, previous) {
  let normalizedBars = [];

  for (let i = 0; i < current.length; i++) {
    let currentBar = current[i];
    let previousBar = previous[i];

    let normalizedBar = Object.assign({}, currentBar);

    for (let key in previousBar) {
      normalizedBar[`${key}Previous`] = previousBar[key];
    }

    normalizedBars.push(normalizedBar);
  }

  return normalizedBars;
}
