import React, { useState } from "react";

import { csrf } from "../../utils/csrf.js";
import Layout from "../layout";
import { styled } from "../stitches.config";
import InputCheckbox from "../inputCheckbox";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import InputText from "../inputText";
import InputError from "../inputError";
import Button from "../button";
import Logo from "../logo";

export default function Registration(props) {
  const [email, setEmail] = useState(props.email);
  const [orgName, setOrgName] = useState(props.org_name);

  return (
    <Layout type="auth">
      <Wrapper>
        <Container>
          <Logo />

          <Content>
            <Title>Create an account</Title>
            <Paragraph>We're in an open beta.</Paragraph>

            <Form
              id="new_user"
              action="/signup"
              acceptCharset="UTF-8"
              method="post"
              data-turbo="false"
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={csrf()}
                autoComplete="off"
              />

              <InputField>
                <InputLabel htmlFor="user_org_name">
                  Organization Name
                </InputLabel>
                <InputText
                  aria-label="Organization name"
                  id="user_org_name"
                  name="user[org_name]"
                  type="text"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  errors={props.errors?.org_name}
                  autoFocus
                  required
                />
                {props.errors?.org_name && (
                  <InputError error={props.errors?.org_name} />
                )}
              </InputField>

              <InputField>
                <InputLabel htmlFor="user_email">Email</InputLabel>
                <InputText
                  aria-label="Email address"
                  id="user_email"
                  name="user[email]"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  errors={props.errors?.email}
                  autoFocus
                  required
                />
                {props.errors?.email && (
                  <InputError error={props.errors?.email} />
                )}
              </InputField>

              <InputField>
                <InputLabel htmlFor="user_password">Password</InputLabel>
                <InputText
                  aria-label="Password"
                  id="user_password"
                  name="user[password]"
                  type="password"
                  autoComplete="new-password"
                  required
                  errors={props.errors?.password}
                />
                {props.errors?.password && (
                  <InputError error={props.errors?.password} />
                )}
              </InputField>

              <InputField layout="row">
                <InputCheckbox
                  id="user_terms"
                  name="user[terms]"
                  errors={props.errors?.terms}
                />
                <InputLabel htmlFor="user_terms">
                  Agree to terms of service
                </InputLabel>
                {props.errors?.terms && (
                  <InputError error={props.errors?.terms} />
                )}
              </InputField>

              <Button type="submit">Submit</Button>
            </Form>
          </Content>

          <Action>
            Already have an account? <a href="/login">Sign in</a>
          </Action>
        </Container>

        <Spotlight>
          <img src="/img/auth-preview.png" />
        </Spotlight>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled("div", {
  background: "$slate2",
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "100%",
  height: "100vh",

  "@lg": {
    gridTemplateColumns: "1fr 1fr",
  },

  "@xl": {
    gridTemplateColumns: "1fr 2fr",
  },
});
const Container = styled("div", {
  background: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: "2.5rem 1rem",

  "@lg": {
    padding: "2.5rem",
  },

  "@xl": {
    padding: "2.5rem 5rem",
  },
});

const Content = styled("div", {
  width: "100%",
});

const Title = styled("h2", {
  color: "$slate12",
  fontSize: "1.5rem",
  marginBottom: "0.75rem",
});

const Paragraph = styled("p", {
  color: "$slate11",
  fontSize: "1rem",
});

const Form = styled("form", {
  marginTop: "2.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",

  "> button": {
    marginTop: "1rem",
  },
});

const Action = styled("p", {
  color: "$slate12",
  textAlign: "center",
  fontSize: "0.875rem",
  alignSelf: "center",

  "> a": {
    color: "$blue11",
    transition: "$transitionBase",

    "&:hover": {
      color: "$blue12",
    },
  },
});

const Spotlight = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "2.5rem 0",

  "> img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
});
