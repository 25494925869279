import React from "react";

export default function IconPaths() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3996 18.4997H1.59961V20.5797H22.3996V18.4997ZM22.3996 13.5H1.59961V15.58H22.3996V13.5ZM1.59961 8.42H22.3996V10.5L1.59961 10.5V8.42ZM22.3996 3.42001H1.59961V5.5H22.3996V3.42001Z"
        fill="currentColor"
      />
    </svg>
  );
}
