import React from "react";
import * as Switch from "@radix-ui/react-switch";
import { styled } from "./stitches.config";

export const SwitchRoot = styled(Switch.Root, {
  all: "unset",
  cursor: "pointer",
  width: 42,
  height: 25,
  backgroundColor: "$slate5",
  borderRadius: "9999px",
  position: "relative",
  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
  // boxShadow: `0 2px 10px ${blackA.blackA7}`,
  // "&:focus": { boxShadow: `0 0 0 2px black` },
  '&[data-state="checked"]': { backgroundColor: "$slate12" },
});

export const SwitchThumb = styled(Switch.Thumb, {
  display: "block",
  width: 21,
  height: 21,
  backgroundColor: "white",
  borderRadius: "9999px",
  boxShadow: `0 2px 2px $slate7`,
  transition: "transform 100ms",
  transform: "translateX(2px)",
  willChange: "transform",
  '&[data-state="checked"]': { transform: "translateX(19px)" },
});
