import React from "react";
import { styled } from "../stitches.config";

export default function SettingsLayout(props) {
  return <Layout {...props}>{props.children}</Layout>;
}

const Layout = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2rem",
  margin: "4rem auto 0",
  maxWidth: "1200px",
  width: "100%",

  "@md": {
    margin: "2rem auto 0",
    gridTemplateColumns: "15rem 1fr",
  },
});

export const Header = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid $slate3",
  paddingBottom: "2.5rem",

  p: {
    fontSize: "0.875rem",
    fontWeight: "400",
    color: "$slate11",
    margin: "0",
  },
});

export const Content = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  marginTop: "2rem",
});

export const List = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
});

export const Title = styled("h1", {
  fontSize: "1.5rem",
  fontWeight: "500",
  marginBottom: "0.5rem",
  color: "$slate12",
});

export const Subtitle = styled("h2", {
  color: "$slate12",
  marginBottom: "1rem",
  fontSize: "1rem",
});

export const Paragraph = styled("p", {
  color: "$slate11",
});

export const Intro = styled("p", {
  fontSize: "1rem",
  marginBottom: "0.25rem",
  fontWeight: "400",
  color: "$slate11",
});

export const Container = styled("div", {
  border: "1px solid $slate4",
  display: "flex",
  flexDirection: "column",
  padding: "1rem",

  "@md": {
    padding: "2.5rem",
  },
});
