import React from "react"
import { styled } from "./stitches.config"

export default React.forwardRef(({ errors, ...props }, ref) => (
  <InputField ref={ref} type="text" error={errors && true} {...props} />
))

const InputField = styled("input", {
  background: "white",
  border: "1px solid $slate5",
  borderRadius: "0px",
  color: "$slate12",
  height: "2.5rem",
  minWidth: "10rem",
  width: "100%",
  padding: "0.25rem 0.5rem",

  '&:hover': {
    border: '1px solid $slate8',
  },

  "&:focus-visible": {
    borderColor: "$blue10",
    boxShadow: "$inputFocus",
    outline: "none",
  },

  variants: {
    error: {
      true: {
        borderColor: "$crimson10",
        boxShadow: "$inputError",
      }
    }
  }
})
