import React, { useState } from "react";
import { useAtom } from "jotai";

import { periodCompareAtom } from "../helpers/atoms";
import { styled } from "../stitches.config";
import {
  formatNumber,
  formatTime,
  formatPercent,
  calculateDifference,
  calculatePercentageDifference,
  displayDifference,
  displayPercentageChange,
} from "../helpers/formatters";

import { Text } from "../text";
import Indicator from "../indicator";
import { Flex } from "../flex";
import { CurrentPeriod, PreviousPeriod } from "../comparisons/periods";

export default function TableTotals({ columnOrder, columns }) {
  return (
    <>
      {columnOrder.map((columnId) => {
        const column = columns.find((c) => c.id === columnId);

        if (!column.visible) {
          return null;
        }

        return (
          <TableTotal
            key={columnId}
            total={column.total}
            totalCompare={column.totalCompare}
            id={columnId}
          />
        );
      })}
    </>
  );
}

export function TableTotal({ total, totalCompare, id }) {
  const [periodCompare] = useAtom(periodCompareAtom);

  return (
    <TableHeader kind={id === "path" ? "path" : "default"}>
      {id !== "path" ? (
        <TableHeaderTotal>
          {periodCompare.active ? (
            <>
              <Comparison>
                {id !== "average_seconds_on_page" &&
                  id !== "bounce_rate" &&
                  displayDifference(calculateDifference(totalCompare, total))}

                {displayPercentageChange(
                  calculatePercentageDifference(totalCompare, total)
                )}
              </Comparison>

              <Total compare={periodCompare.active}>
                {id === "average_seconds_on_page"
                  ? formatTime(total)
                  : id === "bounce_rate"
                  ? formatPercent(total)
                  : formatNumber(total)}
              </Total>
              <Total compare={periodCompare.active}>
                {id === "average_seconds_on_page"
                  ? formatTime(totalCompare)
                  : id === "bounce_rate"
                  ? formatPercent(totalCompare)
                  : formatNumber(totalCompare)}
              </Total>
            </>
          ) : (
            <Total>
              {id === "average_seconds_on_page"
                ? formatTime(total)
                : id === "bounce_rate"
                ? formatPercent(total)
                : formatNumber(total)}
            </Total>
          )}

          <Percent>
            % of Total: 100% {!periodCompare.active && formatNumber(total)}
          </Percent>
        </TableHeaderTotal>
      ) : (
        <>
          {periodCompare.active && (
            <Periods>
              <Text kind="name">Totals</Text>

              <CurrentPeriod />
              <PreviousPeriod />
            </Periods>
          )}
        </>
      )}
    </TableHeader>
  );
}

const tableCellStyles = {
  backgroundColor: "white",
  textAlign: "right",
  fontWeight: "400",
  fontFamily: "$sans",
  position: "relative",
  fontSize: "0.875rem",
  border: "1px solid $slate3",
  borderWidth: "0 1px 1px 0",
  borderCollapse: "collapse",

  "&:first-of-type": {
    borderWidth: "0 0 1px 0 ",
  },
  "&:last-of-type": {
    borderWidth: "0 0 1px 0",
  },

  variants: {
    kind: {
      default: {
        flex: "1 0 180px",
        minWidth: "180px",
        alignItems: "flex-end",
      },
      path: {
        flex: "1 1 100%",
        minWidth: "240px",
        width: "100%",
        alignItems: "flex-start",
        padding: "1rem 1.25rem",
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
};

const TableHeader = styled("th", {
  ...tableCellStyles,
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  color: "$slate11",

  "&:first-of-type": {
    textAlign: "left",
    justifyContent: "flex-start",
  },
});

const TableHeaderTotal = styled("div", {
  padding: "0.75rem",
});

const Periods = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

const Comparison = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: "0.25rem",
});

const Total = styled("div", {
  color: "$slate12",
  marginBottom: "0.25rem",

  variants: {
    compare: {
      true: {
        fontSize: "1rem",
      },
      false: {
        fontSize: "1.375rem",
      },
    },
  },

  defaultVariants: {
    compare: "false",
  },
});

const Percent = styled("div", {
  fontSize: "0.675rem",
  color: "$slate11",
});
