import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import localforage from "localforage";
import * as ToggleGroup from "@radix-ui/react-toggle-group";

import { styled } from "../stitches.config";
import { Flex } from "../flex";
import TotalItem from "../comparisons/totalItem";
import { periodAtom, periodCompareAtom } from "../helpers/atoms";
import {
  formatPercent,
  formatNumber,
  displayPercentageChange,
  calculatePercentageDifference,
} from "../helpers/formatters";

import { client } from "../../utils/client";

export default function OverviewTabs({ selected, setSelected }) {
  const [period] = useAtom(periodAtom);
  const [periodCompare] = useAtom(periodCompareAtom);

  const [aggregates, setAggregates] = useState();

  const changeChartTab = (value) => {
    value.length !== 0 && setSelected(value);
    localforage.setItem("chartTabs", value);
  };

  useEffect(() => {
    const fetchParams = {
      date: period.date,
      interval_count: period.intervalCount,
      interval: period.interval,
      previous_date: periodCompare?.date,
    };

    client.aggregateComparisons(fetchParams).then((r) => {
      setAggregates(r.aggregates[0]);
    });

    localforage.getItem("chartTabs").then((value) => {
      if (value) {
        changeChartTab(value);
      }
    });
  }, [period.date, period.intervalCount, period.interval, periodCompare.date]);

  return (
    <TabGroup
      type="multiple"
      defaultValue={["users"]}
      value={selected}
      onValueChange={(value) => changeChartTab(value)}
    >
      <Tab value="users" theme="blue">
        <Indicator />
        <Flex gap="2" align="center" spacing="xxs">
          <Title>Users</Title>
          {displayPercentageChange(
            calculatePercentageDifference(
              aggregates?.previous?.users,
              aggregates?.users
            )
          )}
        </Flex>

        <TotalItem
          selected={selected.includes("users")}
          kind="users"
          total={formatNumber(aggregates?.users)}
          totalCompare={formatNumber(aggregates?.previous?.users)}
        />
      </Tab>
      <Tab value="pageViews" theme="yellow">
        <Indicator />
        <Flex gap="2" align="center" spacing="xxs">
          <Title>Page Views</Title>
          {displayPercentageChange(
            calculatePercentageDifference(
              aggregates?.previous?.views,
              aggregates?.views
            )
          )}
        </Flex>

        <TotalItem
          selected={selected.includes("pageViews")}
          kind="pageViews"
          total={formatNumber(aggregates?.views)}
          totalCompare={formatNumber(aggregates?.previous?.views)}
        />
      </Tab>
      <Tab value="sessions" theme="red">
        <Indicator />
        <Flex gap="2" align="center" spacing="xxs">
          <Title>Sessions</Title>
          {displayPercentageChange(
            calculatePercentageDifference(
              aggregates?.previous?.sessions,
              aggregates?.sessions
            )
          )}
        </Flex>

        <TotalItem
          selected={selected.includes("sessions")}
          kind="sessions"
          total={formatNumber(aggregates?.sessions)}
          totalCompare={formatNumber(aggregates?.previous?.sessions)}
        />
      </Tab>
      <Tab value="bounceRate" theme="purple">
        <Indicator />
        <Flex gap="2" align="center" spacing="xxs">
          <Title>Bounce Rate</Title>
          {displayPercentageChange(
            calculatePercentageDifference(
              aggregates?.previous?.bounce_rate,
              aggregates?.bounce_rate
            )
          )}
        </Flex>

        <TotalItem
          selected={selected.includes("bounceRate")}
          kind="bounceRate"
          total={formatPercent(aggregates?.bounce_rate)}
          totalCompare={formatPercent(aggregates?.previous?.bounce_rate)}
        />
      </Tab>
    </TabGroup>
  );
}

const TabGroup = styled(ToggleGroup.Root, {
  display: "grid",
  gridTemplateColumns: "repeat(1, 1fr)",
  width: "100%",
  borderBottom: "1px solid $slate4",

  "@sm": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  "@mg": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
});

const Indicator = styled("div", {
  position: "absolute",
  top: "1.65rem",
  left: "1.5rem",
  height: "0.5rem",
  width: "0.5rem",
  backgroundColor: "currentColor",
  borderRadius: "$round",
  opacity: "0",
  transition: "$transitionShort",
  transform: "scale(0)",
});

const Tab = styled(ToggleGroup.Item, {
  background: "transparent",
  border: "none",
  borderLeft: "1px solid $slate4",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  padding: "1.5rem 2.5rem",
  position: "relative",
  transition: "$transitionShort",
  borderTop: "1px solid $slate4",

  "@mg": {
    borderTop: "none",
    borderLeft: "1px solid $slate4",
  },

  "&:before": {
    content: "",
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "$slate1",
    transformOrigin: "center bottom",
    transform: "scaleY(0)",
    zIndex: "-1",
    transition: "$transitionBase",
  },

  "&:first-child": {
    borderLeft: "none",
  },

  "&:hover": {
    "&:before": {
      transform: "scaleY(1)",
    },
  },

  "&[data-state=on]": {
    background: "$slate1",

    "&:after": {
      content: "",
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "2px",
      backgroundColor: "currentColor",
    },

    [`& ${Indicator}`]: {
      opacity: "1",
      transform: "scale(1)",
    },
  },

  variants: {
    theme: {
      blue: {
        color: "$blue9",
      },
      yellow: {
        color: "$amber9",
      },
      red: {
        color: "$crimson9",
      },
      purple: {
        color: "$purple9",
      },
    },
  },
});

const Title = styled("h2", {
  fontSize: "0.75rem",
  color: "$slate11",
});

const Value = styled("h3", {
  fontSize: "1.25rem",
  color: "$slate12",

  "@md": {
    fontSize: "1.5rem",
  },

  "@lg": {
    fontSize: "2rem",
  },
});
