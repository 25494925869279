import React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { styled } from "./stitches.config";

export default function Checkbox({
  errors,
  checked,
  onCheckedChange,
  ...props
}) {
  return (
    <>
      <input {...props} type="hidden" value="0" />
      <StyledCheckbox
        error={errors && true}
        {...{ checked, onCheckedChange, ...props }}
      >
        <StyledIndicator>
          <CheckIcon />
        </StyledIndicator>
      </StyledCheckbox>
    </>
  );
}

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  backgroundColor: "white",
  border: "1px solid $slate5",
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "$transitionBase",

  "&:hover": {
    backgroundColor: "$slate1",
    border: "1px solid $slate8",
  },
  "&:focus": {
    borderColor: "$blue10",
    boxShadow: "$inputFocus",
    outline: "none",
  },

  variants: {
    size: {
      sm: {
        width: "0.75rem",
        height: "0.75rem",
      },
      md: {
        width: "1rem",
        height: "1rem",
      },
      lg: {
        width: "1.25rem",
        height: "1.25rem",
      },
    },

    error: {
      true: {
        borderColor: "$crimson10",
        boxShadow: "$inputError",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  alignItems: "center",
  display: "flex",
  color: "$slate12",
  justifyContent: "center",
});
