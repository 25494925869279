import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";

import { styled } from "../stitches.config";
import DemoOrganizationPopover from "./demoOrganizationPopover";
import ProfilePopover from "./profilePopover";
import SupportPopover from "./supportPopover";
import { latestImportDateAtom, periodAtom } from "../helpers/atoms";

import Logo, { Icon } from "../logo";
import { set } from "react-hook-form";
import PeriodPanel from "../period/periodPanel";
import Button from "../button";

export default function DemoNavigation() {
  const [period] = useAtom(periodAtom);
  const [latestImportDate, setLatestImportDate] = useAtom(latestImportDateAtom);
  const [isMobile, setMobile] = useState(window.innerWidth < 800);
  const route = window.location.pathname;

  const handleResize = () => {
    setMobile(window.innerWidth < 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Nav>
      <Col>
        <LogoWrapper href="/">{isMobile ? <Icon /> : <Logo />}</LogoWrapper>

        <DemoOrganizationPopover />
      </Col>

      <Col>
        <Button as="a" href="/signup" css={{ marginRight: "0.5rem" }}>
          Sign Up
        </Button>

        {period && latestImportDate && route === "/" && <PeriodPanel />}

        {!isMobile && <SupportPopover />}
      </Col>
    </Nav>
  );
}

const Nav = styled("nav", {
  alignItems: "center",
  borderBottom: "1px solid $slate3",
  display: "flex",
  height: "4rem",
  width: "100%",
  position: "fixed",
  backgroundColor: "white",
  justifyContent: "space-between",
  zIndex: "$zNav",
});

const Col = styled("div", {
  alignItems: "center",
  display: "flex",
  height: "100%",
});

const LogoWrapper = styled("a", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 1rem",
  lineHeight: 0,
  width: "4rem",

  "@md": {
    padding: "0 1.5rem",
    width: "initial",
  },
});
