import React from "react";
import { styled } from "./stitches.config";

export default function Logo({ theme }) {
  return (
    <LogoWrapper
      viewBox="0 0 123 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      theme={theme}
    >
      <path
        d="M34.5296 18.0343C33.3787 18.0343 32.3391 17.8504 31.4109 17.4826C30.5013 17.0964 29.7216 16.5907 29.0719 15.9655C28.4407 15.3218 27.9488 14.5771 27.5961 13.7312C27.2619 12.8852 27.0948 11.9842 27.0948 11.0279C27.0948 10.0717 27.2619 9.1706 27.5961 8.32469C27.9488 7.47878 28.4407 6.7432 29.0719 6.11796C29.7216 5.47434 30.5013 4.96863 31.4109 4.60084C32.3391 4.21467 33.3787 4.02158 34.5296 4.02158C35.6992 4.02158 36.7387 4.21467 37.6484 4.60084C38.5765 4.96863 39.3562 5.47434 39.9874 6.11796C40.6371 6.7432 41.1291 7.47878 41.4632 8.32469C41.8159 9.1706 41.9923 10.0717 41.9923 11.0279C41.9923 11.9842 41.8159 12.8852 41.4632 13.7312C41.1291 14.5771 40.6371 15.3218 39.9874 15.9655C39.3562 16.5907 38.5765 17.0964 37.6484 17.4826C36.7387 17.8504 35.6992 18.0343 34.5296 18.0343ZM34.5296 14.4208C35.0123 14.4208 35.4485 14.338 35.8384 14.1725C36.2468 13.9886 36.5902 13.7403 36.8687 13.4277C37.1471 13.1151 37.3606 12.7565 37.5091 12.352C37.6762 11.9474 37.7597 11.506 37.7597 11.0279C37.7597 10.5682 37.6762 10.136 37.5091 9.73147C37.3606 9.30852 37.1471 8.94992 36.8687 8.65569C36.5902 8.34308 36.2468 8.09482 35.8384 7.91093C35.4485 7.72703 35.0123 7.63509 34.5296 7.63509C34.047 7.63509 33.6107 7.72703 33.2209 7.91093C32.831 8.09482 32.4969 8.34308 32.2184 8.65569C31.94 8.94992 31.7265 9.30852 31.578 9.73147C31.4295 10.136 31.3552 10.5682 31.3552 11.0279C31.3552 11.506 31.4295 11.9474 31.578 12.352C31.7265 12.7565 31.94 13.1151 32.2184 13.4277C32.4969 13.7403 32.831 13.9886 33.2209 14.1725C33.6107 14.338 34.047 14.4208 34.5296 14.4208Z"
        fill="currentColor"
      />
      <path
        d="M43.8477 4.38017H48.1081V6.31105C48.4794 5.59387 49.0363 5.04219 49.7789 4.65601C50.54 4.25145 51.4496 4.04916 52.5077 4.04916C53.3802 4.04916 54.1321 4.18708 54.7632 4.46292C55.3944 4.72037 55.8956 5.08816 56.2669 5.56628C56.6939 6.08119 56.9723 6.67884 57.1023 7.35925C57.2508 8.02126 57.325 8.83039 57.325 9.78664V17.6757H53.0646V10.8624C53.0646 9.90617 52.8883 9.13382 52.5356 8.54536C52.2014 7.93851 51.5888 7.63509 50.6978 7.63509C49.8253 7.63509 49.1755 7.93851 48.7486 8.54536C48.3216 9.15221 48.1081 9.97053 48.1081 11.0003V17.6757H43.8477V4.38017Z"
        fill="currentColor"
      />
      <path
        d="M62.2442 4.38017L65.5021 12.7381L68.7879 4.38017H73.2432L67.1728 17.6757H63.7757L57.7888 4.38017H62.2442Z"
        fill="currentColor"
      />
      <path
        d="M76.6824 12.0485C76.7009 12.4531 76.7566 12.8393 76.8494 13.2071C76.9608 13.5565 77.1279 13.8691 77.3507 14.1449C77.592 14.4024 77.8983 14.6138 78.2696 14.7793C78.6409 14.9265 79.105 15 79.6619 15C80.4044 15 80.9613 14.8529 81.3326 14.5587C81.7224 14.2644 81.9916 13.9059 82.1401 13.4829H86.317C86.2242 14.1081 86.0014 14.6966 85.6487 15.2483C85.3145 15.8 84.8597 16.2873 84.2842 16.7102C83.7273 17.1148 83.059 17.4366 82.2794 17.6757C81.5182 17.9147 80.655 18.0343 79.6897 18.0343C78.5202 18.0343 77.4806 17.8688 76.571 17.5378C75.6799 17.1884 74.9281 16.701 74.3155 16.0758C73.7029 15.4506 73.2388 14.715 72.9232 13.8691C72.6076 13.0048 72.4498 12.0577 72.4498 11.0279C72.4498 10.0533 72.5983 9.14301 72.8954 8.2971C73.2109 7.45119 73.6658 6.71562 74.2598 6.09038C74.8724 5.44675 75.615 4.94105 76.4875 4.57326C77.3785 4.20547 78.4088 4.02158 79.5783 4.02158C80.6365 4.02158 81.5832 4.18708 82.4186 4.51809C83.2725 4.8491 83.9965 5.29044 84.5905 5.84212C85.2032 6.3938 85.6673 7.03743 85.9828 7.77301C86.317 8.50858 86.4841 9.28093 86.4841 10.0901C86.4841 10.3659 86.4748 10.6601 86.4562 10.9728C86.4562 11.2854 86.4191 11.644 86.3448 12.0485H76.6824ZM79.5505 6.83515C78.7151 6.83515 78.0282 7.07421 77.4899 7.55233C76.9515 8.03046 76.6824 8.72006 76.6824 9.62113H82.3072C82.3072 8.75684 82.0473 8.07643 81.5275 7.57992C81.0263 7.08341 80.3673 6.83515 79.5505 6.83515Z"
        fill="currentColor"
      />
      <path
        d="M98.1834 8.21435C97.8864 8.14079 97.6357 8.09482 97.4315 8.07643C97.2273 8.03965 96.9767 8.02126 96.6797 8.02126C95.5102 8.02126 94.5727 8.36146 93.8673 9.04187C93.1619 9.72228 92.8092 10.8716 92.8092 12.4899V17.6757H88.5488V4.38017H92.8092V6.53172C93.1248 5.90649 93.6445 5.35481 94.3685 4.87668C95.1111 4.39856 95.9557 4.1595 96.9025 4.1595C97.4037 4.1595 97.8307 4.23306 98.1834 4.38017V8.21435Z"
        fill="currentColor"
      />
      <path
        d="M108.959 8.21435C108.662 8.14079 108.412 8.09482 108.208 8.07643C108.003 8.03965 107.753 8.02126 107.456 8.02126C106.286 8.02126 105.349 8.36146 104.643 9.04187C103.938 9.72228 103.585 10.8716 103.585 12.4899V17.6757H99.3248V4.38017H103.585V6.53172C103.901 5.90649 104.421 5.35481 105.145 4.87668C105.887 4.39856 106.732 4.1595 107.679 4.1595C108.18 4.1595 108.607 4.23306 108.959 4.38017V8.21435Z"
        fill="currentColor"
      />
      <path
        d="M113.198 12.0485C113.217 12.4531 113.273 12.8393 113.365 13.2071C113.477 13.5565 113.644 13.8691 113.867 14.1449C114.108 14.4024 114.414 14.6138 114.786 14.7793C115.157 14.9265 115.621 15 116.178 15C116.92 15 117.477 14.8529 117.849 14.5587C118.238 14.2644 118.508 13.9059 118.656 13.4829H122.833C122.74 14.1081 122.517 14.6966 122.165 15.2483C121.83 15.8 121.376 16.2873 120.8 16.7102C120.243 17.1148 119.575 17.4366 118.795 17.6757C118.034 17.9147 117.171 18.0343 116.206 18.0343C115.036 18.0343 113.997 17.8688 113.087 17.5378C112.196 17.1884 111.444 16.701 110.831 16.0758C110.219 15.4506 109.755 14.715 109.439 13.8691C109.124 13.0048 108.966 12.0577 108.966 11.0279C108.966 10.0533 109.114 9.14301 109.411 8.2971C109.727 7.45119 110.182 6.71562 110.776 6.09038C111.388 5.44675 112.131 4.94105 113.003 4.57326C113.894 4.20547 114.925 4.02158 116.094 4.02158C117.152 4.02158 118.099 4.18708 118.935 4.51809C119.788 4.8491 120.512 5.29044 121.106 5.84212C121.719 6.3938 122.183 7.03743 122.499 7.77301C122.833 8.50858 123 9.28093 123 10.0901C123 10.3659 122.991 10.6601 122.972 10.9728C122.972 11.2854 122.935 11.644 122.861 12.0485H113.198ZM116.066 6.83515C115.231 6.83515 114.544 7.07421 114.006 7.55233C113.467 8.03046 113.198 8.72006 113.198 9.62113H118.823C118.823 8.75684 118.563 8.07643 118.043 7.57992C117.542 7.08341 116.883 6.83515 116.066 6.83515Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.54 5.57546L10.7118 0L7.52142 1.03077L10.9474 11.4788L19.1963 4.1095L16.9519 1.63406L12.54 5.57546Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.60089 16.4245L11.4291 22L14.6195 20.9692L11.1935 10.5212L2.9446 17.8905L5.18904 20.3659L9.60089 16.4245Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4004 12.4677L22.0143 10.6438L20.9707 7.47868L10.4507 10.8964L17.8881 19.0725L20.3782 16.8406L16.4004 12.4677Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.61388 9.59518L0 11.419L1.04361 14.5842L11.5636 11.1665L4.12623 2.99034L1.63605 5.22226L5.61388 9.59518Z"
        fill="currentColor"
      />
    </LogoWrapper>
  );
}

export function Icon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_326_874)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2282 8.10976L15.5706 0L10.9331 1.49931L15.9132 16.6964L27.9038 5.97746L24.6412 2.37681L18.2282 8.10976Z"
          fill="#11181C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9559 23.8902L16.6134 32L21.2509 30.5007L16.2709 15.3036L4.28027 26.0225L7.5428 29.6232L13.9559 23.8902Z"
          fill="#11181C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8397 18.1348L32 15.482L30.483 10.8781L15.1911 15.8493L26.0021 27.7418L29.6218 24.4954L23.8397 18.1348Z"
          fill="#11181C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16034 13.9566L0 16.6094L1.51699 21.2133L16.8089 16.2421L5.99789 4.34959L2.37817 7.59602L8.16034 13.9566Z"
          fill="#11181C"
        />
      </g>
      <defs>
        <clipPath id="clip0_326_874">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const LogoWrapper = styled("svg", {
  lineHeight: 0,

  variants: {
    size: {
      sm: {
        width: "104px",
      },
      md: {
        width: "120px",
      },
      lg: {
        width: "160px",
      },
    },
    theme: {
      light: {
        color: "$white",
      },
      dark: {
        color: "$slate12",
      },
    },
  },

  defaultVariants: {
    theme: "dark",
    size: "sm",
  },
});
