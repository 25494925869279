import React, { useState, useEffect } from "react";

import { client } from "../../utils/client";
import TableRowCompare from "./tableRowCompare";
import Pagination from "../pagination";
import { useAtom } from "jotai";
import { periodAtom, periodCompareAtom } from "../helpers/atoms";

export default function TableSubRowsCompare({
  row,
  sort,
  compare,
  columns,
  columnOrder,
  expandedRows,
  pathRollup,
}) {
  const [period] = useAtom(periodAtom);
  const [periodCompare] = useAtom(periodCompareAtom);

  const [subRows, setSubRows] = useState(row.subRows);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const fetchParams = {
    date: period.date,
    interval_count: period.intervalCount,
    interval: period.interval,
    page: pageIndex,
    per: pageSize,
    path: row.path,
    search_mode: "starts_with",
  };

  if (sort.id) {
    fetchParams.order = sort.id;
    fetchParams.desc = sort.desc;
  }

  useEffect(() => {
    if (periodCompare.active) {
      fetchParams.previous_date = periodCompare.date;
      client.pathAggregateComparisons(fetchParams).then((r) => {
        setPageCount(r.total_pages);
        setSubRows(r.path_aggregates);
      });
    } else {
      client.pathAggregates(fetchParams).then((r) => {
        setPageCount(r.total_pages);
        setSubRows(r.path_aggregates);
      });
    }
  }, [periodCompare.date, periodCompare.active, pageIndex, pageSize, sort]);

  return (
    <>
      {subRows.map((subRow, index) => {
        return (
          <TableRowCompare
            row={subRow}
            subRow={true}
            columns={columns}
            columnOrder={columnOrder}
            expandedRows={expandedRows}
            pathRollup={pathRollup}
            key={index}
          />
        );
      })}
      <tr>
        <Pagination
          subRow={true}
          currentPage={pageIndex}
          setCurrentPage={setPageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalPages={pageCount}
        />
      </tr>
    </>
  );
}
