import { createStitches } from "@stitches/react";
import {
  slate,
  blue,
  crimson,
  red,
  purple,
  green,
  amber,
} from "@radix-ui/colors";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      ...slate,
      ...blue,
      ...crimson,
      ...red,
      ...purple,
      ...green,
      ...amber,
    },
    fonts: {
      sans: "system-ui, sans-serif",
    },
    radii: {
      round: "100%",
      roundedSm: "0.25rem",
      roundedMd: "0.5rem",
      roundedLg: "1rem",
      roundedXl: "2rem",
    },
    shadows: {
      inputFocus: `0 0 0 3px ${blue.blue5}`,
      inputError: `0 0 0 3px ${crimson.crimson5}`,
      shadowSmall: `0 5px 10px rgba(0,0,0,.12)`,
      shadowMedium: `0 8px 30px rgba(0,0,0,.12)`,
      shadowLarge: `0 30px 60px rgba(0,0,0,.12)`,
    },
    space: {
      1: "0.25rem",
      2: "0.5rem",
      3: "0.75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      11: "3rem",
      12: "3.5rem",
      13: "4rem",
      14: "4.5rem",
      15: "5rem",
      16: "7.5rem",
      17: "10rem",
      18: "12.5rem",
      19: "15rem",
      20: "20rem",
      base: 10,
    },
    transitions: {
      transitionShort: "all 100ms ease-in-out",
      transitionBase: "all 200ms ease-in-out",
      transitionLong: "all 500ms ease-in-out",
    },
    zIndices: {
      zLost: -10,
      zBack: -1,
      zReset: 0,
      zBase: 1,
      zNav: 10,
      zSelect: 10,
      zPopover: 20,
      zDialog: 30,
      zToast: 40,
      zModal: 50,
    },
  },
  media: {
    xxs: "(min-width: 360px)",
    xs: "(min-width: 480px)",
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    mg: "(min-width: 980px)",
    lg: "(min-width: 1024px)",
    xl: "(min-width: 1280px)",
    xxl: "(min-width: 1440px)",
    xxxl: "(min-width: 1560px)",
  },
});
