import React from "react"
import { styled } from "./stitches.config"

export default function InputError({ error, ...props }) {
  return (
    <Wrapper {...props}>{error}</Wrapper>
  )
}

const Wrapper = styled("div", {
  color: "$crimson11",
  fontSize: "0.875rem",
  width: "100%",
})
