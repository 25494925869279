import React from "react";
import { useAtom } from "jotai";

import { styled } from "../stitches.config";
import { Flex } from "../flex";
import { Text } from "../text";
import { periodCompareAtom } from "../helpers/atoms";
import { CurrentPeriod, PreviousPeriod } from "./periods";

export default function TotalItem({ selected, kind, total, totalCompare }) {
  const [periodCompare] = useAtom(periodCompareAtom);

  if (periodCompare.active) {
    return (
      <Compare>
        <CompareRow>
          <Value compare={true}>{total}</Value>

          {selected && (
            <Flex align="center" gap="1">
              <Indicator kind={kind} previous="false" />
              <Text kind="period">Current</Text>
            </Flex>
          )}
        </CompareRow>
        <CompareRow>
          <Value compare={true}>{totalCompare}</Value>

          {selected && (
            <Flex align="center" gap="1">
              <Indicator kind={kind} previous="true" />
              <Text kind="period">Previous</Text>
            </Flex>
          )}
        </CompareRow>
      </Compare>
    );
  } else {
    return <Value>{total}</Value>;
  }
}

const Value = styled("h3", {
  textAlign: "left",
  color: "$slate12",

  variants: {
    compare: {
      true: {
        fontSize: "0.875rem",

        "@md": {
          fontSize: "1rem",
        },

        "@lg": {
          fontSize: "1.25rem",
        },
      },
      false: {
        fontSize: "1.25rem",

        "@md": {
          fontSize: "1.5rem",
        },

        "@lg": {
          fontSize: "2rem",
        },
      },
    },
  },

  defaultVariants: {
    compare: "false",
  },
});

const Compare = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const CompareRow = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 6.5rem",
});

const Indicator = styled("div", {
  alignItems: "center",
  background: "white",
  display: "flex",
  width: "10px",
  height: "10px",
  borderRadius: "100%",
  border: "2px solid $slate12",
  marginRight: "0.375rem",
  position: "relative",

  "&::before": {
    content: "''",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    display: "block",
    height: "2px",
    backgroundColor: "$slate12",
  },

  "&::after": {
    content: "''",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    display: "block",
    height: "2px",
    backgroundColor: "$slate12",
  },

  variants: {
    kind: {
      users: {
        borderColor: "$blue10",
        "&::before": {
          backgroundColor: "$blue10",
        },
        "&::after": {
          backgroundColor: "$blue10",
        },
      },
      pageViews: {
        borderColor: "$amber10",
        "&::before": {
          backgroundColor: "$amber10",
        },
        "&::after": {
          backgroundColor: "$amber10",
        },
      },
      sessions: {
        borderColor: "$crimson10",
        "&::before": {
          backgroundColor: "$crimson10",
        },
        "&::after": {
          backgroundColor: "$crimson10",
        },
      },
      bounceRate: {
        borderColor: "$purple10",
        "&::before": {
          backgroundColor: "$purple10",
        },
        "&::after": {
          backgroundColor: "$purple10",
        },
      },
    },
    previous: {
      true: {
        "&::before": {
          left: "-8px",
          width: "4px",
        },
        "&::after": {
          right: "-8px",
          width: "4px",
        },
      },
      false: {
        "&::before": {
          left: "-8px",
          width: "8px",
        },
        "&::after": {
          right: "-8px",
          width: "8px",
        },
      },
    },
  },
});
