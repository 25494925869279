import React from "react";
import { keyframes } from "@stitches/react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { styled } from "./stitches.config";
import { slate } from "@radix-ui/colors";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  borderRadius: "0px",
  border: "1px solid $slate4",
  backgroundColor: "white",
  margin: "0",
  position: "relative",
  zIndex: "$zPopover",
  boxShadow:
    "hsl(206 22% 7% / 20%) 0px 10px 38px -10px, hsl(206 22% 7% / 15%) 0px 10px 20px -15px",

  "&:focus": {
    outline: "none",
  },

  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },

  variants: {
    kind: {
      default: {
        width: "15rem",
      },
      organization: {
        width: "17.5rem",
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
});

const StyledArrow = styled(PopoverPrimitive.Arrow, {
  fill: "white",
});

const Content = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <PopoverPrimitive.Portal>
      <StyledContent ref={ref} {...props}>
        {children}
      </StyledContent>
    </PopoverPrimitive.Portal>
  );
});

const StyledTrigger = styled(PopoverPrimitive.Trigger, {
  appearance: "none",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  outline: "none",
});

const StyledClose = styled(PopoverPrimitive.Close, {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 25,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: "$slate12",
  position: "absolute",
  top: 5,
  right: 5,

  "&:hover": { backgroundColor: "$slate4" },
  "&:focus": { boxShadow: `0 0 0 2px ${slate.slate7}` },
});

const StyledTitle = styled("h4", {
  color: "$slate12",
  fontSize: "1rem",
  padding: "0.5rem 1rem 0.25rem 1rem",
});

const StyledSubtitle = styled("h5", {
  color: "$slate11",
  fontSize: "0.875rem",
  padding: "0.25rem 1.75rem 0.5rem 1.75rem",
});

const StyledGroup = styled("ul", {
  display: "flex",
  flexDirection: "column",
  listStyle: "none",
  borderTop: "1px solid $slate4",

  "&:first-of-type": {
    borderTop: "none",
  },

  variants: {
    kind: {
      default: {},
      tableColumns: {
        padding: "0.75rem 0",
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
});

export const PopoverGroup = ({ children, ...props }) => {
  return <StyledGroup {...props}>{children}</StyledGroup>;
};

const StyledItem = styled("a", {
  all: "unset",
  alignItems: "center",
  boxSizing: "border-box",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  gap: "0.5rem",
  justifyContent: "space-between",
  textDecoration: "none",
  transition: "$transitionBase",
  width: "100%",

  "&:hover": {
    color: "$slate12",
    backgroundColor: "$slate2",
  },

  variants: {
    kind: {
      default: {
        color: "$slate11",
        fontSize: "0.875rem",
        padding: "0.75rem 1.5rem",
      },
      period: {
        color: "$slate11",
        fontSize: "0.875rem",
        padding: "0.75rem 1rem",
      },
      danger: {
        color: "$crimson10",
        fontSize: "1rem",
        padding: "1rem 1.5rem",
      },
      tableColumn: {
        padding: "0.25rem 1rem",
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
});

export const PopoverItem = ({ children, ...props }) => {
  return (
    <li>
      <StyledItem {...props}>{children}</StyledItem>
    </li>
  );
};

// Exports
export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = StyledTrigger;
export const PopoverContent = Content;
export const PopoverClose = StyledClose;
export const PopoverTitle = StyledTitle;
export const PopoverSubtitle = StyledSubtitle;
