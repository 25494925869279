import React, { useState, useReducer } from "react";
import { styled } from "../stitches.config";
import { csrf } from "../../utils/csrf.js";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import InputText from "../inputText";
import InputCheckbox from "../inputCheckbox";
import InputHelpText from "../inputHelpText";
import InputError from "../inputError";
import Button from "../button";

import AvatarUpload from "./avatarUpload";

export default function EditAccountInformation({ user }) {
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);

  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState(user.current_password);

  return (
    <div>
      <Title>Account Information</Title>
      <p>Edit your account information here. </p>

      <hr />

      <Form
        action="/settings/account"
        method="post"
        acceptCharset="UTF-8"
        data-turbo="false"
        encType="multipart/form-data"
      >
        <input name="_method" type="hidden" value="patch" />

        <input
          type="hidden"
          name="authenticity_token"
          value={csrf()}
          autoComplete="off"
        />

        <InputField>
          <InputLabel htmlFor="user_email">Email Address *</InputLabel>
          <InputText
            aria-label="user_email"
            name="user[email]"
            id="user_email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="off"
          />
        </InputField>

        <InputField>
          <InputLabel htmlFor="user_first_name">First Name</InputLabel>
          <InputText
            aria-label="user_first_name"
            name="user[first_name]"
            id="user_first_name"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="off"
          />
        </InputField>

        <InputField>
          <InputLabel htmlFor="user_last_name">Last Name</InputLabel>
          <InputText
            aria-label="user_last_name"
            name="user[last_name]"
            id="user_last_name"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoFocus
            autoComplete="off"
          />
        </InputField>

        <AvatarUpload user={user} />

        <InputField>
          <InputLabel htmlFor="user_password">Password</InputLabel>
          <InputText
            aria-label="user_password"
            name="user[password]"
            id="user_password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
          />
          <InputHelpText>
            Leave this blank if you do not want to change your password.
          </InputHelpText>
        </InputField>

        {password.length > 0 ? (
          <Confirmation>
            <div>
              <Subtitle>Confirm changes</Subtitle>

              <InputHelpText>
                In order to keep this account secure, we require that you
                confirm your current password to make changes to your password.
              </InputHelpText>
            </div>

            <InputField>
              <InputLabel htmlFor="user_current_password">
                Current Password
              </InputLabel>
              <InputText
                aria-label="user_current_password"
                name="user[current_password]"
                id="user_current_password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="off"
              />
            </InputField>

            <Button type="submit">Update Account</Button>
          </Confirmation>
        ) : (
          <Button type="submit">Update Account</Button>
        )}
      </Form>
    </div>
  );
}

const Title = styled("h2", {
  color: "$slate12",
  marginBottom: "0.75rem",
  fontSize: "1.5rem",
});

const Paragraph = styled("p", {
  color: "$slate11",
});

const Subtitle = styled("h3", {
  color: "$slate12",
  marginBottom: "0.5rem",
  fontSize: "1rem",
});

const Form = styled("form", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "1.25rem",

  "> button": {
    marginTop: "1rem",
  },
});

const Confirmation = styled("div", {
  background: "$slate1",
  border: "1px solid $slate3",
  padding: "1.5rem",
  margin: "1rem -1.5rem 0",
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});
