import React from "react"

function IconChartLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M10.02 17.5l-5.166-5L2.5 14.188v-1.542l2.48-1.771 5.103 4.958 3.584-2.854H17.5v1.25h-3.417L10.021 17.5zm0-8.042L6.376 5.812 2.5 8.626V7.083l4-2.916 3.646 3.646L17.5 2.5v1.542l-7.48 5.416z"
      ></path>
    </svg>
  )
}

export default IconChartLine
