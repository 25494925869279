import React, { useState } from "react";

import OverviewTabs from "./overviewTabs";
import OverviewChart from "./overviewChart";

import { styled } from "../stitches.config";

export default function Overview() {
  const [selected, setSelected] = useState(["users"]);

  return (
    <Wrapper>
      <OverviewTabs selected={selected} setSelected={setSelected} />
      <OverviewChart selected={selected} />
    </Wrapper>
  );
}

const Wrapper = styled("div", {
  "@xl": { gridColumn: "1 / span 2" },
});
