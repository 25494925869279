import React, { useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

import { styled } from "../stitches.config";

export default function ControlsSearch({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Search>
      <SearchIcon />

      <SearchInputText
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Search>
  );
}

const SearchIcon = styled(MagnifyingGlassIcon, {
  position: "absolute",
  top: "50%",
  left: "0",
  transform: "translateY(-50%)",
  color: "$slate11",
  pointerEvents: "none",
  width: "1rem",
  height: "1rem",
});

const SearchInputText = styled("input", {
  background: "white",
  border: "1px solid transparent",
  borderRadius: "0px",
  color: "$slate12",
  fontSize: "0.875rem",
  minWidth: "10rem",
  width: "100%",
  height: "2rem",
  padding: "0.25rem 0.5rem 0.25rem 1.25rem",

  "&:focus-visible": {
    outline: "none",
  },

  variants: {
    error: {
      true: {
        borderColor: "$crimson10",
        boxShadow: "$inputError",
      },
    },
  },
});

const Search = styled("div", {
  alignItems: "center",
  display: "flex",
  position: "relative",
  width: "100%",

  "&:hover": {
    [`${SearchIcon}`]: {
      color: "$slate12",
    },
  },

  "&:focus-within": {
    [`${SearchIcon}`]: {
      color: "$slate12",
    },
  },
});
