import React from "react";

import { styled } from "./stitches.config";

export const Flex = styled("div", {
  display: "flex",

  variants: {
    direction: {
      column: {
        flexDirection: "column",
      },
      row: {
        flexDirection: "row",
      },
      rowReverse: {
        flexDirection: "row-reverse",
      },
      columnReverse: {
        flexDirection: "column-reverse",
      },
    },
    justify: {
      start: {
        justifyContent: "flex-start",
      },
      end: {
        justifyContent: "flex-end",
      },
      center: {
        justifyContent: "center",
      },
      between: {
        justifyContent: "space-between",
      },
      around: {
        justifyContent: "space-around",
      },
    },
    align: {
      start: {
        alignItems: "flex-start",
      },
      end: {
        alignItems: "flex-end",
      },
      center: {
        alignItems: "center",
      },
      stretch: {
        alignItems: "stretch",
      },
    },
    wrap: {
      wrap: {
        flexWrap: "wrap",
      },
      nowrap: {
        flexWrap: "nowrap",
      },
    },
    gap: {
      none: {
        gap: "0",
      },
      "05": {
        gap: "0.125rem",
      },
      1: {
        gap: "$1",
      },
      2: {
        gap: "$2",
      },
      xxs: {
        gap: "$3",
      },
      xs: {
        gap: "$5",

        "@md": {
          gap: "$7",
        },

        "@lg": {
          gap: "$10",
        },
      },
      sm: {
        gap: "$12",
      },
      md: {
        gap: "$8",

        "@md": {
          gap: "$13",
        },
        "@lg": {
          gap: "$15",
        },
        "@xl": {
          gap: "$17",
        },
      },
      lg: {
        gap: "$18",
      },
    },
    width: {
      full: {
        width: "100%",
      },
      auto: {
        width: "auto",
      },
    },
    spacing: {
      none: {
        marginBottom: "0",
      },
      xxs: {
        marginBottom: "$3",
      },
      xs: {
        marginBottom: "$5",
      },
      sm: {
        marginBottom: "$7",
      },
      md: {
        marginBottom: "$8",
      },
      lg: {
        marginBottom: "$10",
      },
      xl: {
        marginBottom: "$12",
      },
    },
  },

  defaultVariants: {
    direction: "row",
    justify: "start",
    align: "start",
    wrap: "nowrap",
    gap: "md",
  },
});

export const Column = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "100%",

  variants: {
    col: {
      auto: {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: "100%",
      },
      1: { flex: "1 1 auto", maxWidth: "8.33333333%" },
      2: { flex: "1 1 auto", maxWidth: "16.66666667%" },
      3: { flex: "1 1 auto", maxWidth: "25%" },
      4: { flex: "1 1 auto", maxWidth: "33.33333333%" },
      5: { flex: "1 1 auto", maxWidth: "41.66666667%" },
      6: { flex: "1 1 auto", maxWidth: "50%" },
      7: { flex: "1 1 auto", maxWidth: "58.33333333%" },
      8: { flex: "1 1 auto", maxWidth: "66.66666667%" },
      9: { flex: "1 1 auto", maxWidth: "75%" },
      10: { flex: "1 1 auto", maxWidth: "83.33333333%" },
      11: { flex: "1 1 auto", maxWidth: "91.66666667%%" },
      12: { flex: "1 1 auto", maxWidth: "100%" },
    },
  },

  defaultVariants: {
    col: "auto",
  },
});
