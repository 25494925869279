import { parseISO, format, subDays, getYear } from "date-fns";

import {
  yesterday,
  endOfLastWeek,
  endOfThisWeek,
  endOfLastMonth,
  endOfThisMonth,
} from "../helpers/dates";

export function getPeriodOptions(latestImportDate) {
  return [
    {
      label: "Last Week",
      date: endOfLastWeek(latestImportDate).format("YYYY-MM-DD"),
      interval: "day",
      intervalCount: 7,
    },
    {
      label: "Past 7 Days",
      date: yesterday(latestImportDate).format("YYYY-MM-DD"),
      interval: "day",
      intervalCount: 7,
    },
    {
      label: "Past 28 Days",
      date: yesterday(latestImportDate).format("YYYY-MM-DD"),
      interval: "day",
      intervalCount: 28,
    },
    {
      label: "Past 90 Days",
      date: yesterday(latestImportDate).format("YYYY-MM-DD"),
      interval: "day",
      intervalCount: 90,
    },
    {
      label: "Last Month",
      date: endOfLastMonth(latestImportDate).format("YYYY-MM-DD"),
      interval: "month",
      intervalCount: 1,
    },
    {
      label: "Week to Date",
      date: endOfThisWeek(latestImportDate).format("YYYY-MM-DD"),
      interval: "week",
      intervalCount: 1,
    },
    {
      label: "Month to Date",
      date: endOfThisMonth(latestImportDate).format("YYYY-MM-DD"),
      interval: "month",
      intervalCount: 1,
    },
  ];
}

export function getPeriodRange(date, intervalCount) {
  const parsedDate = parseISO(date);
  const startDate = subDays(parsedDate, intervalCount - 1);
  const startYear = getYear(startDate);
  const endYear = getYear(parsedDate);
  const startFormat = startYear !== endYear ? "MMM d y" : "MMM d";

  const start = format(startDate, startFormat);
  const end = format(parsedDate, "MMM d y");

  const range = `${start} - ${end}`;

  return range;
}
