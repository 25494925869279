import React from "react";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { client } from "../../utils/client";

import Avatar from "../avatar";
import Button from "../button";
import { Select, SelectItem } from "../select";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  PopoverGroup,
  PopoverItem,
} from "../popover";

import { Card, Content, Title, Info, Actions, Action } from "./card";
import { styled } from "../stitches.config";

export default function MembersInvitationCard(props) {
  const { org, invitation, name, email, role, currentUserRole } = props;

  const resendInvitation = () => {
    client
      .post(`/orgs/${org.slug}/invitations/${invitation.id}/resend`)
      .then((response) => {
        Turbo.visit(`/settings/${org.slug}/members`);
      })
      .catch((error) => {
        console.log({ error });
        // TODO put up some flash message ?
      });
  };

  const revokeInvitation = () => {
    client
      .delete(`/orgs/${org.slug}/invitations/${invitation.id}`)
      .then((response) => {
        Turbo.visit(`/settings/${org.slug}/members`);
      })
      .catch((error) => {
        console.log({ error });
        // TODO put up some flash message ?
      });
  };

  return (
    <Card>
      <Content>
        <Avatar user={props} />
        <div>
          <Title>{name}</Title>
          <Row>
            <Info>{email}</Info>
            <Spacer> - </Spacer>
            <Role>{role}</Role>
          </Row>
        </div>
      </Content>

      {currentUserRole !== "member" && (
        <Actions>
          <Button onClick={resendInvitation} kind="secondary">
            Resend Invitation
          </Button>

          <Popover>
            <PopoverTrigger>
              <Action>
                <DotsVerticalIcon width={18} height={18} />
              </Action>
            </PopoverTrigger>

            <PopoverContent align="end" side="top">
              <PopoverGroup>
                <PopoverItem onClick={revokeInvitation} kind="danger">
                  Revoke Invitation
                </PopoverItem>
              </PopoverGroup>
            </PopoverContent>
          </Popover>
        </Actions>
      )}
    </Card>
  );
}

const Role = styled("div", {
  color: "$slate11",
  fontWeight: "400",
  fontSize: "0.875rem",
  textTransform: "capitalize",
});

const Row = styled("div", {
  display: "flex",
  alignItems: "center",
});

const Spacer = styled("div", {
  marginLeft: "0.25rem",
  marginRight: "0.25rem",
  color: "$slate11",
  fontWeight: "400",
  fontSize: "0.875rem",
});
