import React, { useState } from "react"

import { csrf } from "../../utils/csrf.js"
import Layout from "../layout"
import { styled } from "../stitches.config"
import InputCheckbox from "../inputCheckbox"
import InputLabel from "../inputLabel"
import InputField from "../inputField"
import InputText from "../inputText"
import InputError from "../inputError"
import Button from "../button"

export default function MembersInvitationAccept({ org, invitation, user }) {
  return (
    <Layout type="auth">
      <Wrapper>
        <Container>
          <Logo>onverre</Logo>

          <Content>
            <Paragraph>Join your organization</Paragraph>
            <Title>{org.name}</Title>

            <Form id="new_session" action={`/invite/${invitation.token}`} acceptCharset="UTF-8" method="post" data-turbo="false">
              <input type="hidden" name="authenticity_token" value={csrf()} autoComplete="off" />
              <input type="hidden" id="user_org_name" name="user[org_name]" value={org.name} autoComplete="off" />

              <InputField>
                <InputLabel htmlFor="user_email">Email</InputLabel>
                <InputText aria-label="Email address" id="user_email" name="user[email]" type="email" value={invitation.email} autoComplete="on" disabled />
              </InputField>

              <InputField>
                <InputLabel htmlFor="user_password">Password</InputLabel>
                <InputText aria-label="Password" id="user_password" name="user[password]" type="password" autoComplete="current-password" required />
                {user?.errors?.password && <InputError error={user?.errors?.password} />}
              </InputField>


              <InputField layout="row">
                <InputCheckbox id="user_terms" name="user[terms]" errors={user?.errors?.terms} />
                <InputLabel htmlFor="user_terms">Agree to terms of service</InputLabel>
                {user?.errors?.terms && <InputError error={user?.errors?.terms} />}
              </InputField>

              <Button type="submit">Submit</Button>
            </Form>
          </Content>

          <Action>
            Don't want to join this organization? <a href="/">Cancel</a>
          </Action>
        </Container>
        <Spotlight>

        </Spotlight>
      </Wrapper>
    </Layout>

  )
}

const Wrapper = styled("div", {
  background: "$slate2",
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "100%",
  height: "100vh",

  "@lg": {
    gridTemplateColumns: "1fr 1fr",
  },

  "@xl": {
    gridTemplateColumns: "1fr 2fr",
  }
})

const Container = styled("div", {
  background: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "4rem",
  justifyContent: "space-between",
  padding: "2.5rem 1rem",

  "@lg": {
    padding: "2.5rem",
  },

  "@xl": {
    padding: "2.5rem 5rem",
  }
})

const Logo = styled("h1", {
  fontSize: "1.5rem",
  fontWeight: "700",
})

const Content = styled("div", {
  width: "100%",
})

const Title = styled("h2", {
  color: "$slate12",
  fontSize: "1.5rem",
  marginBottom: "0.75rem",
})

const Paragraph = styled("p", {
  color: "$slate11",
  fontSize: "1rem",
})

const Form = styled("form", {
  marginTop: "2.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",

  "> button": {
    marginTop: "1rem",
  }
})

const Action = styled("p", {
  color: "$slate12",
  textAlign: "center",
  fontSize: "0.875rem",
  alignSelf: "center",

  "> a": {
    color: "$blue11",
    transition: "$transitionBase",

    "&:hover": {
      color: "$blue12",
    }
  }
})

const Spotlight = styled("div", {
  background: "radial-gradient(35.75% 38.74% at 25.34% 64.25%, rgba(225, 89, 89, 0.20) 0%, rgba(246, 249, 249, 0) 100%), radial-gradient(33.1% 33.1% at 17.33% 32.8%, rgba(225, 154, 89, 0.15) 0%, rgba(246, 249, 249, 0) 100%), radial-gradient(46.4% 46.4% at 39.83% 53.6%, rgba(140, 89, 225, 0.25) 0%, rgba(246, 249, 249, 0) 100%), radial-gradient(40.45% 40.45% at 62.16% 72.2%, #C5DEEE 0%, #F6F9F9 100%)",
  width: "100%",
  height: "100%",
})
