import * as TabsPrimitive from "@radix-ui/react-tabs";
import { styled } from "./stitches.config";

export const Tabs = styled(TabsPrimitive.Root, {});

export const TabsList = styled(TabsPrimitive.List, {
  display: "flex",
  background: "$slate1",
  borderBottom: "1px solid $slate4",
  padding: "0 1rem",

  "@xxl": {
    padding: "0 1.25rem",
  },
});

export const TabsTrigger = styled(TabsPrimitive.Trigger, {
  background: "none",
  border: "none",
  borderLeft: "1px solid transparent",
  borderRight: "1px solid transparent",
  position: "relative",
  color: "$slate10",
  cursor: "pointer",
  fontSize: "0.875rem",
  padding: "0.75rem 1rem",
  transition: "$transitionShort",

  "&:after": {
    content: "''",
    position: "absolute",
    bottom: "-1px",
    left: 0,
    width: "100%",
  },

  "@xl": {
    padding: "1rem 0.5rem",
  },
  "@xxl": {
    padding: "1rem ",
  },

  "&:hover": {
    background: "$slate1",
    color: "$slate12",
  },

  "&[data-state=active]": {
    background: "white",
    color: "$slate12",
    borderLeft: "1px solid $slate4",
    borderRight: "1px solid $slate4",
    "&:after": {
      height: "2px",
      background: "white",
    },
  },
});

export const TabsContent = styled(TabsPrimitive.Content, {});
