import React, { useEffect, useState, useRef } from "react";
import { ExitIcon } from "@radix-ui/react-icons";
import { useAtom } from "jotai";

import { styled } from "../stitches.config";
import Avatar from "../avatar";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverGroup,
  PopoverItem,
} from "../popover";
import { userAtom, orgAtom } from "../helpers/atoms";

export default function ProfilePopover() {
  const [user] = useAtom(userAtom);
  const [org] = useAtom(orgAtom);
  const [isOpen, setIsOpen] = useState(false);

  const triggerRef = useRef(null);
  const contentRef = useRef(null);

  function handleClickOutside(event) {
    if (
      contentRef.current &&
      !contentRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (user === undefined || org === undefined) {
    return null;
  }

  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        <AvatarWrapper onClick={() => setIsOpen(!isOpen)} ref={triggerRef}>
          <Avatar type="nav" user={user} hoverable />
        </AvatarWrapper>
      </PopoverTrigger>

      <PopoverContent align="end" ref={contentRef}>
        <PopoverGroup>
          <Name>{user.name}</Name>
          <Email>{user.email}</Email>
          <PopoverItem onClick={() => setIsOpen(!isOpen)} href="/settings/account">Account Settings</PopoverItem>
          <PopoverItem onClick={() => setIsOpen(!isOpen)} href={`/settings/${org.slug}/organization`}>
            Organization
          </PopoverItem>
          <PopoverItem onClick={() => setIsOpen(!isOpen)} href={`/settings/${org.slug}/warehouse-connection`}>
            Warehouse Connections
          </PopoverItem>
          <PopoverItem onClick={() => setIsOpen(!isOpen)} href={`/settings/${org.slug}/members`}>
            Members
          </PopoverItem>
          {false && (
            <PopoverItem
              onClick={() => setIsOpen(!isOpen)}
              href={`/settings/${org.slug}/members`}
              className="hidden"
            >
              Billing
            </PopoverItem>
          )}
        </PopoverGroup>

        <PopoverGroup>
          <PopoverItem onClick={() => setIsOpen(!isOpen)} href="/logout">
            Logout
            <ExitIcon />
          </PopoverItem>
        </PopoverGroup>
      </PopoverContent>
    </Popover>
  );
}

const AvatarWrapper = styled("div", {
  alignItems: "center",
  width: "4rem",
  height: "100%",
  borderLeft: "1px solid $slate4",
  display: "flex",
  justifyContent: "center",
});

const Name = styled("div", {
  color: "$slate12",
  fontSize: "1rem",
  padding: "1.5rem 1.5rem 0.25rem 1.5rem",
});

const Email = styled("div", {
  color: "$slate10",
  fontSize: "0.75rem",
  padding: "0 1.5rem 0.5rem 1.5rem",
});
