import React from "react"

function IconChartBar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M3.875 16.125V8.042H6v8.083H3.875zm5.063 0V3.875h2.124v12.25H8.938zm5.062 0v-4.75h2.125v4.75H14z"
      ></path>
    </svg>
  )
}

export default IconChartBar
