import React, { useEffect, useState } from "react";
import { InfoCircledIcon, PinRightIcon } from "@radix-ui/react-icons";
import { useAtom } from "jotai";
import { set } from "date-fns";
import * as ToggleGroup from "@radix-ui/react-toggle-group";

import { styled } from "../stitches.config";
import { Flex } from "../flex";
import { Text } from "../text";

import { SwitchRoot, SwitchThumb } from "../switch";
import DayPicker from "../dayPicker";

import { getPeriodOptions } from "./periodHelpers";
import {
  periodAtom,
  periodCompareAtom,
  latestImportDateAtom,
  compareAtom,
} from "../helpers/atoms";
import { getPeriodValue, previousPeriodEnd } from "../helpers/dates";
import { useMediaQuery } from "../helpers/useMediaQuery";
import { is } from "date-fns/locale";

export default function PeriodPanelContent({
  currentPeriod,
  setCurrentPeriod,
  handleToggle,
}) {
  const [period, setPeriod] = useAtom(periodAtom);
  const [periodCompare, setPeriodCompare] = useAtom(periodCompareAtom);
  const [latestImportDate, setLatestImportDate] = useAtom(latestImportDateAtom);

  const isXl = useMediaQuery("(min-width: 1280px)");
  const [compare, setCompare] = useAtom(compareAtom);
  const periodOptions = getPeriodOptions(latestImportDate);

  function handleQuickPeriod(label) {
    const period = periodOptions.find(
      (periodOption) => periodOption.label === label
    );
    if (period) {
      // We need these things to update atomically so re-renders
      // don't happen all the time. Also moving this to a function
      // causes uneccesary re-renders
      const compareValue = previousPeriodEnd(
        period.date,
        period.intervalCount,
        period.interval
      ).format("YYYY-MM-DD");

      setPeriodCompare({
        active: compare,
        date: compareValue,
        interval: period.interval,
        intervalCount: period.intervalCount,
      });
      setPeriod(period);
    }
    label.length !== 0 && setCurrentPeriod(label);

    if (label !== "Custom Range" && !isXl) {
      handleToggle();
    }
  }

  useEffect(() => {
    const compareValue = previousPeriodEnd(
      period.date,
      period.intervalCount,
      period.interval
    ).format("YYYY-MM-DD");

    setPeriodCompare({
      active: compare,
      date: compareValue,
      interval: period.interval,
      intervalCount: period.intervalCount,
    });
  }, [compare, period.date, period.intervalCount, period.inveral]);

  useEffect(() => {
    const initialPeriod = periodOptions.find(
      (periodOption) =>
        periodOption.date === period.date &&
        periodOption.interval === period.interval &&
        periodOption.intervalCount === period.intervalCount
    );

    if (initialPeriod) {
      setCurrentPeriod(initialPeriod.label);
    } else {
      setCurrentPeriod("Custom Range");
    }
  }, []);

  return (
    <Content>
      <Header>
        <Text>Period Selector</Text>

        <CloseToggle onClick={() => handleToggle()}>
          <PinRightIcon />
        </CloseToggle>
      </Header>

      <Section>
        <Text kind="name">Quick Periods</Text>

        <QuickPeriods
          type="single"
          value={currentPeriod}
          onValueChange={handleQuickPeriod}
        >
          {periodOptions.map((periodOption, index) => (
            <QuickPeriodItem key={index} value={periodOption.label}>
              {periodOption.label}
            </QuickPeriodItem>
          ))}

          <QuickPeriodItem value="Custom Range">Custom</QuickPeriodItem>
        </QuickPeriods>
      </Section>

      <Section>
        <Flex gap="2" align="center">
          <SwitchRoot checked={compare} onCheckedChange={setCompare}>
            <SwitchThumb />
          </SwitchRoot>

          <Text kind="name">Compare Previous Period</Text>
        </Flex>
      </Section>

      {currentPeriod === "Custom Range" && (
        <React.Fragment>
          <Notice>
            <InfoCircledIcon />
            <Text css={{ fontSize: "0.75rem" }}>
              Custom ranges use sampled data.
            </Text>
          </Notice>

          <Section>
            <Text kind="name">Custom Period</Text>

            <DayPicker handleToggle={handleToggle} />
          </Section>
        </React.Fragment>
      )}
    </Content>
  );
}

const Content = styled("div", {
  height: "calc(100vh - 4rem)",
  overflowY: "scroll",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid $slate3",
  paddingLeft: "1rem",
});

const CloseToggle = styled("button", {
  all: "unset",
  background: "white",
  borderLeft: "1px solid $slate3",
  cursor: "pointer",
  height: "63px",
  width: "63px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&:hover": {
    background: "$slate1",
  },
});

const Section = styled("section", {
  borderBottom: "1px solid $slate3",
  padding: "1rem",

  "&:last-of-type": {
    borderBottom: "none",
    paddingBottom: "7.5rem",
  },
});

const QuickPeriods = styled(ToggleGroup.Root, {
  listStyle: "none",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "0.5rem",
  paddingTop: "0.75rem",

  "@sm": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
});

const QuickPeriodItem = styled(ToggleGroup.Item, {
  background: "white",
  border: "1px solid $slate5",
  color: "$slate11",
  fontSize: "0.75rem",
  textAlign: "center",
  padding: "0.5rem",
  cursor: "pointer",
  transition: "$transitionShort",

  "&:hover": {
    borderColor: "$slate10",
    color: "$slate12",
  },

  "&[data-state=on]": {
    backgroundColor: "$slate12",
    color: "white",
  },
});

const Notice = styled("div", {
  background: "$amber2",
  width: "100%",
  padding: "0.25rem 1rem",
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  justifyContent: "center",
  color: "$amber12",

  "& > svg": {
    width: "0.75rem",
    height: "0.75rem",
  },
});
