import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
import { useAtom } from "jotai";
import localforage from "localforage";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { blue, crimson, amber, purple, slate } from "@radix-ui/colors";

import {
  Select,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectItem,
} from "../select";
import { Skeleton } from "../skeleton";
import { styled } from "../stitches.config";
import { TooltipBar, TooltipLine } from "../charts/tooltips";
import {
  latestImportDateAtom,
  periodAtom,
  periodCompareAtom,
} from "../helpers/atoms";
import {
  DashedChartLine,
  barChartFills,
  chartColors,
  chartFills,
  chartGradients,
  combineDatesBarChart,
  combineDatesLineChart,
  getChartData,
} from "../helpers/chart";
import { formatNumber } from "../helpers/formatters";
import { periodStart, yesterday } from "../helpers/dates";

import IconChartBar from "../icons/iconChartBar";
import IconChartLine from "../icons/iconChartLine";
import IconChartLinear from "../icons/iconChartLinear";
import IconChartRolling from "../icons/iconChartRolling";

import { client } from "../../utils/client";

export default function OverviewChart({ selected }) {
  const [period, setPeriod] = useAtom(periodAtom);
  const [periodCompare, setPeriodCompare] = useAtom(periodCompareAtom);
  const [latestImportDate, setLatestImportDate] = useAtom(latestImportDateAtom);

  const [currentData, setCurrentData] = useState();
  const [previousData, setPreviousData] = useState();

  const [lineChartData, setLineChartData] = useState();
  const [lineChartDataRolling, setLineChartDataRolling] = useState();

  const [barChartData, setBarChartData] = useState();
  const [barChartDataRolling, setBarChartDataRolling] = useState();

  const [chartToggle, setChartToggle] = useState("line");
  const [rollingData, setRollingData] = useState(false);

  const changeRollingData = (value) => {
    setRollingData(value);
    localforage.setItem("rollingData", value);
  };

  useEffect(() => {
    localforage.getItem("rollingData").then((value) => {
      if (value) {
        changeRollingData(value);
      }
    });
  }, []);

  // DYNAMIC TICK LENGTHS
  function tickLengths() {
    if (period.interval === "day") {
      if (period.intervalCount < 28) {
        return "every day";
      } else if (period.intervalCount < 56) {
        return "every 3 days";
      } else {
        return "every week";
      }
    } else if (period.interval === "week") {
      if (period.intervalCount < 4) {
        return "every day";
      } else if (period.intervalCount < 8) {
        return "every 3 days";
      } else {
        return "every week";
      }
    } else if (period.interval === "month") {
      if (period.intervalCount < 2) {
        return "every day";
      } else if (period.intervalCount < 3) {
        return "every 3 days";
      } else {
        return "every week";
      }
    }
  }

  // CHART THEME
  const theme = {
    textColor: slate.slate6,
    axis: {
      domain: {
        line: {
          stroke: slate.slate6,
        },
      },
      ticks: {
        line: {
          stroke: slate.slate6,
        },
        text: {
          fill: slate.slate10,
          fontFamily: "'Inter', sans-serif",
          fontSize: "12px",
        },
      },
    },
    grid: {
      line: {
        stroke: slate.slate4,
        strokeDasharray: "3, 3",
        strokeWidth: 1,
      },
    },
  };

  // BAR CHART AXIS
  let axisIndex = 0;
  const barChartAxis = (val) => {
    if (axisIndex % 2 === 0) {
      axisIndex++;

      return dayjs(val).format("MMM D");
    } else {
      axisIndex++;

      return "";
    }
  };

  useEffect(() => {
    if (period) {
      const maxChartDate = yesterday(latestImportDate).format("YYYY-MM-DD");

      getChartData(
        period.date,
        period.intervalCount,
        period.interval,
        maxChartDate
      ).then((current) => {
        setCurrentData(current);
      });
    }
  }, [period.date, period.intervalCount, period.inveral]);

  useEffect(() => {
    if (period && periodCompare.active) {
      const maxChartDate = yesterday(latestImportDate).format("YYYY-MM-DD");

      getChartData(
        periodCompare.date,
        period.intervalCount,
        period.interval,
        maxChartDate
      ).then((previous) => {
        setPreviousData(previous);
      });
    }
  }, [period.date, period.intervalCount, period.intervalCount, periodCompare.date, periodCompare.active]);

  useEffect(() => {
    if (periodCompare.active && currentData && previousData) {
      let combinedLineChartData = combineDatesLineChart(
        currentData.lineChartData,
        previousData.lineChartData
      );

      let combinedLineChartDataRolling = combineDatesLineChart(
        currentData.lineChartDataRolling,
        previousData.lineChartDataRolling
      );

      let combinedBarChartData = combineDatesBarChart(
        currentData.barChartData,
        previousData.barChartData
      );

      let combinedBarChartDataRolling = combineDatesBarChart(
        currentData.barChartDataRolling,
        previousData.barChartDataRolling
      );

      setLineChartData(
        combinedLineChartData.filter((line) => {
          return selected.includes(line.id.replace(/Previous$/, ""));
        })
      );

      setLineChartDataRolling(
        combinedLineChartDataRolling.filter((line) => {
          return selected.includes(line.id.replace(/Previous$/, ""));
        })
      );

      setBarChartData(
        combinedBarChartData.map((bar) => {
          return Object.keys(bar).reduce((accumulator, key) => {
            if (
              selected.includes(key.replace(/Previous$/, "")) ||
              key === "x"
            ) {
              accumulator[key] = bar[key];
            }
            return accumulator;
          }, {});
        })
      );
      setBarChartDataRolling(
        combinedBarChartDataRolling.map((bar) => {
          return Object.keys(bar).reduce((accumulator, key) => {
            if (
              selected.includes(key.replace(/Previous$/, "")) ||
              key === "x"
            ) {
              accumulator[key] = bar[key];
            }
            return accumulator;
          }, {});
        })
      );
    } else if (!periodCompare.active && currentData) {
      setLineChartData(
        currentData.lineChartData.filter((line) => {
          return selected.includes(line.id);
        })
      );

      setLineChartDataRolling(
        currentData.lineChartDataRolling.filter((line) => {
          return selected.includes(line.id);
        })
      );

      setBarChartData(
        currentData.barChartData.map((bars) => {
          const filteredBars = { x: bars.x };
          selected.map((s) => {
            filteredBars[s] = bars[s];
          });
          return filteredBars;
        })
      );

      setBarChartDataRolling(
        currentData.barChartDataRolling.map((bars) => {
          const filteredBars = { x: bars.x };
          selected.map((s) => {
            filteredBars[s] = bars[s];
          });
          return filteredBars;
        })
      );
    }
  }, [selected, periodCompare.active, currentData, previousData]);

  return (
    <PrimaryChartWrapper>
      <ChartActionButtonGroup>
        <Select
          check="right"
          size="sm"
          value={rollingData}
          onValueChange={(value) => changeRollingData(value)}
        >
          <SelectGroup>
            <SelectItem size="sm" value={false}>
              <IconChartLinear />
              &nbsp;Linear
            </SelectItem>
            <SelectItem size="sm" value={true}>
              <IconChartRolling />
              &nbsp;Rolling
            </SelectItem>
          </SelectGroup>
        </Select>

        <ChartToggleGroup
          type="single"
          onValueChange={(value) => {
            if (value) setChartToggle(value);
          }}
          defaultValue="line"
        >
          <ChartToggle value="line">
            <IconChartLine />
          </ChartToggle>
          <ChartToggle value="bar">
            <IconChartBar />
          </ChartToggle>
        </ChartToggleGroup>
      </ChartActionButtonGroup>

      <ChartContainer>
        {!lineChartData ? (
          <Skeleton css={{ height: "30rem" }} />
        ) : (
          <>
            {chartToggle === "line" ? (
              <ResponsiveLine
                data={rollingData ? lineChartDataRolling : lineChartData}
                curve="catmullRom"
                theme={theme}
                keys={selected}
                colors={(d) => chartColors[d.id]}
                margin={{ top: 8, right: 56, bottom: 40, left: 24 }}
                yScale={{
                  type: "linear",
                  min: "0",
                  max: "auto",
                  reverse: false,
                  stacked: false,
                }}
                yFormat=" >-.2f"
                xScale={{
                  type: "time",
                  format: "%Y-%m-%d",
                  useUTC: false,
                  precision: "day",
                }}
                xFormat="time:%Y-%m-%d"
                axisRight={{
                  format: function (value) {
                    return formatNumber(value);
                  },
                  orient: "left",
                  tickSize: 8,
                  tickPadding: 8,
                  tickRotation: 0,
                }}
                axisTop={null}
                axisLeft={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 8,
                  tickPadding: 8,
                  tickRotation: 0,
                  format: "%b %d",
                  tickValues: tickLengths(),
                }}
                pointSize={10}
                pointColor="white"
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                enableArea={true}
                areaBaselineValue={0}
                areaBlendMode="normal"
                defs={chartGradients}
                fill={chartFills}
                enableSlices="x"
                layers={[
                  "grid",
                  "markers",
                  "axes",
                  "areas",
                  "crosshair",
                  DashedChartLine,
                  "points",
                  "slices",
                  "mesh",
                  "legends",
                ]}
                sliceTooltip={({ slice }) => {
                  return (
                    <TooltipLine
                      slice={slice}
                      compare={periodCompare.active}
                      interval={period.interval}
                      intervalCount={period.intervalCount}
                    />
                  );
                }}
              />
            ) : (
              <ResponsiveBar
                data={rollingData ? barChartDataRolling : barChartData}
                colors={(d) => chartColors[d.data.id]}
                keys={
                  !periodCompare.active
                    ? selected
                    : selected.flatMap((x) => [x, `${x}Previous`])
                }
                indexBy={(d) => d.x}
                enableLabel={false}
                groupMode="grouped"
                margin={{ top: 8, right: 48, bottom: 40, left: 16 }}
                theme={theme}
                axisTop={null}
                yScale={{
                  type: "linear",
                  min: "0",
                  max: "auto",
                  reverse: false,
                  stacked: false,
                }}
                yFormat=" >-.2f"
                axisLeft={null}
                axisRight={{
                  format: function (value) {
                    return formatNumber(value);
                  },
                  orient: "left",
                  tickSize: 8,
                  tickPadding: 8,
                  tickRotation: 0,
                }}
                xScale={{
                  type: "time",
                  format: "%Y-%m-%d",
                  useUTC: false,
                  precision: "day",
                }}
                xFormat="time:%Y-%m-%d"
                axisBottom={{
                  orient: "bottom",
                  tickSize: 8,
                  tickPadding: 8,
                  tickRotation: 0,
                  format: barChartAxis,
                  tickValues: tickLengths(),
                }}
                defs={chartGradients}
                fill={barChartFills}
                tooltip={({ data }) => {
                  return (
                    <TooltipBar
                      data={data}
                      compare={periodCompare.active}
                      interval={period.interval}
                      intervalCount={period.intervalCount}
                    />
                  );
                }}
              />
            )}
          </>
        )}
      </ChartContainer>
    </PrimaryChartWrapper>
  );
}

const PrimaryChartWrapper = styled("div", {
  alignItems: "flex-end",
  border: "none",
  borderBottom: "1px solid $slate4",
  display: "flex",
  flexDirection: "column",
  padding: "1rem ",

  "@lg": {
    padding: "1.5rem 2.5rem 2.5rem",
  },
});

const ChartContainer = styled("div", {
  height: "30rem",
  width: "99.9%",
  minWidth: "0",
  marginTop: "$4",
});

const ChartToggleGroup = styled(ToggleGroup.Root, {
  display: "flex",
  gap: "0.5rem",
});

const ChartToggle = styled(ToggleGroup.Item, {
  alignItems: "center",
  background: "transparent",
  border: "1px solid $slate4",
  borderRadius: "0px",
  cursor: "pointer",
  display: "flex",
  height: "2rem",
  justifyContent: "center",
  width: "2rem",
  color: "$slate10",

  "&[data-state=on]": {
    border: "1px solid $slate10",
    color: "$slate12",
  },
});

const ChartActionButtonGroup = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});
