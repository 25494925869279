import React from "react"
import { styled } from "./stitches.config"

export default function InputField({ children, layout, ...props }) {
  return (
    <Field layout={layout} {...props}>{children}</Field>
  )
}

const Field = styled("div", {
  display: "flex",
  gap: "0.5rem",
  width: "100%",

  variants: {
    layout: {
      stacked: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      row: {
        flexDirection: "row",
        flexWrap: "wrap",
      },
    },
  },
  defaultVariants: {
    layout: "stacked",
  }
})
