import React, { useEffect, useState, useRef } from "react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { useAtom } from "jotai";

import { styled } from "../stitches.config";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverGroup,
  PopoverItem,
} from "../popover";
import DemoOrganization from "../demoOrganization";

import { client } from "../../utils/client";

export default function DemoOrganizationPopover() {
  const triggerRef = useRef(null);
  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  function handleClickOutside(event) {
    if (
      contentRef.current &&
      !contentRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <Popover open={isOpen}>
      <PopoverTrigger asChild>
        <OrganizationSwitcher
          ref={triggerRef}
          onClick={() => setIsOpen(!isOpen)}
        >
          <DemoOrganization type="trigger" onClick={() => setIsOpen(!isOpen)} />

          <OrganizationSwitcherIcon>
            <CaretSortIcon />
          </OrganizationSwitcherIcon>
        </OrganizationSwitcher>
      </PopoverTrigger>

      <PopoverContent align="start" kind="organization" ref={contentRef}>
        <PopoverGroup>
          <Title>Organizations</Title>

          <PopoverItem
            as="button"
          >
            <DemoOrganization />
          </PopoverItem>
        </PopoverGroup>
      </PopoverContent>
    </Popover>
  );
}

const Title = styled("h6", {
  color: "$slate11",
  fontSize: "0.75rem",
  fontWeight: "400",
  padding: "1rem 1.5rem 0.5rem 1.5rem",
});

const OrganizationSwitcherIcon = styled("div", {
  padding: "0.25rem",
  display: "flex",
  color: "$slate11",
  transition: "$transitionBase",
});

const OrganizationSwitcher = styled("button", {
  all: "unset",
  boxSizing: "border-box",
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: "pointer",
  border: "1px solid transparent!important",
  borderLeft: "1px solid $slate4!important",
  borderRight: "1px solid $slate4!important",
  padding: "0.5rem 1rem",
  height: "100%",

  "@md": {
    minWidth: "17.5rem",
  },

  "&:hover": {
    [`${OrganizationSwitcherIcon}`]: {
      color: "$slate12",
      backgroundColor: "$slate3",
    },
  },
});
