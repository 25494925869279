import React from "react";
import { TriangleRightIcon } from "@radix-ui/react-icons";

import { styled } from "../stitches.config";
import {
  formatNumber,
  formatTime,
  formatPercent,
  calculateDifference,
  calculatePercentageOfTotal,
  calculatePercentageDifference,
  displayDifference,
  displayPercentageChange,
} from "../helpers/formatters";

import { Text } from "../text";
import Indicator from "../indicator";
import { Flex } from "../flex";
import { CurrentPeriod, PreviousPeriod } from "../comparisons/periods";

export default function TableRowCompare({
  row,
  subRow,
  columns,
  columnOrder,
  expandedRows,
  pathRollup,
  togglePathExpand,
}) {
  const expanded = expandedRows.includes(row.path);

  return (
    <StyledTableRow subRow={subRow}>
      {columnOrder.map((columnId) => {
        const column = columns.find((c) => c.id === columnId);

        if (!column.visible) {
          return null;
        }
        let cellValue;
        switch (column.id) {
          case "path":
            cellValue = row.path;
            break;
          case "users":
            cellValue = formatNumber(row.users);
            cellValueCompare = formatNumber(row.previous?.users);
            cellValueDifference = calculateDifference(
              row.previous?.users,
              row.users
            );
            cellPercentage = calculatePercentageOfTotal(
              row.users,
              column.total
            );
            cellPercentageCompare = calculatePercentageOfTotal(
              row.previous?.users,
              column.totalCompare
            );
            cellPercentageChange = calculatePercentageDifference(
              row.previous?.users,
              row.users
            );
            break;
          case "views":
            cellValue = formatNumber(row.views);
            cellValueCompare = formatNumber(row.previous?.views);
            cellValueDifference = calculateDifference(
              row.previous?.views,
              row.views
            );
            cellPercentage = calculatePercentageOfTotal(
              row.views,
              column.total
            );
            cellPercentageCompare = calculatePercentageOfTotal(
              row.previous?.views,
              column.totalCompare
            );
            cellPercentageChange = calculatePercentageDifference(
              row.previous?.views,
              row.views
            );
            break;
          case "average_seconds_on_page":
            cellValue = formatTime(row.average_seconds_on_page);
            cellValueCompare = formatTime(
              row.previous?.average_seconds_on_page
            );
            cellValueDifference = "";
            cellPercentage = null;
            cellPercentageCompare = null;
            cellPercentageChange = calculatePercentageDifference(
              row.previous?.average_seconds_on_page,
              row.average_seconds_on_page
            );
            break;
          case "entrances":
            cellValue = formatNumber(row.entrances);
            cellValueCompare = formatNumber(row.previous?.entrances);
            cellValueDifference = calculateDifference(
              row.previous?.entrances,
              row.entrances
            );
            cellPercentage = calculatePercentageOfTotal(
              row.entrances,
              column.total
            );
            cellPercentageCompare = calculatePercentageOfTotal(
              row.previous?.entrances,
              column.totalCompare
            );
            cellPercentageChange = calculatePercentageDifference(
              row.previous?.entrances,
              row.entrances
            );
            break;
          case "exits":
            cellValue = formatNumber(row.exits);
            cellValueCompare = formatNumber(row.previous?.exits);
            cellValueDifference = calculateDifference(
              row.previous?.exits,
              row.exits
            );
            cellPercentage = calculatePercentageOfTotal(
              row.exits,
              column.total
            );
            cellPercentageCompare = calculatePercentageOfTotal(
              row.previous?.exits,
              column.totalCompare
            );
            cellPercentageChange = calculatePercentageDifference(
              row.previous?.exits,
              row.exits
            );
            break;
          case "bounce_rate":
            cellValue = formatPercent(row.bounce_rate);
            cellValueCompare = formatPercent(row.previous?.bounce_rate);
            cellValueDifference = "";
            cellPercentage = null;
            cellPercentageCompare = null;
            cellPercentageChange = calculatePercentageDifference(
              row.previous?.bounce_rate,
              row.bounce_rate
            );
            break;
          default:
            cellValue = "";
            cellValueCompare = "";
        }

        if (column.id === "path") {
          return (
            <TableData
              key={column.id}
              kind={column.id === "path" ? "path" : undefined}
            >
              <Cell>
                {row.sub_path_count > 1 ? (
                  <CellRollUp
                    expanded={expanded}
                    onClick={() => togglePathExpand(row.path)}
                  >
                    <TriangleRightIcon />
                  </CellRollUp>
                ) : (
                  <CellRollUpIndent
                    state={
                      pathRollup ? "rolledUp" : subRow ? "childRow" : "base"
                    }
                  />
                )}
                <Text kind="name">{cellValue}</Text>

                {row.sub_path_count > 1 && (
                  <CellRollUpPaths>
                    {formatNumber(row.sub_path_count)} subpaths
                  </CellRollUpPaths>
                )}
              </Cell>

              <Cell>
                <CellRollUpIndent
                  state={pathRollup ? "rolledUp" : subRow ? "childRow" : "base"}
                />
                <CurrentPeriod />
              </Cell>
              <Cell>
                <CellRollUpIndent
                  state={pathRollup ? "rolledUp" : subRow ? "childRow" : "base"}
                />
                <PreviousPeriod />
              </Cell>
            </TableData>
          );
        } else {
          return (
            <TableData
              key={column.id}
              kind={column.id === "path" ? "path" : undefined}
            >
              <Cell>
                {displayDifference(cellValueDifference)}
                {displayPercentageChange(cellPercentageChange)}
              </Cell>

              <Cell>
                <Text kind="count">{cellValue}</Text>

                {cellPercentage && (
                  <Text kind="percent">({cellPercentage})</Text>
                )}
              </Cell>

              <Cell>
                <Text kind="count">{cellValueCompare}</Text>

                {cellPercentageCompare && (
                  <Text kind="percent">({cellPercentageCompare})</Text>
                )}
              </Cell>
            </TableData>
          );
        }
      })}
    </StyledTableRow>
  );
}

export const StyledTableRow = styled("tr", {
  display: "flex",
  position: "relative",

  "&:before, &:after": {
    content: "''",
    display: "block",
    width: "100%",
    height: "1px",
    backgroundColor: "$slate7",
    position: "absolute",
    left: "0",
    zIndex: "1",
    opacity: "0",
  },

  "&:before": {
    top: "-1px",
  },

  "&:after": {
    bottom: "0",
  },

  variants: {
    subRow: {
      true: {
        background: "$slate1",
      },
      false: {
        background: "$white",
      },
    },
    kind: {
      header: {},
      body: {
        "&:hover": {
          "&:before, &:after": {
            opacity: "1",
          },
        },
      },
    },
  },

  defaultVariants: {
    kind: "body",
    state: "collapsed",
  },
});

const tableCellStyles = {
  textAlign: "right",
  fontWeight: "400",
  fontFamily: "$sans",
  position: "relative",
  fontSize: "0.875rem",
  border: "1px solid $slate3",
  borderWidth: "0 1px 1px 0",
  borderCollapse: "collapse",
  transition: "$transitionShort",

  "&:first-of-type": {
    borderWidth: "0 0 1px 0 ",
  },
  "&:last-of-type": {
    borderWidth: "0 0 1px 0",
  },

  variants: {
    kind: {
      default: {
        flex: "1 0 180px",
        minWidth: "180px",
      },
      path: {
        flex: "1 1 100%",
        minWidth: "240px",
        width: "100%",
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
};

const Cell = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "0.5rem",
});

const TableData = styled("td", {
  ...tableCellStyles,
  padding: "0.75rem",
  color: "$slate12",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",

  [`${Cell}`]: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  "&:first-of-type": {
    textAlign: "left",

    [`${Cell}`]: {
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },
});

const CellRollUp = styled("button", {
  appearance: "none",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  lineHeight: 0,
  color: "$slate12",
  transition: "$transitionShort",

  variants: {
    expanded: {
      true: { transform: "rotate(90deg)" },
      false: { transform: "rotate(0deg)" },
    },
  },

  defaultVariants: {
    state: false,
  },
});

const CellRollUpIndent = styled("div", {
  height: "15px",
  width: "15px",
  background: "transparent",

  variants: {
    state: {
      rolledUp: { width: "15px" },
      childRow: { width: "30px" },
      base: { width: "0px" },
    },
  },

  defaultVariants: {
    state: "base",
  },
});

const CellRollUpPaths = styled("div", {
  marginLeft: "auto",
  justifySelf: "flex-end",
  fontSize: "0.75rem",
  color: "$slate10",
});
