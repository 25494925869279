import React from "react";
import dayjs from "dayjs";

import { Text } from "../text";
import { styled } from "../stitches.config";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

const numberFormatter = new Intl.NumberFormat("en-US")

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatTitle(camelCase) {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

export function formatNumber(number) {
  if (number === null || number === undefined) {
    return "—";
  } else {
    // return number.toLocaleString("en-US");
    return numberFormatter.format(number)
  }
}

export function formatTime(number) {
  if (number === null || number === undefined) {
    return "—";
  } else {
    return new Date(number * 1000).toISOString().slice(11, 19);
  }
}

export function formatCleanDate(date) {
  if (date === null || date === undefined) {
    return "—";
  } else {
    return dayjs(date).format("MMM D, YYYY");
  }
}

export function formatReadableDate(date) {
  if (date === null || date === undefined) {
    return "—";
  } else {
    return dayjs(date).format("ddd, MMM D, YYYY");
  }
}

export function formatPercent(number) {
  if (number === null || number === undefined) {
    return "—";
  } else {
    return +(Math.round(number + "e+2") + "e-2") + "%";
  }
}
export function calculateDifference(previousValue, currentValue) {
  const difference = currentValue - previousValue;

  if (previousValue === null || previousValue === undefined) {
    return currentValue;
  } else if (currentValue === null || currentValue === undefined) {
    return previousValue;
  } else if (isNaN(difference)) {
    return "—";
  } else {
    return difference;
  }
}

export function calculatePercentageOfTotal(value = 0, total = 0) {
  if (value === null || value === undefined || value === 0) {
    return "—";
  } else {
    return formatPercent((value / total) * 100);
  }
}

export function calculatePercentageDifference(previousValue, currentValue) {
  if (isNaN(currentValue)) {
    return "—";
  } else if (previousValue === 0 && currentValue === 0) {
    return 0; // No change, as both periods have no data
  } else if (
    previousValue === 0 ||
    (isNaN(previousValue) && currentValue > 0)
  ) {
    return 100; // 100% increase, as previous period has no data
  } else if (currentValue === 0) {
    return -100; // -100% decrease, as current period has no data
  } else {
    return ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
  }
}

export function displayDifference(difference) {
  if (difference === "") {
    return null;
  } else if (difference > 0) {
    return (
      <Text kind="difference" value="positive">
        +{formatNumber(difference)}
      </Text>
    );
  } else if (difference === 0) {
    return (
      <Text kind="difference" value="same">
        {formatNumber(difference)}
      </Text>
    );
  } else if (difference < 0) {
    return (
      <Text kind="difference" value="negative">
        {formatNumber(difference)}
      </Text>
    );
  } else {
    return (
      <Text kind="difference" value="empty">
        —
      </Text>
    );
  }
}

export function displayPercentageChange(percentageChange) {
  if (percentageChange > 0) {
    return (
      <PercentChange>
        <Text kind="percentChange" value="positive">
          +{formatPercent(percentageChange)}
        </Text>
      </PercentChange>
    );
  } else if (percentageChange === 0) {
    return (
      <PercentChange>
        <Text kind="percentChange" value="same">
          0%
        </Text>
      </PercentChange>
    );
  } else if (percentageChange < 0) {
    return (
      <PercentChange>
        <Text kind="percentChange" value="negative">
          {formatPercent(percentageChange)}
        </Text>
      </PercentChange>
    );
  } else {
    return (
      <PercentChange>
        <Text kind="percentChange" value="empty">
          —
        </Text>
      </PercentChange>
    );
  }
}

const PercentChange = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  width: "3.25rem",
});
