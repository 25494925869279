import React from "react";

import { styled } from "../stitches.config";
import { formatNumber, formatPercent } from "../helpers/formatters";
import { Flex } from "../flex";

export default function BarChartRow({ name, count, sum }) {
  const percent = (count / sum) * 100;

  return (
    <Row>
      <Flex justify="between" gap="1">
        <Text kind="name">{name === "" ? "direct" : name}</Text>

        <Flex gap="xxs" align="end">
          <Text kind="count">{formatNumber(count)}</Text>
          <Text kind="percent">({formatPercent(percent)})</Text>
        </Flex>
      </Flex>

      <Amount>
        <AmountValue css={{ width: `${percent}%` }} />
      </Amount>
    </Row>
  );
}

const Row = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  borderBottom: "1px solid $slate4",
  padding: "$2 $5",

  "@lg": {
    padding: "$4 $10",
  },
});

const Text = styled("h4", {
  fontWeight: "400",

  variants: {
    kind: {
      name: {
        color: "$slate12",
        fontSize: "0.875rem",
      },
      count: {
        color: "$slate12",
        fontSize: "0.875rem",
      },
      percent: {
        color: "$slate11",
        fontSize: "0.675rem",
        textAlign: "right",
        width: "3rem",
      },
    },
  },
});

const Amount = styled("div", {
  position: "relative",
  width: "100%",
  height: "0.5rem",
  backgroundColor: "$blue2",
});

const AmountValue = styled("div", {
  position: "absolute",
  bottom: "0",
  left: "0",
  height: "100%",
  backgroundColor: "$blue10",
});
