import React, { useState } from "react";

import { styled } from "../stitches.config";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import Button from "../button";

export default function BigQueryCredentialsUpload({ source }) {
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState(source.credentials_filename);

  function handleUpload(event) {
    setFile(event.target.files[0]);
  }

  function handleClear(event) {
    setFile("")
    setFilename(null)
  }

  return (
    <InputField>
      <InputLabel>Credentials JSON file</InputLabel>

      <CredentialsField>
        <CredentialsFieldContent>
          <CredentialsFieldText>
            {file
              ? file.name
              : (filename ? filename : "Upload a credentials JSON file" )}
          </CredentialsFieldText>
        </CredentialsFieldContent>

        {(!file && !filename) ? (
          <Button type="button" kind="secondary" size="sm" as="label" htmlFor="source_credentials">
            Browse
          </Button>
        ) : (
          <Button type="button" kind="secondary" size="sm" onClick={handleClear}>
            Clear
          </Button>
        )}

        <input
          style={{ display: "none" }}
          onChange={handleUpload}
          type="file"
          name="source[credentials]"
          id="source_credentials"
        />

        { (!filename && source.credentials_filename) &&
          <input name="source[_destroy_credentials]" type="hidden" value="true" />
        }
      </CredentialsField>
    </InputField>
  );
}

const CredentialsField = styled("div", {
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  border: "1px dashed $slate5",
  width: "100%",
  padding: "0.5rem",
  justifyContent: "space-between",
});

const CredentialsFieldContent = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});

const CredentialsFieldText = styled("span", {
  color: "$slate11",
  fontSize: "0.875rem",
  fontWeight: "400",
});

const CredentialsInputLabel = styled("label", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0px",
  cursor: "pointer",
  fontSize: "0.875rem",
  height: "2.75rem",
  padding: "0.5rem 1rem",
  transition: "$transitionBase",
  pointerEvents: "all",
  background: "transparent",
  border: "1px solid $slate8",
  color: "$slate12",

  "&:hover": {
    border: "1px solid $slate10",
  },
});

const Placeholder = styled("img", {
  width: "2rem",
  height: "2rem",
  objectFit: "cover",
  borderRadius: "$round",
});
