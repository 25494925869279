import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { IMaskInput } from "react-imask";
import { format, isValid, parse, isBefore, isAfter, subDays } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { CalendarIcon } from "@radix-ui/react-icons";
import { useAtom } from "jotai";

import Button from "./button";
import {
  FormRoot,
  FormField,
  FormLabel,
  FormMessage,
  FormControl,
  FormValidityState,
  FormSubmit,
} from "./form";
import { periodAtom, latestImportDateAtom } from "./helpers/atoms";
import { styled } from "./stitches.config";

import { useMediaQuery } from "./helpers/useMediaQuery";

export default function PeriodPanel({ handleToggle }) {
  const [latestImportDate] = useAtom(latestImportDateAtom);
  const [period, setPeriod] = useAtom(periodAtom);
  const [dateRange, setDateRange] = useState();
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [activeField, setActiveField] = useState();
  const isXl = useMediaQuery("(min-width: 1280px)");

  const parseLatestDate = parse(latestImportDate, "yyyy-MM-dd", new Date());
  const latestDate = subDays(parseLatestDate, 1);

  const today = new Date();
  const thisYear = today.getFullYear();

  function formatInput(input) {
    let letters = input.slice(0, 3).replace(/[^a-zA-Z]/g, "");
    letters = letters.charAt(0).toUpperCase() + letters.slice(1).toLowerCase();
    const firstNumbers = input.slice(3, 5).replace(/[^0-9]/g, "");
    const lastNumbers = input.slice(5, 9).replace(/[^0-9]/g, "");

    let formatted = letters;
    if (letters.length === 3 && firstNumbers) formatted += " ";
    if (firstNumbers) formatted += `${firstNumbers}`;
    if (firstNumbers.length === 2 && lastNumbers) formatted += " ";
    if (lastNumbers) formatted += `${lastNumbers}`;

    return formatted;
  }

  function handleFromChange(e) {
    const input = e.target.value.replace(/[^a-zA-Z0-9]/g, "");

    if (input.length <= 9) {
      setFromValue(formatInput(input));
      const date = parse(e.target.value, "MMM d y", new Date());
      const toDate = parse(toValue, "MMM d y", new Date());

      if (!isValid(date)) {
        return setDateRange({
          from: undefined,
          to: toDate ? toDate : undefined,
        });
      } else {
        setDateRange({ from: date, to: dateRange?.to });
      }
    }
  }

  function handleToChange(e) {
    const input = e.target.value.replace(/[^a-zA-Z0-9]/g, "");

    if (input.length <= 9) {
      setToValue(formatInput(input));
      const date = parse(e.target.value, "MMM d y", new Date());
      const fromDate = parse(fromValue, "MMM d y", new Date());

      if (!isValid(date)) {
        return setDateRange({
          from: fromDate ? fromDate : undefined,
          to: undefined,
        });
      } else {
        setDateRange({ from: dateRange?.from, to: date });
      }
    }
  }

  function handleRangeSelect(range) {
    setDateRange(range);

    if (!range?.to) {
      setActiveField("to");
    } else if (range?.to && !range?.from) {
      setActiveField("from");
    } else {
      setActiveField();
    }

    if (!range) {
      setFromValue("");
      setToValue("");
    }

    if (range?.from) {
      setFromValue(format(range.from, "MMM d y"));
    } else if (!range?.from) {
      setFromValue("");
    }
    if (range?.to) {
      setToValue(format(range.to, "MMM d y"));
    } else if (!range?.to) {
      setToValue("");
    }
  }

  function handleSave() {
    if (dateRange?.from && dateRange?.to) {
      let endDate = dayjs(dateRange.to);
      let startDate = dayjs(dateRange.from);
      let days = dayjs(endDate).diff(startDate, "day") + 1;
      setPeriod({
        label: "Custom Range",
        date: endDate.format("YYYY-MM-DD"),
        interval: "day",
        intervalCount: days,
      });
    }
    !isXl && handleToggle();
  }

  return (
    <>
      <Wrapper>
        <DateInputs>
          <DateInputIcon>
            <CalendarIcon />
          </DateInputIcon>
          <DateInputField
            value={fromValue}
            onChange={handleFromChange}
            active={activeField === "from"}
          />
          <Separator>-</Separator>
          <DateInputField
            value={toValue}
            onChange={handleToChange}
            active={activeField === "to"}
          />
        </DateInputs>

        <DayPicker
          mode="range"
          showOutsideDays
          fixedWeeks
          toDate={today}
          fromYear={2020}
          toYear={thisYear}
          disabled={{ after: latestDate }}
          selected={dateRange}
          onSelect={handleRangeSelect}
          captionLayout="dropdown"
        />
      </Wrapper>

      <Actions>
        <Button as={FormSubmit} onClick={() => handleSave()}>
          Save
        </Button>
        <Button kind="secondary" onClick={() => handleToggle()}>
          Close
        </Button>
      </Actions>
    </>
  );
}

function DateInputField({ title, value, active, onChange, ...props }) {
  return (
    <FormField name={title} css={{ flex: "0 0 104px" }}>
      <FormLabel css={{ display: "none" }}>{title}</FormLabel>

      <FormValidityState>
        {(validity) => (
          <DateInput>
            <FormControl asChild>
              <InputFormatted
                type="text"
                placeholder="Mmm dd yyyy"
                value={value}
                onChange={onChange}
                required
                active={active}
              />
            </FormControl>
          </DateInput>
        )}
      </FormValidityState>

      <DayPickerFormMessage match="valueMissing">
        No date defined
      </DayPickerFormMessage>
      <DayPickerFormMessage match="badInput">
        Format: Jan 1 2023
      </DayPickerFormMessage>
      <DayPickerFormMessage match="typeMismatch">
        Format: Jan 1 2023
      </DayPickerFormMessage>
      <DayPickerFormMessage match="patternMismatch">
        Format: Jan 1 2023
      </DayPickerFormMessage>
    </FormField>
  );
}

const DateInputs = styled(FormRoot, {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "$1",
  marginTop: "1rem",
  border: "1px solid $slate4",
  position: "relative",

  "@xxs": {
    flexDirection: "row",
  },
});

const DateInput = styled("div", {
  display: "flex",
});

const DateInputIcon = styled("div", {
  borderRight: "1px solid $slate4",
  padding: "0.5rem",
});

const InputFormatted = styled("input", {
  all: "unset",
  border: "none",
  width: "100%",
  fontSize: "0.875rem",
  color: "$slate12",
  padding: "0.25rem",

  "&:placeholder": {
    color: "$slate10",
  },

  variants: {
    active: {
      true: {
        background: "$slate3",
        color: "$slate11",
      },
      false: {
        background: "transparent",
        color: "$slate12",
      },
    },
  },
});

const Separator = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.875rem",
  color: "$slate10",
});

const Actions = styled("div", {
  background: "white",
  borderTop: "1px solid $slate3",
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",
  padding: "1rem",
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",

  "& > button": {
    flex: 1,

    "&:first-of-type": {
      flex: 1.5,
    },
  },
});

const DayPickerFormMessage = styled(FormMessage, {
  position: "absolute",
  top: "100%",
  left: 0,
});

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$3",

  ".rdp": {
    margin: 0,
    padding: "0.75rem 0 0 0",

    "--rdp-cell-size": "2rem",
    "--rdp-accent-color": "#fff",
    "--rdp-background-color": "white",

    "@sm": {
      "--rdp-cell-size": "2.5rem",
    },
  },

  // STYLING CAPTION
  ".rdp-caption": {},

  ".rdp-caption_dropdowns": {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },

  ".rdp-dropdown": {
    border: "none",
    background: "$slate3",
  },

  ".rdp-caption_label": {
    border: "none",
    background: "$slate3",
    fontFamily: "$sans",
    padding: "0.5rem 0.75rem",
    fontSize: "0.875rem",
    color: "$slate12",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gridArea: "navigation",
    fontWeight: "400",

    svg: {
      display: "none",
    },

    "&:focus": {
      outline: "none",
      boxShadow: "none",
      background: "$slate4",
    },
  },

  ".rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label ": {
    fontSize: "0.875rem",
    color: "$slate12",
    background: "$slate3",
    borderRadius: "0",
    padding: "0.5rem 0.75rem",
    margin: 0,
    border: "none",
  },

  ".rdp-head_cell": {
    fontWeight: "500",
  },

  ".rdp-cell": {
    height: "2rem",
  },

  "& .rdp-button": {
    "&:focus:not([disabled])": {
      borderColor: "$slate2",
    },
    "&:hover:not([disabled])": {
      backgroundColor: "$slate3",
      color: "$slate12",
    },
  },

  ".rdp-day": {
    width: "calc(100vw / 7 - 0.25rem)",
    maxWidth: "calc(100vw / 7 - 0.25rem)",
    height: "2rem",
    fontFamily: "$paragraph",
    fontWeight: "400",
    fontSize: "0.875rem",
    border: "none",
    borderRadius: "0",
    cursor: "pointer",

    "@xs": {
      height: "2.25rem",
    },
    "@sm": {
      width: "2.95rem",
      maxWidth: "2.95rem",
    },
  },

  ".rdp-day_selected": {
    backgroundColor: "$slate12",
    color: "$slate1",
  },

  ".rdp-day_today, .rdp-day_today:not(.rdp-day_outside)": {
    fontWeight: "500",
    color: "$slate12",
    background: "$slate1",
  },

  ".rdp-day_disabled": {
    cursor: "not-allowed",
    color: "$slate8",
  },

  ".rdp-day_range_start, .rdp-day_range_start:not([aria-disabled='true'])": {
    color: "$slate12",
    backgroundColor: "$slate7",
  },

  ".rdp-day_range_end, .rdp-day_range_end:not([aria-disabled='true'])": {
    color: "$slate12",
    backgroundColor: "$slate7",
  },

  ".rdp-day_range_middle, .rdp-day_range_middle:not([aria-disabled='true'])": {
    borderRadius: "0",
    color: "$slate11",
    backgroundColor: "$slate4",
  },

  ".DayPicker-NavButton": {
    color: "$slate7",
  },

  ".DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover":
    { backgroundColor: "$slate1" },
});
