import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { useAtom } from "jotai";
import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";

import IconPaths from "../icons/iconPaths";
import IconPathsNested from "../icons/iconPathsNested";

import Button from "../button";
import Checkbox from "../inputCheckbox";
import Pagination from "../pagination";

import { styled } from "../stitches.config";
import { client } from "../../utils/client";
import { formatNumber, formatTime, formatPercent } from "../helpers/formatters";
import {
  periodAtom,
  periodCompareAtom,
  periodPanelAtom,
} from "../helpers/atoms";

import ControlsColumn from "../table/controlsColumn";
import ControlsExport from "../table/controlsExport";
import ControlsSearch from "../table/controlsSearch";
import TableTotals from "../table/tableTotals";
import TableRow, { StyledTableRow } from "../table/tableRow";
import SubRows from "../table/tableSubRows";
import TableRowCompare from "../table/tableRowCompare";
import TableSubRowsCompare from "../table/tableSubRowsCompare";

export default function Pages() {
  // COLUMNS
  const initialColumns = [
    { id: "path", name: "Slug", visible: true },
    {
      id: "users",
      name: "Users",
      visible: true,
      total: null,
      totalCompare: null,
    },
    {
      id: "views",
      name: "Views",
      visible: true,
      total: null,
      totalCompare: null,
    },
    {
      id: "average_seconds_on_page",
      name: "Avg. Time on Page",
      visible: true,
      total: null,
      totalCompare: null,
    },
    {
      id: "entrances",
      name: "Entrances",
      visible: true,
      total: null,
      totalCompare: null,
    },
    {
      id: "exits",
      name: "Exits",
      visible: true,
      total: null,
      totalCompare: null,
    },
    {
      id: "bounce_rate",
      name: "Bounce Rate",
      visible: true,
      total: null,
      totalCompare: null,
    },
  ];

  const [period] = useAtom(periodAtom);
  const [periodCompare] = useAtom(periodCompareAtom);
  const [periodPanel] = useAtom(periodPanelAtom);

  const [data, setData] = useState([]);
  const [aggregates, setAggregates] = useState();

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ id: "views", desc: true });
  const [columns, setColumns] = useState(initialColumns);
  const [columnOrder, setColumnOrder] = useState(columns.map((c) => c.id));

  const [pathRollup, setPathRollup] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const aggregatesFetchParams = {
      date: period.date,
      interval_count: period.intervalCount,
      interval: period.interval,
      previous_date: periodCompare.date,
    };


    client.aggregateComparisons(aggregatesFetchParams).then((r) => {
      setAggregates(r.aggregates[0]);
    });
  }, []);

  useEffect(() => {
    setExpandedRows([]);

    const fetchParams = {
      date: period.date,
      interval_count: period.intervalCount,
      interval: period.interval,
      page: pageIndex,
      per: pageSize,
    };

    if (sort.id) {
      fetchParams.order = sort.id;
      fetchParams.desc = sort.desc;
    }

    if (search) {
      fetchParams.path = search;
    }

    if (pathRollup) {
      if (periodCompare.active) {
        fetchParams.previous_date = periodCompare.date;

        client.pathRollupComparisons(fetchParams).then((r) => {
          setPageCount(r.total_pages);
          setData(r.path_rollups);
        });
      } else {
        client.pathRollups(fetchParams).then((r) => {
          setPageCount(r.total_pages);
          setData(r.path_rollups);
        });
      }
    } else {
      if (periodCompare.active) {
        fetchParams.previous_date = periodCompare.date;

        client.pathAggregateComparisons(fetchParams).then((r) => {
          setPageCount(r.total_pages);
          setData(r.path_aggregates);
        });
      } else {
        client.pathAggregates(fetchParams).then((r) => {
          setPageCount(r.total_pages);
          setData(r.path_aggregates);
        });
      }
    }
    // We need to depend on the keys insead of the objects because atoms set a new object and even if all the keys are the same,
    // react sees the new object and does a re-render
  }, [period.date, period.intervalCount, period.interval, periodCompare.date, periodCompare.active, pageIndex, pageSize, sort, search, pathRollup]);

  useEffect(() => {
    // Update the columns array when aggregates prop changes
    setColumns((prevColumns) => {
      return prevColumns.map((column) => {
        if (column.id === "users") {
          return {
            ...column,
            total: aggregates?.users,
            totalCompare: aggregates?.previous?.users,
          };
        } else if (column.id === "views") {
          return {
            ...column,
            total: aggregates?.views,
            totalCompare: aggregates?.previous?.views,
          };
        } else if (column.id === "average_seconds_on_page") {
          return {
            ...column,
            total: aggregates?.average_seconds_on_page,
            totalCompare: aggregates?.previous?.average_seconds_on_page,
          };
        } else if (column.id === "entrances" || column.id === "exits") {
          return {
            ...column,
            total: aggregates?.sessions,
            totalCompare: aggregates?.previous?.sessions,
          };
        } else if (column.id === "bounce_rate") {
          return {
            ...column,
            total: aggregates?.bounce_rate,
            totalCompare: aggregates?.previous?.bounce_rate,
          };
        }
        return column;
      });
    });
  }, [aggregates]);

  // SEARCH

  function handleSearch(value) {
    setSearch(value);
  }

  // SORTING

  function handleColumnSort(column) {
    const isDesc = sort.id === column && sort.desc === true;
    const order = isDesc ? false : true;

    setSort({ id: column, desc: order });
  }

  // COLUMN VISIBILITY

  function handleColumnVisibility(column) {
    const newColumns = columns.map((c) => {
      if (c.id === column) {
        return { ...c, visible: !c.visible };
      } else {
        return c;
      }
    });

    setColumns(newColumns);
  }

  // PATH ROLLUPS

  function rollupPaths() {
    setPathRollup(true);
  }

  function unrollPaths() {
    setPathRollup(false);
  }

  function togglePathExpand(path) {
    const fetchParams = {
      date: period.date,
      interval_count: period.intervalCount,
      interval: period.interval,
      page: pageIndex,
      per: pageSize,
    };

    if (sort.id) {
      fetchParams.order = sort.id;
      fetchParams.desc = sort.desc;
    }

    if (search) {
      fetchParams.path = search;
    }

    fetchParams.path = path;
    fetchParams.search_mode = "starts_with";

    if (data.find((rollup) => rollup.path === path).subRows) {
      if (expandedRows.includes(path)) {
        setExpandedRows(expandedRows.filter((i) => i !== path));
      } else {
        setExpandedRows([...expandedRows, path]);
      }
    } else {
      client.pathAggregates(fetchParams).then((r) => {
        let newData = data;
        let rollup = data.find((rollup) => rollup.path === path);
        rollup.subRows = r.path_aggregates;
        setData(newData);

        if (expandedRows.includes(path)) {
          setExpandedRows(expandedRows.filter((i) => i !== path));
        } else {
          setExpandedRows([...expandedRows, path]);
        }
      });
    }
  }

  if (!data) {
    return <div>placeholder</div>;
  } else {
    return (
      <Wrapper panel={periodPanel}>
        <Header>
          <Title>Pages</Title>
        </Header>

        <TableControls>
          <ControlsSearch
            value={search ?? ""}
            onChange={(value) => handleSearch(String(value))}
            placeholder="Search all columns..."
          />

          <ControlsColumn
            columns={columns}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            handleColumnVisibility={handleColumnVisibility}
          />

          {pathRollup ? (
            <Button size="sm" kind="action" onClick={() => unrollPaths()}>
              <IconPaths />
              Unroll Paths
            </Button>
          ) : (
            <Button size="sm" kind="action" onClick={() => rollupPaths()}>
              <IconPathsNested />
              Rollup Paths
            </Button>
          )}

          <ControlsExport date={period.date} intervalCount={period.intervalCount} interval={period.interval} order={sort.id} desc={sort.desc} path={search} pathRollup={pathRollup} />
        </TableControls>

        <TableWrapper panel={periodPanel}>
          <Table>
            <TableHead>
              <StyledTableRow kind="header">
                {columnOrder.map((columnId) => {
                  const column = columns.find((c) => c.id === columnId);

                  if (!column.visible) {
                    return null;
                  }

                  return (
                    <TableHeader
                      key={column.id}
                      kind={column.id === "path" ? "path" : undefined}
                    >
                      <TableHeaderTitle
                        onClick={() => handleColumnSort(column.id)}
                      >
                        <TableHeaderTitleText>
                          {column.name}
                        </TableHeaderTitleText>

                        {sort.id === column.id &&
                          (sort.desc === true ? (
                            <TableHeaderIcon>
                              <TriangleDownIcon />
                            </TableHeaderIcon>
                          ) : (
                            <TableHeaderIcon>
                              <TriangleUpIcon />
                            </TableHeaderIcon>
                          ))}
                      </TableHeaderTitle>
                    </TableHeader>
                  );
                })}
              </StyledTableRow>

              <StyledTableRow kind="header">
                <TableTotals columnOrder={columnOrder} columns={columns} />
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {periodCompare.active
                ? data.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRowCompare
                          row={row}
                          subRow={false}
                          columns={columns}
                          columnOrder={columnOrder}
                          expandedRows={expandedRows}
                          pathRollup={pathRollup}
                          togglePathExpand={togglePathExpand}
                        />

                        {expandedRows.includes(row.path) && row.subRows && (
                          <TableSubRowsCompare
                            row={row}
                            sort={sort}
                            compare={periodCompare.active}
                            columns={columns}
                            columnOrder={columnOrder}
                            expandedRows={expandedRows}
                            pathRollup={pathRollup}
                          />
                        )}
                      </React.Fragment>
                    );
                  })
                : data.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          row={row}
                          subRow={false}
                          columns={columns}
                          columnOrder={columnOrder}
                          expandedRows={expandedRows}
                          pathRollup={pathRollup}
                          togglePathExpand={togglePathExpand}
                        />

                        {expandedRows.includes(row.path) && row.subRows && (
                          <SubRows
                            row={row}
                            sort={sort}
                            columns={columns}
                            columnOrder={columnOrder}
                            expandedRows={expandedRows}
                            pathRollup={pathRollup}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
            </TableBody>
          </Table>
        </TableWrapper>

        <Pagination
          currentPage={pageIndex}
          setCurrentPage={setPageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalPages={pageCount}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "$white",
  borderTop: "1px solid $slate4",
  padding: "1rem 0 1rem 1rem",

  variants: {
    panel: {
      true: {
        "@lg": { padding: "2.5rem 0 2.5rem 2.5rem" },
      },
      false: {
        "@lg": { padding: "2.5rem" },
      },
    },
  },
});

const Header = styled("div", {
  display: "flex",
  marginBottom: "1rem",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
  padding: "0.5rem 0",
});

const Title = styled("h3", {
  fontSize: "1.25rem",
});

const TableWrapper = styled("div", {
  width: "calc(100vw - 1rem)",
  overflow: "scroll",

  "@lg": {
    width: "calc(100vw - 5rem)",
  },

  // "@xxl": {
  // overflow: "initial",
  // },

  variants: {
    panel: {
      true: {
        "@lg": { width: "calc(100vw - 25.5rem)" },
      },
      false: {
        "@lg": { width: "calc(100vw - 5rem)" },
      },
    },
  },
});

const Table = styled("table", {
  borderCollapse: "collapse",
  width: "100%",
});

const TableControls = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
  width: "100%",
  padding: "0.5rem 1rem 0.5rem 0",
  position: "sticky",
  top: "4rem",
  background: "white",
  zIndex: 5,
  borderBottom: "1px solid $slate4",
  flexWrap: "wrap",

  "@md": {
    flexWrap: "nowrap",
  },

  "@lg": {
    padding: "0.5rem 1rem",
  },
});

const TableHead = styled("thead", {
  zIndex: "5",

  // "@xxl": {
  //   position: "sticky",
  //   top: "calc(7rem + 1px)",
  // },
});

const TableBody = styled("tbody", {});

const tableCellStyles = {
  textAlign: "right",
  fontWeight: "400",
  fontFamily: "$sans",
  position: "relative",
  fontSize: "0.875rem",
  border: "1px solid $slate3",
  borderWidth: "0 1px 1px 0",
  borderCollapse: "collapse",
  transition: "$transitionShort",

  "&:first-of-type": {
    borderWidth: "0 0 1px 0 ",
  },
  "&:last-of-type": {
    borderWidth: "0 0 1px 0",
  },

  variants: {
    kind: {
      default: {
        flex: "1 0 180px",
        minWidth: "180px",
      },
      path: {
        flex: "1 1 100%",
        minWidth: "240px",
        width: "100%",
      },
    },
  },

  defaultVariants: {
    kind: "default",
  },
};

const TableHeaderTitle = styled("div", {
  position: "relative",
  padding: "0.75rem",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",

  "&:before": {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "$slate2",
    zIndex: "1",
    transition: "$transitionBase",
    transformOrigin: "center bottom",
    transform: "scaleY(0)",
  },

  "&:hover": {
    cursor: "pointer",
    color: "$slate12",

    "&:before": {
      transform: "scaleY(1)",
    },
  },
});

const TableHeaderTitleText = styled("span", {
  position: "relative",
  zIndex: "2",
});

const TableHeaderIcon = styled("span", {
  position: "relative",
  zIndex: "2",
});

const TableHeader = styled("th", {
  ...tableCellStyles,
  background: "white",
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  alignItems: "flex-end",
  color: "$slate11",

  [`${TableHeaderTitle}`]: {
    justifyContent: "flex-end",
    textAlign: "right",
  },

  "&:first-of-type": {
    textAlign: "left",
    justifyContent: "flex-start",

    [`${TableHeaderTitle}`]: {
      justifyContent: "flex-start",
      textAlign: "left",
    },
  },
});
