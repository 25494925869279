import React from "react";
import { styled } from "./stitches.config";

export const Text = styled("div", {
  fontWeight: "400",

  variants: {
    kind: {
      name: {
        color: "$slate12",
        fontSize: "0.875rem",
      },
      period: {
        color: "$slate11",
        fontSize: "0.75rem",
      },
      count: {
        color: "$slate12",
        fontSize: "0.75rem",
      },
      range: {
        color: "$slate11",
        fontSize: "0.675rem",
      },
      percent: {
        color: "$slate11",
        fontSize: "0.675rem",
        textAlign: "right",
        width: "3.25rem",
      },
      difference: {
        fontSize: "0.875rem",
      },
      percentChange: {
        textAlign: "center",
        fontSize: "0.675rem",
        padding: "0.25rem",
        width: "initial",
      },
    },

    value: {
      positive: {},
      negative: {},
      same: {},
      empty: {},
    },
  },

  compoundVariants: [
    {
      kind: "difference",
      value: "positive",
      css: {
        color: "$green11",
      },
    },
    {
      kind: "difference",
      value: "negative",
      css: {
        color: "$red11",
      },
    },
    {
      kind: "difference",
      value: "same",
      css: {
        color: "$slate11",
      },
    },
    {
      kind: "difference",
      value: "empty",
      css: {
        color: "$slate10",
      },
    },

    {
      kind: "percentChange",
      value: "positive",
      css: {
        color: "$green11",
        backgroundColor: "$green2",
      },
    },
    {
      kind: "percentChange",
      value: "negative",
      css: {
        color: "$red11",
        backgroundColor: "$red2",
      },
    },
    {
      kind: "percentChange",
      value: "same",
      css: {
        color: "$slate11",
        backgroundColor: "$slate2",
      },
    },
  ],
});
