import React from "react";
import { styled } from "../stitches.config";

export const Card = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
  border: "1px solid $slate3",
});

export const Content = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
});

export const Title = styled("h3", {
  fontSize: "1.25rem",
  fontWeight: "500",
  margin: "0",
  color: "$slate12",
});

export const Info = styled("p", {
  fontSize: "0.875rem",
  fontWeight: "400",
  margin: "0",
  color: "$slate11",
});

export const Actions = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});

export const Action = styled("button", {
  width: "2.75rem",
  height: "2.75rem",
  backgroundColor: "transparent",
  transition: "$transitionBase",
  cursor: "pointer",
  border: "1px solid $slate7",
  color: "$slate12",

  "&:hover": {
    border: "1px solid $slate10",
  },
});
