import React, { useState } from "react";
import { styled } from "../../stitches.config";
import Layout from "../../layout";
import SettingsNavigation from "../../settings/navigation";
import SettingsLayout, {
  Header,
  Title,
  Intro,
  Container,
  Content,
} from "../../settings/layout";
import { Dialog, DialogTrigger, DialogContent } from "../../dialog";
import Button from "../../button";

import EditAccountInformation from "../../settings/editAccountInformation";

export default function Account(props) {
  const { user, org } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <Layout {...props}>
      <SettingsLayout>
        <SettingsNavigation active="account" {...props} />

        <Container>
          <Header>
            <div>
              <Title>Account</Title>
              <Intro>Edit your account information.</Intro>
            </div>

            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
              <DialogTrigger asChild>
                <Button>Edit Account</Button>
              </DialogTrigger>

              <DialogContent>
                <EditAccountInformation user={user} />
              </DialogContent>
            </Dialog>
          </Header>
          <Content>
            <div>
              <Intro>Name:</Intro>
              <Text>{user.name}</Text>
            </div>

            <div>
              <Intro>Email:</Intro>
              <Text>{user.email}</Text>
            </div>
          </Content>
        </Container>
      </SettingsLayout>
    </Layout>
  );
}

const Text = styled("p", {
  color: "$slate11",
});
