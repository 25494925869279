import React from "react";

import { styled } from "../stitches.config";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverGroup,
  PopoverItem,
} from "../popover";

export default function SupportPopover() {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Help>?</Help>
      </PopoverTrigger>
      <PopoverContent align="end">
        <PopoverGroup>
          <PopoverItem href="mailto:support@onverre.com">
            support@onverre.com
          </PopoverItem>
        </PopoverGroup>
      </PopoverContent>
    </Popover>
  );
}

const Help = styled("button", {
  alignItems: "center",
  borderLeft: "1px solid $slate4!important",
  color: "$slate12",
  cursor: "pointer",
  display: "flex",
  fontSize: "0.875rem",
  height: "100%",
  justifyContent: "center",
  width: "4rem",
  transition: "$transitionBase",
});
