import React from "react";
import dayjs from "dayjs";

import { styled } from "../stitches.config";
import {
  formatReadableDate,
  formatTitle,
  formatPercent,
  formatNumber,
} from "../helpers/formatters";

const titleOrder = ["users", "pageViews", "sessions", "bounceRate"];
const titleOrderPrevious = [
  "usersPrevious",
  "pageViewsPrevious",
  "sessionsPrevious",
  "bounceRatePrevious",
];
const removePreviousText = (title) => {
  return title.replace("Previous", "");
};

export function TooltipLine({ slice, compare, intervalCount, interval }) {
  const date = slice.points[0].data.x;
  const datePrevious = slice.points.find((p) => p.data.xPrevious)?.data?.xPrevious

  return (
    <Tooltip>
      <TooltipGroup>
        <TooltipDate>{formatReadableDate(date)}</TooltipDate>

        {titleOrder.map((title) => {
          const point = slice.points.find((p) => p.serieId === title);

          if (point) {
            return (
              <TooltipItem key={point.id}>
                <TooltipLabel theme={point.serieId}>
                  {formatTitle(point.serieId)}
                </TooltipLabel>
                {point.serieId === "bounceRate" ? (
                  <TooltipValue>{formatPercent(point.data.y)}</TooltipValue>
                ) : (
                  <TooltipValue>{formatNumber(point.data.y)}</TooltipValue>
                )}{" "}
              </TooltipItem>
            );
          }
        })}
      </TooltipGroup>

      {compare && (
        <>
          <TooltipCompare>Compared To:</TooltipCompare>

          <TooltipGroup compare="true">
            <TooltipDate>{formatReadableDate(datePrevious)}</TooltipDate>

            {titleOrderPrevious.map((title) => {
              const point = slice.points.find((p) => p.serieId === title);

              if (point) {
                return (
                  <TooltipItem key={point.id}>
                    <TooltipLabel theme={point.serieId}>
                      {removePreviousText(formatTitle(point.serieId))}
                    </TooltipLabel>
                    {point.serieId === "bounceRatePrevious" ? (
                      <TooltipValue>{formatPercent(point.data.y)}</TooltipValue>
                    ) : (
                      <TooltipValue>{formatNumber(point.data.y)}</TooltipValue>
                    )}{" "}
                  </TooltipItem>
                );
              }
            })}
          </TooltipGroup>
        </>
      )}
    </Tooltip>
  );
}

export function TooltipBar({ data, compare, interval, intervalCount }) {
  const date = data.x;
  // TODO: need to somehow check to see if the datePrevious exists or is N/A
  const datePrevious = dayjs(date).subtract(intervalCount, interval).toDate();

  return (
    <Tooltip>
      <TooltipGroup>
        <TooltipDate>{formatReadableDate(date)}</TooltipDate>
        {data.users && (
          <TooltipItem>
            <TooltipLabel theme="users">Users</TooltipLabel>
            <TooltipValue>{formatNumber(data.users)}</TooltipValue>{" "}
          </TooltipItem>
        )}
        {data.pageViews && (
          <TooltipItem>
            <TooltipLabel theme="pageViews">Page Views</TooltipLabel>
            <TooltipValue>{formatNumber(data.pageViews)}</TooltipValue>{" "}
          </TooltipItem>
        )}
        {data.sessions && (
          <TooltipItem>
            <TooltipLabel theme="sessions">Sessions</TooltipLabel>
            <TooltipValue>{formatNumber(data.sessions)}</TooltipValue>{" "}
          </TooltipItem>
        )}
        {data.bounceRate && (
          <TooltipItem>
            <TooltipLabel theme="bounceRate">Bounce Rate</TooltipLabel>
            <TooltipValue>{formatPercent(data.bounceRate)}</TooltipValue>{" "}
          </TooltipItem>
        )}
      </TooltipGroup>

      {compare && (
        <>
          <TooltipCompare>Compared To:</TooltipCompare>

          <TooltipGroup compare="true">
            <TooltipDate>{formatReadableDate(datePrevious)}</TooltipDate>

            {data.usersPrevious && (
              <TooltipItem>
                <TooltipLabel theme="usersPrevious">Users</TooltipLabel>
                <TooltipValue>{formatNumber(data.usersPrevious)}</TooltipValue>
              </TooltipItem>
            )}

            {data.pageViewsPrevious && (
              <TooltipItem>
                <TooltipLabel theme="pageViewsPrevious">
                  Page Views
                </TooltipLabel>
                <TooltipValue>
                  {formatNumber(data.pageViewsPrevious)}
                </TooltipValue>
              </TooltipItem>
            )}

            {data.sessionsPrevious && (
              <TooltipItem>
                <TooltipLabel theme="sessionsPrevious">Sessions</TooltipLabel>
                <TooltipValue>
                  {formatNumber(data.sessionsPrevious)}
                </TooltipValue>
              </TooltipItem>
            )}

            {data.bounceRatePrevious && (
              <TooltipItem>
                <TooltipLabel theme="bounceRatePrevious">
                  Bounce Rate
                </TooltipLabel>
                <TooltipValue>
                  {formatPercent(data.bounceRatePrevious)}
                </TooltipValue>
              </TooltipItem>
            )}
          </TooltipGroup>
        </>
      )}
    </Tooltip>
  );
}

const Tooltip = styled("div", {
  backgroundColor: "$slate12",
  borderRadius: "0px",
  padding: "0.75rem 1.25rem 0.75rem 0.75rem",
  boxShadow: "none",
});

const TooltipGroup = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",

  "&:before": {
    content: "''",
    display: "block",
    border: "none",
    borderLeft: "2px dashed transparent",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },

  variants: {
    compare: {
      true: {
        paddingLeft: "$4",

        "&:before": {
          borderColor: "$slate11",
          opacity: 0.5,
        },
      },
    },
  },
});

const TooltipDate = styled("div", {
  color: "$slate2",
  fontSize: "0.75rem",
  marginBottom: "0.5rem",
});

const TooltipItem = styled("div", {
  display: "grid",
  gridTemplateColumns: "7.5rem auto",
  alignItems: "center",
  marginBottom: "0.25rem",
  gap: "0.25rem",
});

const TooltipValue = styled("div", {
  color: "$slate1",
  textAlign: "right",
  fontSize: "0.875rem",
});

const TooltipLabel = styled("span", {
  fontSize: "0.875rem",

  variants: {
    theme: {
      users: { color: "$blue8" },
      usersPrevious: { color: "$blue6" },
      pageViews: { color: "$amber8" },
      pageViewsPrevious: { color: "$amber6" },
      sessions: { color: "$crimson8" },
      sessionsPrevious: { color: "$crimson6" },
      bounceRate: { color: "$purple8" },
      bounceRatePrevious: { color: "$purple6" },
    },
  },
});

const TooltipCompare = styled("div", {
  color: "$slate8",
  fontSize: "0.75rem",
  textTransform: "uppercase",
  marginBottom: "$2",
  marginTop: "$3",
});
