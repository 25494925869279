import React, { useState } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { styled } from "../stitches.config";
import { csrf } from "../../utils/csrf.js";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import InputText from "../inputText";
import InputCheckbox from "../inputCheckbox";
import InputError from "../inputError";
import Button from "../button";

export default function RedshiftConnectionForm({ source, org }) {
  const [name, setName] = useState(source.name);
  const [host, setHost] = useState(source.host);
  const [database, setDatabase] = useState(source.database);
  const [schema, setSchema] = useState(source.schema);
  const [username, setUsername] = useState(source.username);
  const [password, setPassword] = useState(source.password);
  const [port, setPort] = useState(source.port || 5439);
  const [namespace, setNamespace] = useState(source.namespace);
  const [sslMode, setSslMode] = useState(source.ssl_mode ? source.ssl_mode : true);

  return (
    <Form
      id="edit_source"
      action={`/settings/${org.slug}/warehouse-connection`}
      acceptCharset="UTF-8"
      method="post"
      data-turbo="false"
    >
      <input name="_method" type="hidden" value="patch" />
      <input name="source[type]" type="hidden" value="Redshift::Source" />
      <input
        type="hidden"
        name="authenticity_token"
        value={csrf()}
        autoComplete="off"
      />

      <InputField>
        <InputLabel htmlFor="name">Name</InputLabel>
        <InputText
          aria-label="Name"
          id="name"
          name="source[name]"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          errors={source.errors?.name}
          autoFocus
          required
          autoComplete="off"
        />
        {source.errors?.name && (
          <InputError error={source.errors?.name} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="host">Host</InputLabel>
        <InputText
          aria-label="Host"
          id="host"
          name="source[host]"
          type="text"
          value={host}
          onChange={(e) => setHost(e.target.value)}
          errors={source.errors?.host}
          required
          autoComplete="off"
        />
        {source.errors?.host && (
          <InputError error={source.errors?.host} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="database">Database</InputLabel>
        <InputText
          aria-label="Database"
          id="database"
          name="source[database]"
          type="text"
          value={database}
          onChange={(e) => setDatabase(e.target.value)}
          errors={source.errors?.database}
          required
          autoComplete="off"
        />
        {source.errors?.database && (
          <InputError error={source.errors?.database} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="schema">Schema</InputLabel>
        <InputText
          aria-label="Schema"
          id="schema"
          name="source[schema]"
          type="text"
          value={schema}
          onChange={(e) => setSchema(e.target.value)}
          errors={source.errors?.schema}
          required
          autoComplete="off"
        />
        {source.errors?.schema && (
          <InputError error={source.errors?.schema} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="username">Username</InputLabel>
        <InputText
          aria-label="Username"
          id="username"
          name="source[username]"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          errors={source.errors?.username}
          required
          autoComplete="off"
        />
        {source.errors?.username && (
          <InputError error={source.errors?.username} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="password">Password</InputLabel>
        <InputText
          aria-label="Password"
          id="password"
          name={password && password !== "" && "source[password]"}
          type="text"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          errors={source.errors?.password}
          autoComplete="off"
          placeholder="●●●●●●●●●●●●●●●●●●●●●"
        />
        {source.errors?.password && (
          <InputError error={source.errors?.password} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="port">Port</InputLabel>
        <InputText
          aria-label="Port"
          id="port"
          name="source[port]"
          type="text"
          value={port}
          onChange={(e) => setPort(e.target.value)}
          errors={source.errors?.port}
          required
          autoComplete="off"
        />
        {source.errors?.port && (
          <InputError error={source.errors?.port} />
        )}
      </InputField>

      <InputField>
        <InputLabel htmlFor="namespace">Namespace</InputLabel>
        <InputText
          aria-label="Namespace"
          id="namespace"
          name="source[namespace]"
          type="text"
          value={namespace}
          onChange={(e) => setNamespace(e.target.value)}
          errors={source.errors?.namespace}
          autoComplete="off"
        />
        {source.errors?.namespace && (
          <InputError error={source.errors?.namespace} />
        )}
      </InputField>

      <InputField layout="row">
        <InputCheckbox
          aria-label="SSL Mode"
          id="sslMode"
          name="source[ssl_mode]"
          checked={sslMode}
          onCheckedChange={(e) => setSslMode(e)}
          errors={source.errors?.ssl_mode}
          autoComplete="off"
        />
        <InputLabel htmlFor="sslMode">SSL Mode</InputLabel>
        {source.errors?.ssl_mode && (
          <InputError error={source.errors?.ssl_mode} />
        )}
      </InputField>

      <Button type="submit">Save Connection</Button>
    </Form>
  );
}

const Form = styled("form", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "1.25rem",

  "> button": {
    marginTop: "1rem",
  },
});
