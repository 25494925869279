import * as Turbo from "@hotwired/turbo"
import ReactRailsUJS from "react_ujs"
import components from "./components/pages/**/*.jsx"
import Flash from "./components/flash.jsx"
import { client } from "./utils/client"

window.Turbo = Turbo
window.apiClient = client

let componentsContext = { "Flash": Flash }

components.forEach((component) => {
  componentsContext[component.default.name] = component.default
})

ReactRailsUJS.getConstructor = (name) => {
  return componentsContext[name]
}
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount, false)
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount, false)
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount, false)
