import React from "react";
import { useAtom } from "jotai";

import { periodCompareAtom } from "../helpers/atoms";

import UserCompositionCompare from "./userCompositionCompare";
import UserCompositionSingle from "./userCompositionSingle";

export default function UserComposition() {
  const [periodCompare] = useAtom(periodCompareAtom);

  if (periodCompare.active) {
    return <UserCompositionCompare />;
  } else {
    return <UserCompositionSingle />;
  }
}
