import React from "react";

import { styled } from "./stitches.config";

export default function ImportingData() {
  return (
    <Wrapper>
      <Content>
        <Text>Hold tight we're importing your data...</Text>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1246 534"
        >
          <path stroke="#9747FF" strokeWidth="2" d="M410 247h800"></path>
          <g fill="#9747FF" filter="url(#filter0_d_351_10251)">
            <path d="M499.5 246c2 0 9.5 0 11.5-2s3.5-2 4-2v5h-19s1.5-1 3.5-1zM499.5 248c2 0 9.5 0 11.5 2s3.5 2 4 2v-5h-19s1.5 1 3.5 1zM530.5 246c-2 0-9.5 0-11.5-2s-3.5-2-4-2v5h19s-1.5-1-3.5-1zM530.5 248c-2 0-9.5 0-11.5 2s-3.5 2-4 2v-5h19s-1.5 1-3.5 1z"></path>
            <path d="M520 247a5 5 0 11-10.001-.001A5 5 0 01520 247z"></path>
          </g>
          <circle
            cx="205.5"
            cy="247.5"
            r="204.5"
            fill="url(#paint0_linear_351_10251)"
            stroke="url(#paint1_linear_351_10251)"
            strokeWidth="2"
          ></circle>
          <circle
            cx="205.5"
            cy="247.5"
            r="204.5"
            stroke="url(#paint2_linear_351_10251)"
            strokeWidth="2"
          ></circle>
          <circle
            cx="410"
            cy="247"
            r="4"
            fill="#fff"
            stroke="#9747FF"
            strokeWidth="2"
          ></circle>
          <path
            fill="#fff"
            stroke="#000"
            d="M664.5 0.5H1245.5V533.5H664.5z"
          ></path>
          <rect
            width="32"
            height="10"
            x="844"
            y="255"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="289"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="323"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="357"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="391"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="425"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="459"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="221"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="187"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="153"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="119"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="844"
            y="85"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <path fill="#9747FF" d="M664 241l8 6-8 6v-12z"></path>
          <path fill="#EFF6FE" d="M704 99H944V111H704z"></path>
          <path fill="#387FE9" d="M704 99H912V111H704z"></path>
          <path fill="#EFF6FE" d="M704 201H944V213H704z"></path>
          <path fill="#387FE9" d="M704 201H806V213H704z"></path>
          <path fill="#EFF6FE" d="M704 133H944V145H704z"></path>
          <path fill="#387FE9" d="M704 133H888V145H704z"></path>
          <path fill="#EFF6FE" d="M704 235H944V247H704z"></path>
          <path fill="#387FE9" d="M704 235H799V247H704z"></path>
          <path fill="#EFF6FE" d="M704 167H944V179H704z"></path>
          <path fill="#387FE9" d="M704 167H836V179H704z"></path>
          <path fill="#EFF6FE" d="M704 269H944V281H704z"></path>
          <path fill="#387FE9" d="M704 269H790V281H704z"></path>
          <path fill="#EFF6FE" d="M704 303H944V315H704z"></path>
          <path fill="#387FE9" d="M704 303H784V315H704z"></path>
          <path fill="#EFF6FE" d="M704 337H944V349H704z"></path>
          <path fill="#387FE9" d="M704 337H746V349H704z"></path>
          <path fill="#EFF6FE" d="M704 371H944V383H704z"></path>
          <path fill="#387FE9" d="M704 371H740V383H704z"></path>
          <path fill="#EFF6FE" d="M704 405H944V417H704z"></path>
          <path fill="#EFF6FE" d="M704 439H944V451H704z"></path>
          <path fill="#EFF6FE" d="M704 473H944V485H704z"></path>
          <path fill="#387FE9" d="M704 405H734V417H704z"></path>
          <path fill="#387FE9" d="M704 439H734V451H704z"></path>
          <path fill="#387FE9" d="M704 473H734V485H704z"></path>
          <rect
            width="80"
            height="10"
            x="704"
            y="255"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="289"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="323"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="357"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="391"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="425"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="459"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="221"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="187"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="153"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="119"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="704"
            y="85"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="120"
            height="16"
            x="704"
            y="41"
            fill="#7E868C"
            rx="8"
          ></rect>
          <rect
            width="120"
            height="16"
            x="984"
            y="41"
            fill="#7E868C"
            rx="8"
          ></rect>
          <path
            fill="#EFF6FE"
            d="M1088 81c19.05 0 37.47 6.797 51.96 19.168a80.022 80.022 0 0127.06 48.317 80.073 80.073 0 01-10.81 54.315 79.982 79.982 0 01-43.49 34.285 80.01 80.01 0 01-96-39.766 79.97 79.97 0 01-6.51-54.995 80.022 80.022 0 0130.77-46.045L1088 161V81z"
          ></path>
          <path
            fill="#387FE9"
            d="M1040.98 96.279A80.007 80.007 0 011088 81v80l-47.02-64.721z"
          ></path>
          <rect
            width="80"
            height="10"
            x="984"
            y="257"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="984"
            y="273"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="984"
            y="289"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="1174"
            y="257"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="1174"
            y="273"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="1174"
            y="289"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="984"
            y="475"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="80"
            height="10"
            x="984"
            y="457"
            fill="#C1C8CD"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="1174"
            y="475"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <rect
            width="32"
            height="10"
            x="1174"
            y="457"
            fill="#DFE3E6"
            rx="5"
          ></rect>
          <path
            stroke="#0081F1"
            strokeWidth="2"
            d="M984.5 410.5l44-30 45 27.5 45-4.5 45-18h41"
          ></path>
          <path
            stroke="#8E4EC6"
            strokeWidth="2"
            d="M985 384l43.5 13.5 45-23.5 45 11 45-26.5 42.5 9.5"
          ></path>
          <path
            stroke="#FFA01C"
            strokeWidth="2"
            d="M984.5 431l44-9 45-29.5 45 29 45-14.5 42.5-9"
          ></path>
          <path fill="#DFE3E6" d="M984 327H986V445H984z"></path>
          <path
            fill="#DFE3E6"
            d="M0 0H222V2H0z"
            transform="matrix(1 0 0 -1 984 445)"
          ></path>
          <circle
            cx="1029"
            cy="381"
            r="3"
            fill="#fff"
            stroke="#0081F1"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1074"
            cy="408"
            r="3"
            fill="#fff"
            stroke="#0081F1"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1119"
            cy="384"
            r="3"
            fill="#fff"
            stroke="#8E4EC6"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1164"
            cy="359"
            r="3"
            fill="#fff"
            stroke="#8E4EC6"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1206"
            cy="385"
            r="3"
            fill="#fff"
            stroke="#0081F1"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1029"
            cy="397"
            r="3"
            fill="#fff"
            stroke="#8E4EC6"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1074"
            cy="374"
            r="3"
            fill="#fff"
            stroke="#8E4EC6"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1119"
            cy="403"
            r="3"
            fill="#fff"
            stroke="#0081F1"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1164"
            cy="386"
            r="3"
            fill="#fff"
            stroke="#0081F1"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1206"
            cy="398"
            r="3"
            fill="#fff"
            stroke="#FFA01C"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1029"
            cy="422"
            r="3"
            fill="#fff"
            stroke="#FFA01C"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1074"
            cy="393"
            r="3"
            fill="#fff"
            stroke="#FFA01C"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1119"
            cy="421"
            r="3"
            fill="#fff"
            stroke="#FFA01C"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1164"
            cy="407"
            r="3"
            fill="#fff"
            stroke="#FFA01C"
            strokeWidth="2"
          ></circle>
          <circle
            cx="1206"
            cy="368"
            r="3"
            fill="#fff"
            stroke="#8E4EC6"
            strokeWidth="2"
          ></circle>
          <defs>
            <filter
              id="filter0_d_351_10251"
              width="58"
              height="30"
              x="486"
              y="232"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset></feOffset>
              <feGaussianBlur stdDeviation="5"></feGaussianBlur>
              <feComposite in2="hardAlpha" operator="out"></feComposite>
              <feColorMatrix values="0 0 0 0 0.592157 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 0.25 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_351_10251"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_351_10251"
                result="shape"
              ></feBlend>
            </filter>
            <linearGradient
              id="paint0_linear_351_10251"
              x1="532.5"
              x2="220"
              y1="5"
              y2="241.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9747FF" stopOpacity="0.36"></stop>
              <stop offset="1" stopColor="#9747FF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_351_10251"
              x1="411"
              x2="301.5"
              y1="244"
              y2="270"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9747FF"></stop>
              <stop offset="1" stopColor="#9747FF" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear_351_10251"
              x1="411"
              x2="301.5"
              y1="244"
              y2="270"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9747FF"></stop>
              <stop offset="1" stopColor="#9747FF" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </SVG>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled("div", {
  backgroundColor: "$slate2",
  margin: "$5",
  padding: "$5",

  "@lg": {
    margin: "$5 $10",
    padding: "$10",
  },
});

const Content = styled("div", {
  display: "flex",
  alignItems: "center",
  maxWidth: "60rem",
  margin: "0 auto",
  flexDirection: "column",
  gap: "$5",

  "@md": {
    gap: "0",
    flexDirection: "row",
  },
});

const Text = styled("p", {
  fontSize: "2.25rem",
  textAlign: "left",
  flex: "1 1 30%",
});

const SVG = styled("svg", {
  width: "auto",

  "@md": {
    width: "540px",
  },
});
