import { csrf } from "./csrf";

const client = {
  // supported params:
  // { date, start_date, end_date, interval_count, interval, order, desc, per, page }
  //
  //  ** if you pass in date, then it will return aggregates for only that date
  //  Use that if you want a single aggregate (like yesterdays 28 day lookback)
  //  Use start_date and end_date (and leave date blank) if you want to return
  //  multiple aggragates. Use that if you want all the daily aggregates for
  //  a given range. Keep in mind that end_date is INCLUSIVE... so if you pass
  //  start_date: '2023-01-01', end_date: '2023-01-06', then you will get back
  //  an aggregate with the date of '2023-01-06'
  aggregates(params) {
    return this.get("/api/v1/aggregates", params);
  },

  // supported params:
  // { date, previous_date, interval_count, interval }
  aggregateComparisons(params) {
    return this.get("api/v1/aggregate_comparisons", params);
  },

  // supported params:
  // { date, interval_count, interval, order, path, desc, search_mode, per, page }
  pathRollups(params) {
    return this.get("/api/v1/path_rollups", params);
  },

  // supported params:
  // { date, previous_date, interval_count, interval, order, path, desc, search_mode per, page }
  pathRollupComparisons(params) {
    return this.get("/api/v1/path_rollup_comparisons", params);
  },

  // supported params:
  // { date, interval_count, interval, order, path, desc, search_mode per, page }
  pathAggregates(params) {
    return this.get("/api/v1/path_aggregates", params);
  },

  // supported params:
  // { date, previous_date, interval_count, interval, order, path, desc, search_mode per, page }
  pathAggregateComparisons(params) {
    return this.get("/api/v1/path_aggregate_comparisons", params);
  },

  // supported params:
  // { date, interval_count, interval, per, page, source_medium, search_mode, path }
  sourceMediums(params) {
    return this.get("/api/v1/source_mediums", params);
  },

  // supported params:
  // { date, previous_date, interval_count, interval, per, page, source_medium, search_mode }
  sourceMediumComparisons(params) {
    return this.get("/api/v1/source_medium_comparisons", params);
  },

  // params:
  // { date, interval_count, interval, per, page, referral, search_mode }
  referrals(params) {
    return this.get("/api/v1/referrals", params);
  },

  // params:
  // { date, previous_date, interval_count, interval, per, page, referral, search_mode }
  referralComparisons(params) {
    return this.get("/api/v1/referral_comparisons", params);
  },

  // params:
  // { date, interval_count, interval, per, page, country, search_mode }
  countries(params) {
    return this.get("/api/v1/countries", params);
  },

  // params:
  // { date, previous_date, interval_count, interval, per, page, country, search_mode }
  countryComparisons(params) {
    return this.get("/api/v1/country_comparisons", params);
  },

  // params:
  // { date, interval_count, interval, per, page, device, search_mode }
  devices(params) {
    return this.get("/api/v1/devices", params);
  },

  // params:
  // { date, previous_date, interval_count, interval, per, page, device, search_mode }
  deviceComparisons(params) {
    return this.get("/api/v1/device_comparisons", params);
  },

  // params:
  // { date, interval_count, interval, per, page, operating_system, search_mode }
  operatingSystems(params) {
    return this.get("/api/v1/operating_systems", params);
  },

  // params:
  // { date, previous_date, interval_count, interval, per, page, operating_system, search_mode }
  operatingSystemComparisons(params) {
    return this.get("/api/v1/operating_system_comparisons", params);
  },

  // params:
  // { date, interval_count, interval, per, page, browser, search_mode }
  browsers(params) {
    return this.get("/api/v1/browsers", params);
  },

  // params:
  // { date, previous_date, interval_count, interval, per, page, browser, search_mode }
  browserComparisons(params) {
    return this.get("/api/v1/browser_comparisons", params);
  },

  // params:
  // { date, interval_count, interval, per, page, traffic_channel, search_mode }
  trafficChannels(params) {
    return this.get("/api/v1/traffic_channels", params);
  },

  // params:
  // { date, previous_date, interval_count, interval, per, page, traffic_channel, search_mode }
  trafficChannelComparisons(params) {
    return this.get("/api/v1/traffic_channel_comparisons", params);
  },

  // params:
  // { date, interval_count, interval }
  userCompositions(params) {
    return this.get("/api/v1/user_compositions", params);
  },

  // params:
  // { date, previous_date, interval_count, interval }
  userCompositionComparisons(params) {
    return this.get("/api/v1/user_composition_comparisons", params);
  },

  // params:
  // { plan_key }
  checkout(params) {
    return this.post("/checkouts", params);
  },

  get(path, params) {
    const opts = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-DEMO": window.isDemo
      },
    };

    return fetch(path + "?" + new URLSearchParams(params), opts).then(
      this.unwrap
    );
  },

  post(path, params) {
    const opts = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrf(),
        "X-DEMO": window.isDemo
      },
      body: JSON.stringify(params),
    };

    return fetch(path, opts).then(this.unwrap);
  },

  patch(path, params) {
    const opts = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrf(),
        "X-DEMO": window.isDemo
      },
      body: JSON.stringify(params),
    };

    return fetch(path, opts).then(this.unwrap);
  },

  delete(path, params) {
    const opts = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": csrf(),
        "X-DEMO": window.isDemo
      },
      body: JSON.stringify(params),
    };

    return fetch(path, opts).then(this.unwrap);
  },

  unwrap(response) {
    if (!response.ok) {
      const error = new Error(response.statusText);
      error.response = response.json();
      throw error;
    }

    return response.json();
  },
};

export { client };
