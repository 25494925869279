import React, { useState } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { styled } from "../stitches.config";
import SnowflakeConnectionForm from "./snowflakeConnectionForm";
import PostgresConnectionForm from "./postgresConnectionForm";
import RedshiftConnectionForm from "./redshiftConnectionForm";
import BigQueryConnectionForm from "./bigQueryConnectionForm";

import { Title, Subtitle, Paragraph } from "../settings/layout";

export default function EditWarehouseConnection({ source, org }) {
  console.log({ source })
  return (
    <div>
      <Title>Warehouse Connection</Title>
      <Paragraph>
        Add your warehouse connection details to pull data.{" "}
      </Paragraph>

      <hr />

      <Subtitle>Warehouse</Subtitle>

      <TabsRoot defaultValue={source?.source_key || "postgres"}>
        <TabsList>
          <TabsItem value="postgres">
            <Logo src="/logos/postgresql.png" />
          </TabsItem>
          <TabsItem value="snowflake">
            <Logo src="/logos/snowflake.png" />
          </TabsItem>
          <TabsItem value="redshift">
            <Logo src="/logos/amazon.png" />
          </TabsItem>
          <TabsItem value="bigquery">
            <Logo src="/logos/google.png" />
          </TabsItem>
        </TabsList>

        <TabsContent value="snowflake">
          <hr />
          <Subtitle>Connection Details</Subtitle>
          <Connection>
            <SnowflakeConnectionForm source={source} org={org} />
          </Connection>
        </TabsContent>

        <TabsContent value="postgres">
          <hr />
          <Subtitle>Connection Details</Subtitle>
          <Connection>
            <PostgresConnectionForm source={source} org={org} />
          </Connection>
        </TabsContent>

        <TabsContent value="redshift">
          <hr />
          <Subtitle>Connection Details</Subtitle>
          <Connection>
            <RedshiftConnectionForm source={source} org={org} />
          </Connection>
        </TabsContent>

        <TabsContent value="bigquery">
          <hr />
          <Subtitle>Connection Details</Subtitle>
          <Connection>
            <BigQueryConnectionForm source={source} org={org} />
          </Connection>
        </TabsContent>
      </TabsRoot>
    </div>
  );
}

const TabsRoot = styled(TabsPrimitive.Root, {});

const TabsList = styled(TabsPrimitive.List, {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "1rem",
  width: "100%",
});

const TabsItem = styled(TabsPrimitive.Trigger, {
  background: "transparent",
  border: "1px solid $slate4",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "1.5rem 2.5rem",
  position: "relative",
  transition: "$transitionShort",

  "&:hover": {
    borderColor: "$blue6",
  },

  "&[data-state=active]": {
    borderColor: "$blue9",
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: "not-allowed",

        "&:hover": {
          borderColor: "$slate4",
        },
      },
    },
  },
});

const TabsContent = styled(TabsPrimitive.Content, {});

const Logo = styled("img", {
  height: "2.25rem",
});

const Connection = styled("div", {});
