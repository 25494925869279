import React from "react";

import Indicator from "../indicator";
import { Flex } from "../flex";
import { Text } from "../text";

export function CurrentPeriod() {
  return (
    <Flex align="center" gap="1">
      <Indicator kind="current" />
      <Text kind="period">Current Period</Text>
    </Flex>
  );
}

export function PreviousPeriod() {
  return (
    <Flex align="center" gap="1">
      <Indicator kind="previous" />
      <Text kind="period">Previous Period</Text>
    </Flex>
  );
}

export function Periods() {
  return (
    <Flex justify="between" align="center">
      {CurrentPeriod()}
      {PreviousPeriod()}
    </Flex>
  );
}
