import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";

import DemoLayout from "../demoLayout";

import { styled } from "../stitches.config";
import Audience from "../dashboard/audience";
import Acquisition from "../dashboard/acquisition";
import UserComposition from "../dashboard/userComposition";
import Overview from "../dashboard/overview";
import Pages from "../dashboard/pages";

import { periodAtom } from "../helpers/atoms";

export default function DemoDashboard(props) {
  const [period] = useAtom(periodAtom);

  useEffect(() => {
    window.isDemo = true;
  }, []);

  return (
    <DemoLayout {...props}>
      {period && (
        <DashboardLayout>
          <Overview />

          <SecondRow>
            <Audience />
            <Acquisition />
            <UserComposition />
          </SecondRow>

          <PagesWrapper>
            <Pages />
          </PagesWrapper>
        </DashboardLayout>
      )}
    </DemoLayout>
  );
}

const DashboardLayout = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
});

const SecondRow = styled("div", {
  display: "grid",
  borderCollapse: "collapse",
  gridTemplateColumns: "repeat(1, 1fr)",

  "@lg": {
    gridTemplateColumns: "repeat(2, 1fr)",

    "& > div": {
      border: "1px solid $slate4",
      borderBottom: "none",
      borderTop: "none",
    },

    "& > div:first-child": {
      borderLeft: "none",
    },

    "& > div:last-child": {
      borderRight: "none",
      gridColumn: "span 2",
    },

    "& > div:nth-child(2)": {
      borderLeft: "none",
      borderRight: "none",
    },
  },

  "@xl": {
    gridTemplateColumns: "repeat(3, 1fr)",

    "& > div:last-child": {
      gridColumn: "span 1",
    },
  },
});

const PagesWrapper = styled("div", {
  "@xl": { gridColumn: "1 / span 2" },
});
