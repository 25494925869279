import React, { useState } from "react";

import { styled } from "../stitches.config";
import InputLabel from "../inputLabel";
import InputField from "../inputField";
import Avatar from "../avatar";
import Button from "../button";

export default function AvatarUpload({ user }) {
  const [file, setFile] = useState("");

  function handleUpload(event) {
    setFile(event.target.files[0]);
  }

  return (
    <InputField>
      <InputLabel>Avatar</InputLabel>

      <AvatarField>
        <AvatarFieldContent>
          {!file ? (
            <Avatar user={user} />
          ) : (
            <Placeholder src={URL.createObjectURL(file)} alt={file.name} />
          )}

          <AvatarFieldText>
            {file
              ? file.name
              : user.photo_url && !file
              ? "Change your profile picture"
              : "Upload a profile picture"}
          </AvatarFieldText>
        </AvatarFieldContent>

        {!file ? (
          <Button kind="secondary" size="sm" as="label" htmlFor="user_photo">
            Browse
          </Button>
        ) : (
          <Button kind="secondary" size="sm" onClick={() => setFile("")}>
            Clear
          </Button>
        )}

        <input
          style={{ display: "none" }}
          onChange={handleUpload}
          type="file"
          name="user[photo]"
          id="user_photo"
        />
      </AvatarField>
    </InputField>
  );
}

const AvatarField = styled("div", {
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  border: "1px dashed $slate5",
  width: "100%",
  padding: "0.5rem",
  justifyContent: "space-between",
});

const AvatarFieldContent = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});

const AvatarFieldText = styled("span", {
  color: "$slate11",
  fontSize: "0.875rem",
  fontWeight: "400",
});

const AvatarInputLabel = styled("label", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0px",
  cursor: "pointer",
  fontSize: "0.875rem",
  height: "2.75rem",
  padding: "0.5rem 1rem",
  transition: "$transitionBase",
  pointerEvents: "all",
  background: "transparent",
  border: "1px solid $slate8",
  color: "$slate12",

  "&:hover": {
    border: "1px solid $slate10",
  },
});

const Placeholder = styled("img", {
  width: "2rem",
  height: "2rem",
  objectFit: "cover",
  borderRadius: "$round",
});
