import { CheckIcon } from "@radix-ui/react-icons";
import React from "react";
import { styled } from "./stitches.config";

export default function Organization({ org, current, type }) {
  const kinds = ["one", "two", "three", "four", "five", "six"];
  const kind = kinds[(org.org_id - 1) % kinds.length];

  return (
    <Wrapper type={type}>
      <Content>
        <Avatar kind={kind} />
        <Name>{org.org_name}</Name>
      </Content>

      {current && <CheckIcon />}
    </Wrapper>
  );
}

const Content = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});

const Avatar = styled("div", {
  width: "1.25rem",
  height: "1.25rem",
  borderRadius: "100%",

  variants: {
    kind: {
      one: {
        background:
          "linear-gradient(45deg, hsla(171, 87%, 67%, 1) 0%, hsla(236, 100%, 72%, 1) 100%)",
      },
      two: {
        background: "linear-gradient(132deg, #F4D03F 0%, #16A085 100%)",
      },
      three: {
        background:
          "linear-gradient(45deg, hsla(185, 64%, 51%, 1) 0%, hsla(277, 74%, 24%, 1) 100%)",
      },
      four: {
        background:
          "linear-gradient(45deg, hsla(210, 91%, 39%, 1) 0%, hsla(321, 55%, 50%, 1) 100%)",
      },
      five: {
        background: "linear-gradient(147deg, #FFE53B 0%, #FF2525 74%)",
      },
      six: {
        background:
          "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
      },
    },
  },
});

const Name = styled("h4", {
  color: "$slate12",
  fontSize: "0.875rem",
  fontWeight: "400",
});

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  cursor: "pointer",

  variants: {
    type: {
      default: {},
      trigger: {
        [`& ${Name}`]: {
          display: "none",

          "@md": {
            display: "block",
          },
        },
      },
    },
  },

  defaultVariants: {
    type: "default",
  },
});
