import React, { useState } from "react";
import Layout from "../../layout";
import SettingsNavigation from "../../settings/navigation";
import SettingsLayout, {
  Header,
  Title,
  Intro,
  Container,
  Content,
  List,
} from "../../settings/layout";
import { Dialog, DialogTrigger, DialogContent } from "../../dialog";
import Button from "../../button";
import SourceCard from "../../settings/sourceCard";
import EditWarehouseConnection from "../../settings/editWarehouseConnection";

export default function WarehouseConnection(props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Layout {...props}>
      <SettingsLayout>
        <SettingsNavigation active="warehouse" {...props} />

        <Container>
          <Header>
            <div>
              <Title>Warehouse Connections</Title>
              <Intro>Add your warehouse connection details to pull data.</Intro>
            </div>

            {(props.membership.role === "owner" ||
              props.membership.role === "admin") && (
              <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogTrigger asChild>
                  {props.source?.id === null && <Button>Add Connection</Button>}
                </DialogTrigger>

                <DialogContent>
                  <EditWarehouseConnection
                    setDialogOpen={setDialogOpen}
                    source={props.source}
                    org={props.org}
                  />
                </DialogContent>
              </Dialog>
            )}
          </Header>

          <Content>
            <List>
              <Intro>Connections</Intro>
              {props.source?.id !== null ? (
                <SourceCard
                  membership={props.membership}
                  source={props.source}
                  org={props.org}
                />
              ) : (
                <div>No Source</div>
              )}
            </List>
          </Content>
        </Container>
      </SettingsLayout>
    </Layout>
  );
}
