import React, { useState } from "react";
import { styled } from "../../stitches.config";
import Layout from "../../layout";
import SettingsNavigation from "../../settings/navigation";
import SettingsLayout, {
  Header,
  Title,
  Intro,
  Container,
  Content,
} from "../../settings/layout";
import { Dialog, DialogTrigger, DialogContent } from "../../dialog";
import Button from "../../button";

export default function Organization(props) {
  const { org } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Layout {...props}>
      <SettingsLayout>
        <SettingsNavigation active="organization" {...props} />

        <Container>
          <Header>
            <div>
              <Title>Organization</Title>
              <Intro>Your organization information.</Intro>
            </div>
          </Header>
          <Content>
            <div>
              <Intro>Name:</Intro>
              <Text>{org.name}</Text>
            </div>
          </Content>
        </Container>
      </SettingsLayout>
    </Layout>
  );
}

const Text = styled("p", {
  color: "$slate11",
});
