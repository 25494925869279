import React from "react";

function IconAcquisition() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00098 2.00081L3.00098 0.66748H2.00098L2.00098 15.3341H3.00098L3.00098 14.0008H14.0003V10.6675L3.00098 10.6675V9.66748H10.3337V6.33415H3.00098V5.33415L6.66699 5.33415V2.00081L3.00098 2.00081Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconAcquisition;
