import React, { useState } from "react";
import { CubeIcon, DotsVerticalIcon } from "@radix-ui/react-icons";

import { styled } from "../stitches.config";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverGroup,
  PopoverItem,
} from "../popover";
import { Dialog, DialogTrigger, DialogContent } from "../dialog";
import Button from "../button";

import { Card, Content, Info, Actions, Action } from "./card";
import EditWarehouseConnection from "./editWarehouseConnection";

export default function SourceCard({ source, membership, org }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Card>
      <Content>
        <Icon>
          <CubeIcon />
        </Icon>
        <div>
          <Title>{source.name}</Title>
          <Info>{source.source_key}</Info>
        </div>
      </Content>

      {(membership.role === "owner" || membership.role === "admin") && (
        <Actions>
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
              <Button kind="secondary">Edit Connection</Button>
            </DialogTrigger>

            <DialogContent>
              <EditWarehouseConnection
                setDialogOpen={setDialogOpen}
                source={source}
                org={org}
              />
            </DialogContent>
          </Dialog>

          <Popover>
            <PopoverTrigger asChild>
              <Action>
                <DotsVerticalIcon width={18} height={18} />
              </Action>
            </PopoverTrigger>

            <PopoverContent align="end" side="top">
              <PopoverGroup>
                <PopoverItem href="#remove" kind="danger">
                  Remove Connection
                </PopoverItem>
              </PopoverGroup>
            </PopoverContent>
          </Popover>
        </Actions>
      )}
    </Card>
  );
}

const Title = styled("h4", {
  fontSize: "1rem",
  color: "$slate12",
  marginBottom: "0.25rem",
});

const Icon = styled("div", {
  width: "2.5rem",
  height: "2.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  color: "$slate11",
  fontSize: "0.75rem",
  lineHeight: 1,
  fontWeight: 500,
  border: "1px solid $slate8",
  borderRadius: "100%",
});
