import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { ResponsivePie } from "@nivo/pie";
import { animated } from "@react-spring/web";
import { blue, slate } from "@radix-ui/colors";

import { styled } from "../stitches.config";
import { periodAtom, periodCompareAtom } from "../helpers/atoms";

import Indicator from "../indicator";
import { Flex } from "../flex";
import { client } from "../../utils/client";
import {
  formatNumber,
  formatPercent,
  displayDifference,
  displayPercentageChange,
} from "../helpers/formatters";

export default function UserCompositionCompare() {
  const [period] = useAtom(periodAtom);
  const [periodCompare] = useAtom(periodCompareAtom);

  const [data, setData] = useState();

  useEffect(() => {
    const fetchParams = {
      date: period.date,
      previous_date: periodCompare.date,
      interval_count: period.intervalCount,
      interval: period.interval,
    };

    client.userCompositionComparisons(fetchParams).then((r) => {
      setData(r);
    });
  }, [period.date, period.intervalCount, period.interval, periodCompare.date]);

  const newUsers = data?.new_users;
  const returningUsers = data?.returning_users;
  const newUsersPercentage = Math.round((data?.new_users / data?.users) * 100);
  const returningUsersPercentage = Math.round(
    (data?.returning_users / data?.users) * 100
  );

  const previousNewUsers = data?.previous.new_users;
  const previousReturningUsers = data?.previous.returning_users;
  const previousNewUsersPercentage = Math.round(
    (data?.previous.new_users / data?.previous.users) * 100
  );
  const previousReturningUsersPercentage = Math.round(
    (data?.previous.returning_users / data?.previous.users) * 100
  );

  const differenceNewUsers = newUsers - previousNewUsers;
  const differenceReturningUsers = returningUsers - previousReturningUsers;
  const differenceNewUsersPercentage = Math.round(
    (differenceNewUsers / previousNewUsers) * 100
  );
  const differenceReturningUsersPercentage = Math.round(
    (differenceReturningUsers / previousReturningUsers) * 100
  );

  const pieData = [
    {
      id: "new",
      label: "New Users",
      value: newUsers,
      color: blue.blue3,
      textColor: blue.blue12,
      percentage: newUsersPercentage,
    },
    {
      id: "returning",
      label: "Returning Users",
      value: returningUsers,
      color: blue.blue10,
      textColor: blue.blue1,
      percentage: returningUsersPercentage,
    },
  ];

  const previousPieData = [
    {
      id: "new",
      label: "New Users",
      value: previousNewUsers,
      color: slate.slate3,
      textColor: slate.slate12,
      percentage: previousNewUsersPercentage,
    },
    {
      id: "returning",
      label: "Returning Users",
      value: previousReturningUsers,
      color: slate.slate10,
      textColor: slate.slate1,
      percentage: previousReturningUsersPercentage,
    },
  ];

  return (
    <Container>
      <Header>
        <Title>New vs Returning Users</Title>
      </Header>

      <Content>
        <PieCharts>
          <div>
            <Text kind="title">Current Period</Text>

            <PieWrapper>
              <ResponsivePie
                animate={true}
                arcLabel="value"
                arcLabelsTextColor={slate.slate12}
                arcLabelsSkipAngle={20}
                arcLabelsRadiusOffset={0.65}
                arcLabelsComponent={({ datum, label, style }) => {
                  return (
                    <animated.g
                      transform={style.transform}
                      style={{ pointerEvents: "none" }}
                    >
                      <LabelText
                        textAnchor="middle"
                        dominantBaseline="central"
                        fill={datum.data.textColor}
                      >
                        {datum.data.percentage}%
                      </LabelText>
                    </animated.g>
                  );
                }}
                arcLinkLabel="label"
                valueFormat={(value) => formatNumber(value)}
                arcLinkLabelsColor={{ from: "color" }}
                arcLinkLabelsTextColor={slate.slate12}
                arcLinkLabelsThickness={1}
                activeOuterRadiusOffset={8}
                activeInnerRadiusOffset={16}
                colors={{ datum: "data.color" }}
                data={pieData}
                enableArcLabels={true}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                padAngle={1}
              />
            </PieWrapper>
          </div>

          <div>
            <Text kind="title">Previous Period</Text>

            <PieWrapper>
              <ResponsivePie
                animate={true}
                arcLabel="value"
                arcLabelsTextColor={slate.slate12}
                arcLabelsSkipAngle={20}
                arcLabelsRadiusOffset={0.65}
                arcLabelsComponent={({ datum, label, style }) => {
                  return (
                    <animated.g
                      transform={style.transform}
                      style={{ pointerEvents: "none" }}
                    >
                      <LabelText
                        textAnchor="middle"
                        dominantBaseline="central"
                        fill={datum.data.textColor}
                      >
                        {datum.data.percentage}%
                      </LabelText>
                    </animated.g>
                  );
                }}
                arcLinkLabel="label"
                valueFormat={(value) => formatNumber(value)}
                arcLinkLabelsColor={{ from: "color" }}
                arcLinkLabelsTextColor={slate.slate12}
                arcLinkLabelsThickness={1}
                activeOuterRadiusOffset={8}
                activeInnerRadiusOffset={16}
                colors={{ datum: "data.color" }}
                data={previousPieData}
                enableArcLabels={true}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                padAngle={1}
              />
            </PieWrapper>
          </div>
        </PieCharts>

        <PieLegend>
          <Row>
            <Flex justify="between">
              <Text kind="name">New Users</Text>

              <Flex gap="xxs" align="end">
                {displayDifference(differenceNewUsers)}
                {displayPercentageChange(differenceNewUsersPercentage)}
              </Flex>
            </Flex>

            <Flex justify="between" align="center">
              <Flex align="center" gap="1">
                <Indicator kind="currentNew" />
                <Text kind="period">Current Period</Text>
              </Flex>

              <Flex gap="xxs" align="end">
                <Text kind="count">{formatNumber(newUsers)}</Text>
                <Text kind="percent">
                  ({formatPercent(newUsersPercentage)})
                </Text>
              </Flex>
            </Flex>

            <Flex justify="between" align="center">
              <Flex align="center" gap="1">
                <Indicator kind="previousNew" />
                <Text kind="period">Previous Period</Text>
              </Flex>

              <Flex gap="xxs" align="end">
                <Text kind="count">
                  {previousNewUsers ? formatNumber(previousNewUsers) : "—"}
                </Text>
                <Text kind="percent">
                  {previousNewUsersPercentage
                    ? "(" + formatPercent(previousNewUsersPercentage) + ")"
                    : "—"}
                </Text>
              </Flex>
            </Flex>
          </Row>

          <Row>
            <Flex justify="between">
              <Text kind="name">Returning Users</Text>

              <Flex gap="xxs" align="end">
                {displayDifference(differenceReturningUsers)}
                {displayPercentageChange(differenceReturningUsersPercentage)}
              </Flex>
            </Flex>

            <Flex justify="between" align="center">
              <Flex align="center" gap="1">
                <Indicator kind="currentReturning" />
                <Text kind="period">Current Period</Text>
              </Flex>

              <Flex gap="xxs" align="end">
                <Text kind="count">{formatNumber(returningUsers)}</Text>
                <Text kind="percent">
                  ({formatPercent(returningUsersPercentage)})
                </Text>
              </Flex>
            </Flex>

            <Flex justify="between" align="center">
              <Flex align="center" gap="1">
                <Indicator kind="previousReturning" />
                <Text kind="period">Previous Period</Text>
              </Flex>

              <Flex gap="xxs" align="end">
                <Text kind="count">
                  {previousReturningUsers
                    ? formatNumber(previousReturningUsers)
                    : "—"}
                </Text>
                <Text kind="percent">
                  {previousReturningUsersPercentage
                    ? "(" +
                      formatPercent(previousReturningUsersPercentage) +
                      ")"
                    : "—"}
                </Text>
              </Flex>
            </Flex>
          </Row>
        </PieLegend>
      </Content>
    </Container>
  );
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div", {
  display: "flex",
  padding: "$5",
  borderBottom: "1px solid $slate4",

  "@lg": {
    padding: "$5 $10",
  },
});

const Title = styled("h3", {
  fontSize: "1.25rem",
});

const PieCharts = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",

  "@md": {
    gridTemplateColumns: "1fr 1fr",
  },
});

const PieWrapper = styled("div", {
  height: "100px",
  marginBottom: "1rem",

  "@md": {
    height: "1200px",
  },

  "@lg": {
    height: "240px",
  },
});

const Text = styled("div", {
  variants: {
    kind: {
      title: {
        fontWeight: 500,
        color: "$slate12",
        marginBottom: "0.25rem",
        fontSize: "0.875rem",
        textAlign: "center",
      },
      name: {
        color: "$slate12",
        fontSize: "0.875rem",
      },
      period: {
        color: "$slate11",
        fontSize: "0.75rem",
      },
      count: {
        color: "$slate12",
        fontSize: "0.75rem",
      },
      percent: {
        color: "$slate11",
        fontSize: "0.675rem",
        textAlign: "right",
        width: "3.25rem",
      },
    },
  },
});

const Row = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  borderBottom: "1px solid $slate4",
  padding: "$2 0",

  "&:last-child": {
    borderBottom: "none",
  },

  "@lg": {
    padding: "$5 0",
  },
});

const Content = styled("div", {
  padding: "$2 $5",

  "@lg": {
    padding: "$7 $10",
  },
});

const PieLegend = styled("div", {
  display: "flex",
  position: "relative",
  justifyContent: "center",
  flexDirection: "column",
});

const LabelText = styled("text", {
  fontSize: "0.875rem",
  fontWeight: "500",
});
