import React, { useRef, useState } from "react"
import { keyframes } from '@stitches/react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { styled } from "./stitches.config"

export default function Flash({ messages }) {
  const eventDateRef = React.useRef(new Date());

  function prettyDate(date) {
    return new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(date);
  }

  return (
    <ToastPrimitive.ToastProvider duration="5000" label="flash" swipeDirection="right">
      {messages.map((message, index) =>
        <StyledToast key={index}>
          <StyledTitle>{message.title}</StyledTitle>

          <StyledDescription asChild>
            <time dateTime={eventDateRef.current.toISOString()}>
              {prettyDate(eventDateRef.current)}
            </time>
          </StyledDescription>
        </StyledToast>
      )}
      <ToastViewport />
    </ToastPrimitive.ToastProvider>
  )
}


const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + 1rem))` },
  to: { transform: 'translateX(0)' },
});

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + 1rem))` },
});

const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: "2rem",
  gap: "0.5rem",
  width: "25rem",
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: "$zToast",
  outline: 'none',
});

const StyledToast = styled(ToastPrimitive.Root, {
  backgroundColor: 'white',
  borderRadius: "0px",
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 40px -10px, hsl(206 22% 7% / 20%) 0px 10px 24px -15px',
  padding: "1rem",
  display: 'grid',
  gridTemplateAreas: '"title action" "description action"',
  gridTemplateColumns: 'auto max-content',
  columnGap: "1rem",
  alignItems: 'center',

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },
});

const StyledTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
  marginBottom: "0.25rem",
  fontWeight: 500,
  color: "$slate12",
  fontSize: "1rem",
});

const StyledDescription = styled(ToastPrimitive.Description, {
  gridArea: 'description',
  margin: 0,
  color: "$slate11",
  fontSize: "0.75rem",
  lineHeight: 1.5,
});
