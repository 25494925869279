import React from "react"
import * as LabelPrimitive from '@radix-ui/react-label';

import { styled } from "./stitches.config"

export default function Label({ children, htmlFor, ...props }) {
  return (
    <Wrapper htmlFor={htmlFor}  {...props}>{children}</Wrapper>
  )
}

const Wrapper = styled(LabelPrimitive.Root, {
  color: "$slate12",
  fontSize: "0.875rem",
})
