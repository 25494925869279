import React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { styled } from "./stitches.config";
import { PersonIcon } from "@radix-ui/react-icons";

export default function Avatar({ user, size, hoverable }) {
  const { photo_url, name, email } = user;

  return (
    <StyledAvatar size={size}>
      <StyledImage src={photo_url} />
      <StyledFallback hoverable={hoverable ? true : false}>
        <PersonIcon />
      </StyledFallback>
    </StyledAvatar>
  );
}

const StyledAvatar = styled(AvatarPrimitive.Root, {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  verticalAlign: "middle",
  overflow: "hidden",
  userSelect: "none",
  borderRadius: "100%",
  backgroundColor: "$slate3",

  variants: {
    size: {
      sm: {
        width: "1.5rem",
        height: "1.5rem",
      },
      md: {
        width: "2rem",
        height: "2rem",
      },
      lg: {
        width: "2.5rem",
        height: "2.5rem",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

const StyledImage = styled(AvatarPrimitive.Image, {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "inherit",
});

const StyledFallback = styled(AvatarPrimitive.Fallback, {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  color: "$slate11",
  fontSize: "0.75rem",
  lineHeight: 1,
  fontWeight: 500,
  border: "1px solid $slate8",
  borderRadius: "100%",

  variants: {
    hoverable: {
      true: {
        "&:hover": {
          border: "1px solid $slate11",
        },
      },
      false: {},
    },
  },
});
