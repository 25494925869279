import React, { useState } from "react";
import { useAtom } from "jotai";
import { CalendarIcon, CaretSortIcon } from "@radix-ui/react-icons";

import { styled } from "../stitches.config";
import { Flex } from "../flex";
import { Text } from "../text";
import { periodAtom, periodPanelAtom } from "../helpers/atoms";

import PeriodPanelContent from "./periodPanelContent";
import { getPeriodRange } from "./periodHelpers";
import PeriodComparison from "./periodComparison";

export default function PeriodPanel() {
  const [period, setPeriod] = useAtom(periodAtom);
  const [periodPanel, setPeriodPanel] = useAtom(periodPanelAtom);
  const periodRange = getPeriodRange(period.date, period.intervalCount);

  const [currentPeriod, setCurrentPeriod] = useState();

  function handleToggle() {
    document
      .getElementById("body-container")
      .classList.toggle("period-panel-open");
    setPeriodPanel(!periodPanel);
  }

  return (
    <>
      <Wrapper>
        <PeriodComparison />

        <Trigger onClick={() => handleToggle()}>
          <Flex gap="1">
            <CalendarIcon />

            <Content direction="column" gap="05">
              <Text kind="name">{currentPeriod}</Text>
              <Text kind="range">{periodRange}</Text>
            </Content>
          </Flex>

          <Icon>
            <CaretSortIcon />
          </Icon>
        </Trigger>
      </Wrapper>

      <Panel isOpen={periodPanel}>
        <PeriodPanelContent
          handleToggle={handleToggle}
          currentPeriod={currentPeriod}
          setCurrentPeriod={setCurrentPeriod}
        />
      </Panel>
    </>
  );
}

const Wrapper = styled("div", {
  position: "relative",
  height: "100%",
});

const Icon = styled("div", {
  padding: "0.25rem",
  display: "flex",
  color: "$slate11",
  transition: "$transitionBase",
});

const Trigger = styled("button", {
  all: "unset",
  background: "white",
  boxSizing: "border-box",
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: "pointer",
  border: "1px solid transparent",
  borderLeft: "1px solid $slate4!important",
  padding: "0.5rem 0.75rem",
  position: "relative",
  height: "100%",
  width: "5rem",
  zIndex: 5,

  "@sm": {
    flexDirection: "row",
  },

  "@mg": {
    padding: "0.5rem 1rem",
    width: "15rem",
  },

  "&:hover": {
    [`${Icon}`]: {
      color: "$slate12",
      backgroundColor: "$slate3",
    },
  },
});

const Content = styled(Flex, {
  display: "none",

  "@mg": {
    display: "flex",
  },
});

const Panel = styled("div", {
  position: "fixed",
  top: "4rem",
  right: 0,
  width: "100vw",
  height: "calc(100vh - 4rem)",
  backgroundColor: "white",
  borderLeft: "1px solid $slate3",
  transition: "transform 0.2s ease-in-out",

  "@sm": { width: "368px" },

  variants: {
    isOpen: {
      true: {
        transform: "translateX(0)",
      },
      false: {
        transform: "translateX(100%)",
      },
    },
  },
});
