import React from "react";
import {
  DragHandleDots2Icon,
  LayoutIcon,
  EyeOpenIcon,
  EyeClosedIcon,
} from "@radix-ui/react-icons";
import { Reorder } from "framer-motion";

import { styled } from "../stitches.config";
import Button from "../button";
import InputLabel from "../inputLabel";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverGroup,
  PopoverItem,
  PopoverSubtitle,
} from "../popover";

export default function ControlsColumn({
  columns,
  columnOrder,
  setColumnOrder,
  handleColumnVisibility,
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="sm" kind="action">
          <LayoutIcon />
          Columns
        </Button>
      </PopoverTrigger>

      <PopoverContent align="end">
        <PopoverGroup kind="tableColumns">
          <PopoverSubtitle>Columns</PopoverSubtitle>

          <Reorder.Group
            axis="y"
            values={columnOrder}
            onReorder={setColumnOrder}
          >
            {columnOrder.map((columnId) => {
              const column = columns.find((c) => c.id === columnId);

              return (
                <ReorderItem key={columnId} value={columnId}>
                  <DragHandleDots2Icon />

                  <ColumnsItem onClick={() => handleColumnVisibility(columnId)}>
                    <InputLabel>{column.name}</InputLabel>

                    <ColumnVisibilityToggle>
                      {column.visible ? <EyeOpenIcon /> : <EyeClosedIcon />}
                    </ColumnVisibilityToggle>
                  </ColumnsItem>
                </ReorderItem>
              );
            })}
          </Reorder.Group>
        </PopoverGroup>
      </PopoverContent>
    </Popover>
  );
}

const ReorderItem = styled(Reorder.Item, {
  display: "flex",
  gap: "0.5rem",
  padding: "0.25rem 0.5rem",
  textDecoration: "none",
  cursor: "pointer",
  width: "100%",
  alignItems: "center",

  "&:hover": {
    color: "$slate12",
    backgroundColor: "$slate2",
  },
});

const ColumnsItem = styled("div", {
  display: "flex",
  gap: "0.5rem",
  cursor: "pointer",
  paddingRight: "0.25rem",
  width: "100%",
  justifyContent: "space-between",
});

const ColumnVisibilityToggle = styled("button", {
  appearance: "none",
  all: "unset",
});
