import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { ResponsivePie } from "@nivo/pie";
import { animated } from "@react-spring/web";
import { blue, slate } from "@radix-ui/colors";

import { styled } from "../stitches.config";
import {
  periodAtom,
  periodCompareAtom,
  periodPanelAtom,
} from "../helpers/atoms";

import { client } from "../../utils/client";
import { formatNumber, formatPercent } from "../helpers/formatters";

export default function UserCompositionSingle() {
  const [period] = useAtom(periodAtom);
  const [periodCompare] = useAtom(periodCompareAtom);
  const [periodPanel] = useAtom(periodPanelAtom);

  const [newUsers, setNewUsers] = useState();
  const [returningUsers, setReturningUsers] = useState();
  const [newUsersPercentage, setNewUsersPercentage] = useState();
  const [returningUsersPercentage, setReturningUsersPercentage] = useState();

  useEffect(() => {
    const fetchParams = {
      date: period.date,
      interval_count: period.intervalCount,
      interval: period.interval,
    };

    client.userCompositions(fetchParams).then((r) => {
      setNewUsers(r.new_users);
      setReturningUsers(r.returning_users);
      setNewUsersPercentage(Math.round((r.new_users / r.users) * 100));
      setReturningUsersPercentage(
        Math.round((r.returning_users / r.users) * 100)
      );
    });
  }, [period.date, period.intervalCount, period.interval]);

  const pieData = [
    {
      id: "new",
      label: "New Users",
      value: newUsers,
      color: blue.blue3,
      textColor: blue.blue12,
      percentage: newUsersPercentage,
    },
    {
      id: "returning",
      label: "Returning Users",
      value: returningUsers,
      color: blue.blue10,
      textColor: blue.blue1,
      percentage: returningUsersPercentage,
    },
  ];

  return (
    <Container>
      <Header>
        <Title>New vs Returning Users</Title>
      </Header>

      <Content>
        <PieWrapper panel={periodPanel}>
          <ResponsivePie
            animate={true}
            arcLabel="value"
            arcLabelsTextColor={slate.slate12}
            arcLabelsSkipAngle={15}
            arcLabelsRadiusOffset={0.65}
            arcLabelsComponent={({ datum, label, style }) => {
              return (
                <animated.g
                  transform={style.transform}
                  style={{ pointerEvents: "none" }}
                >
                  <LabelText
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={datum.data.textColor}
                  >
                    {datum.data.percentage}%
                  </LabelText>
                </animated.g>
              );
            }}
            arcLinkLabel="label"
            valueFormat={(value) => formatNumber(value)}
            arcLinkLabelsColor={{ from: "color" }}
            arcLinkLabelsTextColor={slate.slate12}
            arcLinkLabelsThickness={1}
            activeOuterRadiusOffset={8}
            activeInnerRadiusOffset={16}
            colors={{ datum: "data.color" }}
            data={pieData}
            enableArcLabels={true}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            padAngle={1}
          />
        </PieWrapper>

        <PieLegend>
          {pieData.map((data, index) => (
            <PieItem key={index}>
              <PieItemColor style={{ backgroundColor: data.color }} />
              <PieItemLabel>{data.label}</PieItemLabel>
              <PieItemValue>{formatNumber(data.value)}</PieItemValue>
              <PieItemValue kind="percentage">
                ({formatPercent(data.percentage)})
              </PieItemValue>
            </PieItem>
          ))}
        </PieLegend>
      </Content>
    </Container>
  );
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div", {
  display: "flex",
  padding: "$5",
  borderBottom: "1px solid $slate4",

  "@lg": {
    padding: "$5 $10",
  },
});

const Title = styled("h3", {
  fontSize: "1.25rem",
});

const PieWrapper = styled("div", {
  margin: "0 auto 1rem",
  height: "280px",

  "@md": {
    height: "320px",
  },

  variants: {
    panel: {
      true: {
        "@lg": {
          height: "300px",
          width: "300px",
        },
      },
      false: {
        "@lg": {
          height: "320px",
          width: "320px",
        },
        "@xxl": {
          height: "400px",
          width: "400px",
        },
      },
    },
  },
});

const Content = styled("div", {
  padding: "$2 $5",

  "@lg": {
    padding: "$4 $10",
  },
});

const PieLegend = styled("div", {
  display: "flex",
  position: "relative",
  justifyContent: "center",
  flexDirection: "column",
});

const PieItem = styled("div", {
  alignItems: "center",
  display: "grid",
  gridTemplateColumns: "1rem 2fr 5rem 2.5rem",
  gap: "0.5rem",
  justifyContent: "space-between",
  padding: "0.75rem 0",
  borderBottom: "1px solid $slate4",

  "&:last-child": {
    borderBottom: "none",
  },
});

const PieItemColor = styled("div", {
  height: "1rem",
  width: "1rem",
});

const PieItemLabel = styled("div", {
  color: "$slate12",
  fontSize: "0.875rem",
});

const PieItemValue = styled("div", {
  textAlign: "right",

  variants: {
    kind: {
      percentage: {
        fontWeight: "400",
        color: "$slate11",
        fontSize: "0.75rem",
      },

      value: {
        fontSize: "0.875rem",
        fontWeight: "500",
        color: "$slate12",
      },
    },
  },

  defaultVariants: {
    kind: "value",
  },
});

const LabelText = styled("text", {
  fontSize: "0.875rem",
  fontWeight: "500",
});
