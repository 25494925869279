import React from "react";

import { styled } from "./stitches.config";

export default React.forwardRef(({ children, state, ...props }, ref) => (
  <Wrapper ref={ref} {...props}>
    {children}
  </Wrapper>
));

const Wrapper = styled("button", {
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  justifyContent: "center",
  borderRadius: "0px",
  cursor: "pointer",
  fontSize: "0.875rem",
  transition: "$transitionShort",
  textDecoration: "none",
  pointerEvents: "all",
  whiteSpace: "nowrap",

  variants: {
    kind: {
      unset: {
        background: "transparent",
        border: "none",
        padding: "0",
      },
      primary: {
        background: "$slate12",
        border: "none",
        color: "white",

        "&:hover": {
          background: "$slate12",
        },
      },
      secondary: {
        background: "transparent",
        border: "1px solid $slate7",
        color: "$slate12",

        "&:hover": {
          border: "1px solid $slate10",
        },
      },
      action: {
        background: "transparent",
        border: "none",
        color: "$slate11",

        "&:hover": {
          color: "$slate12",
          background: "$slate2",
        },
      },
    },

    size: {
      none: { height: "auto", padding: "0" },
      sm: { height: "2rem", padding: "0.25rem 0.5rem" },
      md: { height: "2.75rem", padding: "0.5rem 1rem" },
    },

    disabled: {
      true: {
        border: "1px solid $slate4",
        color: "$slate9",
        cursor: "not-allowed",
        pointerEvents: "none",

        "&:hover": {
          border: "1px solid $slate4",
          color: "$slate9",
          cursor: "not-allowed",
        },
      },
    },

    state: {
      disabled: {
        border: "1px solid $slate4",
        color: "$slate9",
        pointerEvents: "none",
        cursor: "not-allowed",
      },
      loading: {},
      active: {},
    },
  },

  defaultVariants: {
    kind: "primary",
    size: "md",
  },
});
