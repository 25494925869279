import dayjs from "dayjs";

export function getPeriodValue(date, intervalCount, interval) {
  const dateObj = dayjs(date);
  const periodEnd = dateObj.format("MMM D, YYYY");
  const periodValue = periodStart(date, intervalCount, interval).format("MMM D, YYYY") + " - " + periodEnd;
  return periodValue;
}

export function periodStart(date, intervalCount, interval) {
  const dateObj = dayjs(date);

  if (interval === "day") {
    return dateObj.subtract(intervalCount - 1, "day").tz("UTC")
  } else if (interval === "week") {
    return dateObj.subtract((intervalCount * 7) - 1, "day").tz("UTC")
  } else if (interval === "month") {
    return dateObj.startOf("month").subtract(intervalCount - 1, "month").tz("UTC")
  }
}

export function previousPeriodEnd(date, intervalCount, interval) {
  return periodStart(date, intervalCount, interval).subtract(1, "day")
}

export function yesterday(relativeDate) {
  return dayjs(relativeDate).subtract(1, "days");
}

export function endOfLastWeek(relativeDate) {
  // TODO: handle week start from source
  // if it's sunday, go back 1 week
  if (dayjs(relativeDate).day() === 0) {
    return dayjs(relativeDate).subtract(7, "days");
  } else {
    return dayjs(relativeDate).subtract(dayjs(relativeDate).day(), "days");
  }
}

export function endOfThisWeek(relativeDate) {
  // TODO: handle week start from source
  return endOfLastWeek(relativeDate).add(7, "days");
}

export function endOfLastMonth(relativeDate) {
  return dayjs(relativeDate).startOf("month").subtract(1, "days")
}

export function endOfThisMonth(relativeDate) {
  return dayjs(relativeDate).endOf("month")
}

