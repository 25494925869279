import React, { useState } from "react";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { client } from "../../utils/client";

import Avatar from "../avatar";
import { Select, SelectItem } from "../select";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  PopoverGroup,
  PopoverItem,
} from "../popover";

import { Card, Content, Info, Actions, Action } from "./card";
import { styled } from "../stitches.config";

export default function MembersCard(props) {
  const { org, membership, role } = props;

  const updateMember = (role) => {
    client
      .patch(`/orgs/${org.slug}/memberships/${membership.id}`, { role })
      .then((response) => {
        Turbo.clearCache();
        Turbo.visit(`/settings/${org.slug}/members`);
      })
      .catch((error) => {
        console.log({ error });
        Turbo.clearCache();
        Turbo.visit(`/settings/${org.slug}/members`);
        // TODO put up some flash message ?
      });
  };

  const deleteMember = () => {
    client
      .delete(`/orgs/${org.slug}/memberships/${membership.id}`)
      .then((response) => {
        Turbo.clearCache();
        Turbo.visit(`/settings/${org.slug}/members`);
      })
      .catch((error) => {
        console.log({ error });
        Turbo.clearCache();
        Turbo.visit(`/settings/${org.slug}/members`);
        // TODO put up some flash message ?
      });
  };

  return (
    <Card>
      <Content>
        <Avatar user={membership} size="lg" />
        <div>
          <Title>{membership.name}</Title>
          <Info>{membership.email}</Info>
        </div>
      </Content>

      <Actions>
        {membership.role === "owner" || role == "member" ? (
          <Role>{membership.role}</Role>
        ) : (
          <Select
            defaultValue={membership.role}
            onValueChange={(value) => updateMember(value)}
          >
            <SelectItem value="admin">Administrator</SelectItem>
            <SelectItem value="member">Member</SelectItem>
          </Select>
        )}

        {membership.role !== "owner" && role !== "member" && (
          <Popover>
            <PopoverTrigger>
              <Action>
                <DotsVerticalIcon width={18} height={18} />
              </Action>
            </PopoverTrigger>

            <PopoverContent align="end" side="top">
              <PopoverGroup>
                <PopoverItem
                  onClick={deleteMember}
                  href="#remove"
                  kind="danger"
                >
                  Remove Member
                </PopoverItem>
              </PopoverGroup>
            </PopoverContent>
          </Popover>
        )}
      </Actions>
    </Card>
  );
}

const Role = styled("div", {
  color: "$slate11",
  fontWeight: "400",
  fontSize: "0.875rem",
  textTransform: "capitalize",
});

const Title = styled("h4", {
  fontSize: "1rem",
  color: "$slate12",
  marginBottom: "0.25rem",
});
