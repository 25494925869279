import * as Form from "@radix-ui/react-form";

import { styled } from "./stitches.config";

const FormRoot = styled(Form.Root, {});
const FormField = styled(Form.Field, {
  width: "100%",
});
const FormLabel = styled(Form.Label, {
  color: "$slate12",
  display: "block",
  fontSize: "0.75rem",
  fontWeight: "400",
  marginBottom: "0.25rem",
});
const FormMessage = styled(Form.Message, {
  color: "$red11",
  fontSize: "0.75rem",
  fontWeight: "400",
  marginTop: "0.25rem",
});
const FormControl = styled(Form.Control, {});
const FormValidityState = styled(Form.ValidityState, {});
const FormSubmit = styled(Form.Submit, {});

export {
  FormRoot,
  FormField,
  FormLabel,
  FormMessage,
  FormControl,
  FormValidityState,
  FormSubmit,
};
