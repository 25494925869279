import React from "react";
import { styled } from "./stitches.config";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

const StyledGroup = styled(RadioGroupPrimitive.Root, {
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
});

const StyledItem = styled("div", {
  border: "1px solid $slate4",
  cursor: "pointer",
  display: "grid",
  gridTemplateColumns: "1.5rem 1fr",
  gap: "1rem",
  padding: "1rem",
});

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: "unset",
  backgroundColor: "$slate3",
  width: "1.5rem",
  height: "1.5rem",
  borderRadius: "100%",
  border: "1px solid transparent",

  "&:hover": {
    backgroundColor: "$slate4",
  },
  "&:focus": {
    boxShadow: `0 0 0 2px rgba(0, 0, 0, 0.25)`,
  },
  "&[data-state=checked]": {
    backgroundColor: "transparent",
    borderColor: "$slate12",
  },
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  position: "relative",

  "&::after": {
    content: '""',
    display: "block",
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    backgroundColor: "$slate12",
  },
});

const StyledLabel = styled("label", {
  alignItems: "flex-start",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
});

const StyledDescription = styled("p", {
  fontSize: "0.875rem",
  color: "$slate11",
  fontWeight: "400",
});

const StyledTitle = styled("h6", {
  fontSize: "1rem",
  color: "$slate12",
});

// Exports
export const RadioGroup = StyledGroup;
export const RadioItem = StyledItem;
export const RadioItemRadio = StyledRadio;
export const RadioItemIndicator = StyledIndicator;
export const RadioItemLabel = StyledLabel;
export const RadioItemDescription = StyledDescription;
export const RadioItemTitle = StyledTitle;
