import React from "react";
import { useAtom } from "jotai";
import { CountdownTimerIcon, Cross2Icon } from "@radix-ui/react-icons";

import { styled } from "../stitches.config";
import { Flex } from "../flex";
import Indicator from "../indicator";
import Button from "../button";

import { periodAtom, periodCompareAtom, compareAtom } from "../helpers/atoms";

import { getPeriodRange } from "./periodHelpers";

export default function PeriodComparison() {
  const [period] = useAtom(periodAtom);
  const [periodCompare] = useAtom(periodCompareAtom);
  const [compare, setCompare] = useAtom(compareAtom);

  const periodRange = getPeriodRange(period.date, period.intervalCount);
  const periodCompareRange = getPeriodRange(
    periodCompare.date,
    periodCompare.intervalCount
  );

  return (
    <Wrapper compare={periodCompare.active}>
      <MobileIcon />

      <Flex
        direction="column"
        gap="05"
        css={{ display: "none", "@lg": { display: "flex" } }}
      >
        <Title>Comparing</Title>

        <Flex align="center" gap="1">
          <Indicator size="sm" kind="current" />
          <Text>{periodRange}</Text>
        </Flex>

        <Flex align="center" gap="1">
          <Indicator size="sm" kind="previous" />
          <Text>{periodCompareRange}</Text>
        </Flex>
      </Flex>

      <Action kind="unset" size="none" onClick={() => setCompare(false)}>
        <Cross2Icon />
      </Action>
    </Wrapper>
  );
}

const Wrapper = styled("div", {
  display: "flex",
  background: "$blue2",
  borderLeft: "1px solid $blue6",
  justifyContent: "space-between",
  position: "absolute",
  left: 0,
  top: 0,
  height: "100%",
  padding: "0.5rem 0.5rem 0.5rem 1rem",
  zIndex: -1,
  transition: "$transitionLong",
  width: "4rem",
  alignItems: "center",

  "@lg": {
    width: "12.5rem",
  },

  variants: {
    compare: {
      true: {
        left: "-4rem",

        "@lg": {
          left: "-12.5rem",
        },
      },
    },
  },
});

const MobileIcon = styled(CountdownTimerIcon, {
  display: "block",

  "@lg": {
    display: "none",
    color: "$blue11",
  },
});

const Title = styled(Text, {
  color: "$blue12",
  fontSize: "0.75rem",
  fontWeight: "500",
});

const Text = styled("div", {
  color: "$slate11",
  fontSize: "0.675rem",
  fontWeight: "400",
  whiteSpace: "nowrap",
});

const Action = styled(Button, {
  color: "$blue11",

  "&:hover": {
    color: "$blue12",
  },
});
