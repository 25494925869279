import React from "react";
import { keyframes } from "@stitches/react";
import { styled } from "./stitches.config";

const pulse = keyframes({
  "0%": { opacity: 1 },
  "50%": { opacity: 0.5 },
  "100%": { opacity: 1 },
});

export const Skeleton = styled("div", {
  backgroundColor: "$slate2",
  animation: `${pulse} 0.5s ease-in-out infinite`,
  width: "100%",
});
